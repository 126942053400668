import { type FC, type PropsWithChildren } from 'react';

import { MultiProfilePicture } from '@littleotter/legacy-components';

import { type ConversationInfoFragment_participants } from '../../../../../../graphql/__generated__/ConversationInfoFragment';
import { type ViewerQuery_viewer } from '../../../../../../graphql/__generated__/ViewerQuery';
import { ReadStatus } from '../../../../components';
import { isOutOfOffice } from '../../../../Conversation/utils/isOutOfOffice';
import { getConversationTitle, getConversationTitleWithTimezone } from '../../../../utils/getConversationTitle';
import { getTimestampString } from '../../../../utils/getTimestampString';

import {
  Container,
  LastActiveAt,
  LastMessage,
  MessageDescription,
  MetaLeftSection,
  MetaRightSection,
  OutOfOfficeLabel,
  ProfilePictureContainer,
  ConversationMeta as StyledConversationMeta,
  Title,
  UnreadCount,
} from './styled';

export type ConversationMetaProps = {
  sid: string;
  participants: ConversationInfoFragment_participants[];
  viewer: ViewerQuery_viewer;
  unreadMessageCount: number;
  lastActiveAt: Date;
  lastMessageBody: string;
  isLastMessageOutgoing: boolean;
  isLastMessageRead: boolean;
};

const getTitle: (viewerIsStaff: boolean, participants: ConversationInfoFragment_participants[]) => string = (
  viewerIsStaff,
  participants
) => {
  if (!participants) {
    // If there are no participants, no title is fine
    // https://github.com/thelittleotter/littleotter-ui/pull/333#pullrequestreview-825806128
    return '';
  }
  if (viewerIsStaff) {
    return getConversationTitleWithTimezone(participants);
  }
  return getConversationTitle(participants);
};

export const ConversationMeta: FC<PropsWithChildren<ConversationMetaProps>> = ({
  sid,
  participants,
  viewer,
  unreadMessageCount,
  lastActiveAt,
  lastMessageBody,
  isLastMessageOutgoing,
  isLastMessageRead,
}) => {
  const otherParticipants = participants.filter((participant) => participant.id !== viewer.id);
  const profilePictureList = otherParticipants?.map((participant) => participant.profilePicture) ?? [];
  const title = getTitle(viewer.isStaff, otherParticipants);
  const outOfOfficeParticipants = otherParticipants.filter((part) => isOutOfOffice(part.outOfOfficeDates));
  const messageStatus = () => {
    if (isLastMessageOutgoing) {
      return <ReadStatus read={isLastMessageRead} />;
    }
    if (!isLastMessageOutgoing && unreadMessageCount) {
      return <UnreadCount>{unreadMessageCount}</UnreadCount>;
    }
    return null;
  };

  return (
    <Container href={`/messages/${sid}`} aria-label={`Conversation with ${title}`}>
      <StyledConversationMeta>
        <MetaLeftSection>
          <ProfilePictureContainer>
            <MultiProfilePicture srcList={profilePictureList} />
          </ProfilePictureContainer>
          <MessageDescription>
            <Title>{title}</Title>
            {outOfOfficeParticipants.length > 0 && <OutOfOfficeLabel>Out of Office</OutOfOfficeLabel>}
            <LastMessage hasUnreadMessages={!!unreadMessageCount}>{lastMessageBody}</LastMessage>
          </MessageDescription>
        </MetaLeftSection>
        <MetaRightSection>
          <LastActiveAt>{getTimestampString(lastActiveAt)}</LastActiveAt>
          {messageStatus()}
        </MetaRightSection>
      </StyledConversationMeta>
    </Container>
  );
};
