import { math, rgba } from 'polished';
import { type FC, useCallback, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import styled, { css } from 'styled-components';
import LibModal, { BaseModalBackground, type ModalProps as BaseModalProps } from 'styled-react-modal';

import { Button } from '../Button';

const transitionDuration = 200; // ms

type StyledModalProps = {
  isOpen?: boolean;
  hasCloseButton?: boolean;
  raised?: boolean;
  centered?: boolean;
  fullWidth?: boolean;
};

const StyledModal = styled.div<StyledModalProps>`
  --modal-padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};

  background-color: #fff;
  border: ${(props) =>
    `${props.theme.deprecated_.border.size} ${props.theme.deprecated_.border.style} ${props.theme.deprecated_.border.color}`};
  padding: var(--modal-padding);
  box-shadow: ${({ isOpen, raised }) => (isOpen && raised ? '0px 26px 60px rgba(0, 0, 0, 0.15)' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition:
    opacity ${transitionDuration}ms,
    box-shadow ${transitionDuration * 1.5}ms ease-in;

  ${({ hasCloseButton }) =>
    hasCloseButton &&
    css`
      padding-top: calc(var(--modal-padding) + 0.5rem);
    `};

  ${({ centered, theme, fullWidth }) =>
    centered
      ? css`
          border-radius: ${fullWidth ? 0 : math(`${theme.deprecated_.border.radius} * 2`)};
          max-width: ${fullWidth ? '100vw' : '90vw'};
          align-self: center;
        `
      : css`
          border-radius: 0;
          border-top-left-radius: ${fullWidth ? 0 : math(`${theme.deprecated_.border.radius} * 5`)};
          border-top-right-radius: ${fullWidth ? 0 : math(`${theme.deprecated_.border.radius} * 5`)};
          min-width: 50vw;
          width: 100%;
          align-self: flex-end;
        `}

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    ${({ centered, theme, fullWidth }) =>
      !centered &&
      css`
        border-radius: ${fullWidth ? 0 : theme.deprecated_.border.radius};
        border-top-left-radius: ${fullWidth ? 0 : theme.deprecated_.border.radius};
        border-top-right-radius: ${fullWidth ? 0 : theme.deprecated_.border.radius};
        width: ${fullWidth ? '100vw' : 'auto'};
        align-self: flex-end;
      `};
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      height: 100vh;
    `};
`;

const CloseIconContainer = styled.div`
  position: relative;
`;

const CloseButton = styled(Button).attrs({ variant: 'clearStyles' })`
  font-size: ${({ theme }) => theme.fontSizes.larger};
  line-height: 0;
  position: absolute;
  top: -1.75rem;
  right: -1.25rem;
`;

export const ModalContent = styled.div<StyledModalProps>`
  max-height: 70vh;
  overflow-y: auto;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-height: 100vh;
    `};
`;

type StyledModalBackgroundProps = {
  raised?: boolean;
  isVisible?: boolean;
};

export const StyledModalBackground = styled(BaseModalBackground)<StyledModalBackgroundProps>`
  background-color: ${({ theme, raised }) =>
    raised ? rgba(theme.deprecated_.colors.japaneseIndigo, 0.6) : rgba(theme.deprecated_.colors.lightGray, 0.5)};
  z-index: 9999;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity ${transitionDuration}ms;
`;

export type ModalProps = BaseModalProps & {
  className?: string;
  onCloseButtonClick?: () => void;
  raised?: boolean;
  centered?: boolean;
  fullWidth?: boolean;
};

export const Modal: FC<ModalProps> = ({
  children,
  className,
  onCloseButtonClick,
  raised,
  centered,
  backgroundProps,
  fullWidth,
  ...props
}) => {
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // When the modal is opened:
  // - isBackgroundVisible is set to true.
  // - Allow the background to transition (e.g. fade in) for `transitionDuration` milliseconds.
  // - isOpen is set to true, after the background has completed it transition.
  // - The modal transitions (e.g. fade in) for `transitionDuration` milliseconds.
  const setOpen = useCallback(() => {
    setIsBackgroundVisible(true);
    setTimeout(() => setIsOpen(true), transitionDuration);
  }, []);

  // When the modal is closed:
  // - isOpen and isBackgroundVisible are set to false.
  // - Allow the modal and the background to transition (e.g. fade out) simultaneously
  //   for `transitionDuration` milliseconds.
  // - After `transitionDuration` milliseconds, resolve the promise,
  //   actually closing the modal and removing both the modal and background elements from the DOM.
  const setClosed = useCallback(
    () =>
      new Promise<void>((resolve) => {
        setIsOpen(false);
        setIsBackgroundVisible(false);
        setTimeout(() => resolve(), transitionDuration);
      }),
    []
  );

  return (
    <LibModal
      {...props}
      backgroundProps={{ ...backgroundProps, raised, isVisible: isBackgroundVisible }}
      afterOpen={setOpen}
      beforeClose={setClosed}
    >
      <StyledModal
        className={className}
        hasCloseButton={!!onCloseButtonClick}
        isOpen={isOpen}
        raised={raised}
        centered={centered}
        fullWidth={fullWidth}
      >
        {!!onCloseButtonClick && (
          <CloseIconContainer>
            <CloseButton onClick={onCloseButtonClick}>
              <IoIosClose />
            </CloseButton>
          </CloseIconContainer>
        )}
        <ModalContent fullWidth={fullWidth}>{children}</ModalContent>
      </StyledModal>
    </LibModal>
  );
};
