import { type FC, type PropsWithChildren } from 'react';

import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../../../routes';
import { useGraphQLErrorHandling } from '../../../../shared/hooks';

import { useNextAppointmentQuery } from './hooks';
import { OrderConfirmation, type OrderConfirmationProps } from './OrderConfirmation';

export const OrderConfirmationWithPrerequisites: FC<PropsWithChildren<OrderConfirmationProps>> = ({ ...props }) => {
  const { data, loading, error } = useNextAppointmentQuery({
    skip: !!props.sessionDetails,
  });
  useGraphQLErrorHandling(error);
  const needsAppointment = !props.sessionDetails && !data?.viewer?.nextAppointment;
  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: needsAppointment,
          route: routes.paymentDetails.welcomeCall.home,
          noRedirect: true,
        },
      ]}
      loading={loading}
    >
      <OrderConfirmation {...props} />
    </WithPrerequisites>
  );
};
