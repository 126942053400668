// apollo-upload-client needs a custom fetch implementation for upload progress support
// https://github.com/jaydenseric/apollo-upload-client/issues/88

const parseHeaders = (rawHeaders: string) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const parts = line.split(':');
    const key = parts.shift()?.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

const uploadFetch = (url: string, options: CustomRequestInit) =>
  new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    xhr.open(options.method!, url, true);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Object.keys(options.headers!).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      xhr.setRequestHeader(key, options.headers![key as keyof HeadersInit] as string);
    });

    if (xhr.upload) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      xhr.upload.onprogress = options.onProgress!;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    xhr.send(options.body);
  });

interface CustomRequestInit extends RequestInit {
  onProgress?: (event: ProgressEvent) => void;
}

export const customFetch = (uri: string, options: CustomRequestInit) => {
  if (options?.onProgress) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};
