import { useQuery } from '@apollo/client';
import { debounce } from 'lodash-es';
import { type FormEvent, type PropsWithChildren, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form6';
import { FiDownload } from 'react-icons/fi';

import { Icon } from '@littleotter/legacy-components';

import { type ViewerQuery } from '../../../../../graphql/__generated__/ViewerQuery';
import { VIEWER_QUERY } from '../../../../../graphql/user';

import { FileUploadConfirmationModal } from './FileUploadConfirmationModal';
import { FileUploadIcon } from './FileUploadIcon';
import { useFileUpload } from './hooks';
import { IconButton } from './IconButton';
import { InputBox } from './InputBox';
import { StyledForm } from './styled';

export type NewMessageFormData = {
  newMessage: string;
};

type NewMessageFormProps = {
  onSubmit: (data: NewMessageFormData) => Promise<void>;
  sendNewFile: (file: File) => Promise<void>;
  onCsvDownloadClick: () => void;
  isLoadingCsvButton: boolean;
  defaultMessageDraft: string;
  updateDraftMessage: (value: string) => void;
};

export const NewMessageForm = ({
  onSubmit,
  sendNewFile,
  onCsvDownloadClick,
  isLoadingCsvButton,
  defaultMessageDraft,
  updateDraftMessage,
}: PropsWithChildren<NewMessageFormProps>) => {
  const { newFile, onFileUploadChange, resetFileUpload } = useFileUpload();

  const { data: viewerData } = useQuery<ViewerQuery>(VIEWER_QUERY);
  const isStaff = viewerData?.viewer?.isStaff;

  const { control, handleSubmit, reset, formState } = useForm<NewMessageFormData>({
    defaultValues: { newMessage: defaultMessageDraft },
  });

  const onSubmitInForm = async (data: NewMessageFormData) => {
    await onSubmit(data);
    updateDraftMessage('');
    reset({ newMessage: '' });
  };

  const onInputMessage = useMemo(
    () =>
      debounce((event: FormEvent<HTMLTextAreaElement>) => {
        const { value } = event.target as HTMLInputElement;
        updateDraftMessage(value);
      }, 400),
    [updateDraftMessage]
  );

  return (
    <>
      <FileUploadConfirmationModal newFile={newFile} sendNewFile={sendNewFile} resetFileUpload={resetFileUpload} />
      <StyledForm onSubmit={handleSubmit(onSubmitInForm)} disabled={false}>
        <Controller
          as={InputBox}
          name="newMessage"
          placeholder="Type your message here..."
          rows={1}
          control={control}
          onInput={onInputMessage}
        />
        {isStaff && (
          <IconButton type="button" isLoading={isLoadingCsvButton} onClick={onCsvDownloadClick} Icon={FiDownload} />
        )}
        <FileUploadIcon name="newFile" onChange={onFileUploadChange} />
        <IconButton
          type="submit"
          isLoading={formState.isSubmitting}
          disabled={!(formState.isDirty || defaultMessageDraft.length)}
          Icon={() => <Icon name="SendMessage" />}
        />
      </StyledForm>
    </>
  );
};
