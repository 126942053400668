import { gql } from '@apollo/client';

export const SEND_TEMPER_TANTRUM_EMAIL = gql`
  mutation SendTemperTanturmResultsEmailMutation($input: SendTemperTantrumResultsEmailMutationInput!) {
    sendTemperTantrumResultsEmail(input: $input) {
      ok
    }
  }
`;

export const ADD_EMAIL_TO_HUBSPOT = gql`
  mutation AddEmailToHubspotMutation($input: AddEmailToHubspotMutationInput!) {
    addEmailToHubspot(input: $input) {
      ... on AddEmailToHubspotResult {
        accepted
      }
      ... on AddEmailToHubspotError {
        message
      }
    }
  }
`;
