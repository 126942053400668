import { type IEnvironmentService } from '../../environment/types';
import { DEFAULT_LOG_LEVEL, defineLogLevel, LOG_LEVEL, type LogLevelName } from '../__internal__';
import type { ErrorFormatterFn, IBaseLogHandler } from '../types';

/**
 * This base handler class (that all handlers will extend) provides common functionality for all handlers.
 *
 * The base handler sets handler logging level to DEFAULT_LOG_LEVEL.
 */
export class BaseHandler implements IBaseLogHandler {
  protected logLevel;

  errorFormatter?: ErrorFormatterFn = undefined;

  constructor() {
    this.logLevel = defineLogLevel(DEFAULT_LOG_LEVEL);
  }

  setLevel(level: LogLevelName) {
    this.logLevel = defineLogLevel(level);
    return this;
  }

  getLevel() {
    return this.logLevel;
  }

  isEnabledForLevel(level: LogLevelName) {
    return this.logLevel.value <= LOG_LEVEL[level];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,class-methods-use-this
  isEnabled(env: IEnvironmentService): boolean {
    return true;
  }
}
