import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Link } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { AssessmentHeader } from '../../../components/AssessmentHeader';
import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type HolidayReportQuery,
  type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType,
} from '../../../graphql/__generated__/HolidayReportQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { HOLIDAY_REPORT_QUERY } from '../../../graphql/holiday-report';

import { HolidayReportContent } from './HolidayReportContent';

type PathParams = {
  id: string;
};

export const HolidayReport: FC<PropsWithChildren> = () => {
  const params = useParams<PathParams>();

  const { data, loading, error } = useQuery<HolidayReportQuery>(HOLIDAY_REPORT_QUERY, {
    variables: { uuid: params.id },
  });
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;
  if (!data?.assessmentsNamespace.assessmentResponse) throw new MissingQueryDataError('HolidayReportQuery');

  return (
    <>
      <AssessmentHeader>
        <Link variant="clearStyles" isAnchor href="https://www.littleotterhealth.com/holiday">
          <img
            src="https://littleotter-assets.s3.amazonaws.com/assessments/holiday/logo.svg"
            alt="Little Otter Holiday Toolkit Logo"
          />
        </Link>
      </AssessmentHeader>
      <HolidayReportContent
        reportResult={
          data.assessmentsNamespace.assessmentResponse
            .result as HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType
        }
        updatedAt={new Date(data.assessmentsNamespace.assessmentResponse.updatedAt)}
      />
    </>
  );
};
