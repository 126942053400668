import { type FC } from 'react';

import { Header } from '../../../Header';
import { type CustomWidgetProps } from '../types';

import { ProfilePicture } from './components';
import { Wrapper } from './styled';

export type HeaderWidgetOptions = {
  title: string;
  profilePicture?: string;
};

type HeaderWidgetProps = CustomWidgetProps<HeaderWidgetOptions>;

export const HeaderWidget: FC<HeaderWidgetProps> = ({ options }) => {
  const { title, profilePicture } = options as HeaderWidgetOptions;

  return (
    <Wrapper>
      {profilePicture && <ProfilePicture src={profilePicture} />}
      <Header as="h4">{title}</Header>
    </Wrapper>
  );
};
