import { type FC, type PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';

import { getLocationDescriptor } from '$shared/utils/rerouter/rerouter';
import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';

import { ChildForm, ChildSummary } from './Pages';

export const Child: FC<PropsWithChildren> = () => {
  const childFormRedirect = getLocationDescriptor({ route: routes.child.form, forward: true });
  return (
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.child.home)}>
        <Redirect to={childFormRedirect} />
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.child.form)} withoutMobileNavbar fullHeight>
        <Page hasHeader hasBackButton hasLogo>
          <ChildForm />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.child.summary)} withoutMobileNavbar fullHeight>
        <Page hasHeader hasBackButton hasLogo>
          <ChildSummary />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
