import { type FC, type PropsWithChildren } from 'react';

import { Button, Link } from '@littleotter/legacy-components';

import { formatDate, formatTime } from '../../../utils';

import { ButtonsContainer, NextCallDate, StyledFamilyDetailsCard, TextBlue } from './styled';

export type NextCallProps = {
  startDatetime: Date;
  serviceName: string;
  providerName: string;
  conversationLink?: string;
  zoomLink?: string;
};

export const NextCall: FC<PropsWithChildren<NextCallProps>> = ({
  startDatetime,
  serviceName,
  providerName,
  conversationLink,
  zoomLink,
}) => (
  <StyledFamilyDetailsCard title="Next Call">
    <NextCallDate>
      {formatDate(startDatetime)} <TextBlue>{formatTime(startDatetime)}</TextBlue>
    </NextCallDate>
    <div>
      {serviceName} with {providerName}
    </div>
    <ButtonsContainer>
      {zoomLink ? (
        <Link isAnchor href={zoomLink} variant="primary">
          Join Call
        </Link>
      ) : (
        <Button disabled>No Zoom meeting attached</Button>
      )}
      {conversationLink ? (
        <Link href={conversationLink} variant="primary">
          Message
        </Link>
      ) : (
        <Button disabled>No conversation available</Button>
      )}
    </ButtonsContainer>
  </StyledFamilyDetailsCard>
);
