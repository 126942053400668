import { type FC, type PropsWithChildren } from 'react';

import { formatDate, formatTime } from '../../utils';

import {
  CardContainer,
  CardDate,
  CardDisabledLink,
  CardDisabledLinkContent,
  CardLeftSection,
  CardLink,
  CardLinkContent,
  CardRightSection,
  CardTime,
  CardTitle,
} from './styled';

export type ScheduleCardProps = {
  startDatetime: Date;
  endDatetime: Date;
  clientName: string;
  profileLink?: string | null;
  zoomLink?: string | null;
};

export const ScheduleCard: FC<PropsWithChildren<ScheduleCardProps>> = ({
  startDatetime,
  endDatetime,
  clientName,
  profileLink,
  zoomLink,
}) => (
  <CardContainer>
    <CardLeftSection>
      <CardDate>{formatDate(startDatetime)}</CardDate>
      <CardTime>
        {formatTime(startDatetime)}-{formatTime(endDatetime)}
      </CardTime>
      <CardTitle>{clientName}</CardTitle>
    </CardLeftSection>
    <CardRightSection>
      {profileLink ? (
        <CardLink isAnchor href={profileLink}>
          <CardLinkContent>View Full Profile</CardLinkContent>
        </CardLink>
      ) : (
        <CardDisabledLink>
          <CardDisabledLinkContent>No profile link attached</CardDisabledLinkContent>
        </CardDisabledLink>
      )}
      {zoomLink ? (
        <CardLink isAnchor href={zoomLink}>
          <CardLinkContent>Zoom Meeting</CardLinkContent>
        </CardLink>
      ) : (
        <CardDisabledLink>
          <CardDisabledLinkContent>No zoom meeting attached</CardDisabledLinkContent>
        </CardDisabledLink>
      )}
    </CardRightSection>
  </CardContainer>
);
