import { useMutation } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { type ActionPerformed, PushNotifications, type Token } from '@capacitor/push-notifications';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { logger } from '$services/logging';

import { type DeviceTypes } from '../../graphql/__generated__/globalTypes';
import {
  type RegisterDeviceMutation,
  type RegisterDeviceMutationVariables,
} from '../../graphql/__generated__/RegisterDeviceMutation';
import { REGISTER_DEVICE_MUTATION } from '../../graphql/push-notifications';
import { useViewer } from '../contexts/Viewer';

export const usePushNotifications = () => {
  const { viewer } = useViewer();
  const [registerDevice] = useMutation<RegisterDeviceMutation, RegisterDeviceMutationVariables>(
    REGISTER_DEVICE_MUTATION
  );
  const history = useHistory();

  useEffect(() => {
    if (!Capacitor.isPluginAvailable('PushNotifications') || !viewer) return;
    const requestPermission = async () => {
      const result = await PushNotifications.requestPermissions();

      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    };

    requestPermission();
  }, [viewer]);

  useEffect(() => {
    if (!Capacitor.isPluginAvailable('PushNotifications')) return;
    PushNotifications.addListener('registration', async (token: Token) => {
      await registerDevice({
        variables: { input: { registrationToken: token.value, deviceType: Capacitor.getPlatform() as DeviceTypes } },
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    PushNotifications.addListener('registrationError', (error: any) => {
      logger.error(new Error('Error with push notification registration', { cause: error }));
    });

    // PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {});

    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      if (action.notification.data?.url) {
        history.push(action.notification.data.url);
      }
    });

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [registerDevice, viewer, history]);
};
