import { type FC, type PropsWithChildren } from 'react';

import { HeaderBackground } from './styled';

import bgLeftTop from './assets/bg-left-top-1.svg';
import bgLeftTopTwo from './assets/bg-left-top-2.svg';
import bgRightBottom from './assets/bg-right-bottom.svg';

export const ReportHeaderBackground: FC<PropsWithChildren> = () => {
  return (
    <>
      <HeaderBackground src={bgLeftTop} left="0px" top="0px" />
      <HeaderBackground src={bgLeftTopTwo} left="65px" top="0px" />
      <HeaderBackground src={bgRightBottom} right="0px" bottom="0px" />
    </>
  );
};
