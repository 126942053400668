import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '@littleotter/legacy-components';

import { MarginChildren } from '../../../components';
import { routes } from '../../../routes';

import { TantrumButton } from './components/Tantrum';
import { Container } from './styled';

import intro from './assets/intro.svg';

const ParagraphMoreLineHeight = styled.p`
  line-height: 1.4;
`;

export const Intro: FC<PropsWithChildren> = () => {
  const history = useHistory();
  return (
    <Container>
      <MarginChildren>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ maxWidth: '8rem', width: '50%' }}>
            <img src={intro} alt="page illustration" />
          </div>
        </div>
        <Header as="h3" style={{ textAlign: 'center' }}>
          Wondering if your kid's tantrums are typical?
        </Header>
        <Header as="h4" style={{ textAlign: 'center' }}>
          We can help.
        </Header>
        <ParagraphMoreLineHeight>
          Almost every young child has temper tantrums. Tantrums are caused by big feelings- anger, frustration, fear,
          sadness- that bubble over into big outbursts.{' '}
          <strong>
            Tantrums are also one of the ways we can tell if a little kid has an underlying mental health challenge.
          </strong>
        </ParagraphMoreLineHeight>
        <ParagraphMoreLineHeight>
          Our 3 question survey, designed for kids 2-6 years old, will help identify whether you should be concerned
          about your child’s tantrums.
        </ParagraphMoreLineHeight>
        <TantrumButton
          variant="primary"
          onClick={() => history.push({ pathname: routes.tantrums.child.url(), search: history.location.search })}
        >
          Let's Get Started!
        </TantrumButton>
      </MarginChildren>
    </Container>
  );
};
