import { ResponsiveBar } from '@nivo/bar';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Tick } from './components';
import { ConcernZoneLayer, Container, Text } from './styled';
import { type ChartData, type ChartMarker, type LayerArgs } from './types';
import { getBarChartData, getBarColor } from './utils';

export type AssessmentReportBarChartProps = {
  data: ChartData[];
  concernThreshold?: number;
  yMax: number;
  yGridInterval?: number;
  className?: string;
};

export const AssessmentReportBarChart: FC<PropsWithChildren<AssessmentReportBarChartProps>> = ({
  data,
  concernThreshold,
  yMax,
  yGridInterval = 4,
  className,
}) => {
  const {
    deprecated_: { colors, fontSizes },
  } = useTheme();

  const gridYValues = useMemo(
    () =>
      yGridInterval
        ? Array.from({ length: yMax / yGridInterval + 1 }).map((_, index) => index * yGridInterval)
        : undefined,
    [yGridInterval, yMax]
  );

  const concernZoneLayer = ({ width, height }: LayerArgs) => {
    if (!concernThreshold) {
      return null;
    }

    const zoneHeight = height - (height / yMax) * concernThreshold;

    return <ConcernZoneLayer width={width} height={zoneHeight} />;
  };

  const markers: ChartMarker[] = concernThreshold
    ? [
        {
          axis: 'y',
          value: concernThreshold,
          legend: 'Worry Zone',
          legendOrientation: 'horizontal',
          legendPosition: 'top-right',
          lineStyle: {
            stroke: colors.lightRed,
            strokeWidth: 1,
            strokeDasharray: 2,
          },
          textStyle: {
            fill: colors.lightRed,
            fontSize: `calc(${fontSizes.regular} * 0.75)`,
          },
        },
      ]
    : [];

  return (
    <Container className={className}>
      <ResponsiveBar
        data={getBarChartData(data)}
        maxValue={yMax ?? 'auto'}
        colors={(item) => getBarColor(item.data, concernThreshold, colors)}
        isInteractive={false}
        enableLabel={false}
        gridYValues={gridYValues}
        layers={[concernZoneLayer, 'grid', 'axes', 'bars', 'markers', 'legends']}
        markers={markers}
        axisBottom={{
          tickSize: 0,
          tickPadding: 8,
          renderTick: Tick,
        }}
        axisLeft={null}
        margin={{
          top: 0,
          right: 0,
          bottom: 48,
          left: 0,
        }}
        padding={0.25}
      />
      {!data.length && <Text>No data</Text>}
    </Container>
  );
};
