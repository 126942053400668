import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_holidayStress } from '../../../../graphql/__generated__/HolidayReportQuery';

import { CollapseSection } from './CollapseSection';
import { CenteredSignalStrengthBars, Section } from './styled';

type HolidayStressProps = {
  holidayStressResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType_holidayStress;
};

export const HolidayStress: FC<PropsWithChildren<HolidayStressProps>> = ({
  holidayStressResult: { score, content },
}) => (
  <Section>
    <Header as="h4" id="holiday-stress">
      Holiday Stress
    </Header>
    <p>The colored bars show how much stress your family is experiencing this holiday season.</p>

    <CenteredSignalStrengthBars barAmount={11} activeBars={score} axisLabel="Stress Level" />

    <CollapseSection
      meaningContent={content.meaning}
      whatICanDoToSupportContent={content.whatICanDoToSupport}
      activityToDoContent={content.activityToDo}
    />
  </Section>
);
