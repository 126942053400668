import { type TableInstance, useSortBy, useTable } from 'react-table';

import { type AssessmentsAndDocumentationTableDataItem } from '../types';

import { useTableOptions } from './useTableOptions';

type useAssessmentsAndDocumentationTable = (options: {
  tableData: AssessmentsAndDocumentationTableDataItem[];
}) => TableInstance<AssessmentsAndDocumentationTableDataItem>;

export const useAssessmentsAndDocumentationTable: useAssessmentsAndDocumentationTable = ({ tableData }) => {
  const tableOptions = useTableOptions(tableData);
  const tableInstance = useTable(tableOptions, useSortBy);

  return tableInstance;
};
