import { math } from 'polished';
import styled, { keyframes } from 'styled-components';

import { animationDuration, baseAnimationDelay, runningAnimationDelay } from '../constants';

import { ReactComponent as BaseHerb2Image } from '../assets/Herb2.svg';

const herb2Animation = keyframes`
  from {
    opacity: 0;
    transform: translate(-0.5rem);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
`;

export const Herb2Image = styled(BaseHerb2Image)`
  position: absolute;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 8.6`)};
  height: auto;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.6`)};
  left: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 9.4`)};

  animation-name: ${herb2Animation};
  animation-duration: ${animationDuration};
  animation-delay: calc(${baseAnimationDelay} + ${animationDuration} + ${runningAnimationDelay});
  animation-timing-function: linear;
  animation-fill-mode: both;
`;
