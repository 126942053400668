import { type FC, type PropsWithChildren } from 'react';

import { ReportPointSeparator } from '../../styled';

import { CardContainer, CardHeader, CardLabel, CardScores, CardText, CardTitle, StyledTag } from './styles';
import { type CardProps, type ScoreLabeled } from './types';

export const Card: FC<PropsWithChildren<CardProps>> = ({
  member,
  scores,
  lessThresholdLabel = 'Typical',
  greaterEqualThresholdLabel = 'Concerning',
}) => {
  const scoreLabeled: ScoreLabeled = {
    lessThresholdLabel: [],
    greaterEqualThresholdLabel: [],
  };

  scores.forEach((score) => {
    if (!score.label || typeof score.rawScore !== 'number') {
      return;
    }

    if (score.threshold !== undefined && score?.rawScore >= score?.threshold) {
      scoreLabeled.greaterEqualThresholdLabel.push(score.label);
      return;
    }

    scoreLabeled.lessThresholdLabel.push(score.label);
  });

  const parsedChildAge =
    member.age !== undefined && member.age >= 24 ? `${Math.floor(member.age / 12)}yo` : `${member.age}mo`;

  return (
    <CardContainer>
      <CardHeader>
        {member.isFamily ? (
          <CardTitle>Family</CardTitle>
        ) : (
          <CardTitle>
            {member.name}{' '}
            {member.isCaregiver ? (
              <span>
                <ReportPointSeparator horizontalMargin={0} /> Caregiver
              </span>
            ) : (
              member.age !== undefined && (
                <span>
                  <ReportPointSeparator horizontalMargin={0} /> {parsedChildAge}
                </span>
              )
            )}
          </CardTitle>
        )}
        {!member.isCaregiver && !member.isFamily && (
          <StyledTag textSize="0.75rem" backgroundColor={member.isSeekingCare ? 'lightGreen' : 'gray50'}>
            {member.isSeekingCare ? 'Seeking care' : 'Not seeking care'}
          </StyledTag>
        )}
      </CardHeader>
      <div>
        {!!scoreLabeled.lessThresholdLabel.length && (
          <CardText>
            <CardLabel color="black">{lessThresholdLabel}:</CardLabel>
            <CardScores> {scoreLabeled.lessThresholdLabel.join(', ')}</CardScores>
          </CardText>
        )}
        {!!scoreLabeled.greaterEqualThresholdLabel.length && (
          <CardText>
            <CardLabel color="red">{greaterEqualThresholdLabel}:</CardLabel>
            <CardScores> {scoreLabeled.greaterEqualThresholdLabel.join(', ')}</CardScores>
          </CardText>
        )}
      </div>
    </CardContainer>
  );
};
