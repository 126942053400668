import { type FC, type PropsWithChildren, type ReactNode } from 'react';

import { type CollapseContentIconVariant, ControlledCollapse } from '../Collapse';

export type CollapseItem = {
  id: string;
  label: ReactNode;
  children: ReactNode;
};

export type CollapseGroupProps = {
  items: CollapseItem[];
  activeId: string;
  onCollapseClick: (collapseId: string) => void;
  iconVariant?: CollapseContentIconVariant;
};

/**
 * Group of `ControlledCollapse` components that can only have one of them open
 * at the same time
 *
 * @param props.items array of `CollapseItem` objects defining what to render
 * for each Collapse
 *
 * @param props.activeId the id of the `CollapseItem` that is open
 *
 * @param props.onCollapseClick handler for the click event of an individual
 * collapse. Passes the collapse id as the argument
 *
 * @param props.iconVariant variant for the icons displayed on each collapse
 */
export const CollapseGroup: FC<PropsWithChildren<CollapseGroupProps>> = ({
  items,
  activeId,
  onCollapseClick,
  iconVariant,
}) => (
  <div>
    {items.map(({ id, label, children }) => (
      <ControlledCollapse
        key={id}
        label={label}
        onClick={() => onCollapseClick(id)}
        isOpen={id === activeId}
        iconVariant={iconVariant}
      >
        {children}
      </ControlledCollapse>
    ))}
  </div>
);
