import { type FC, type PropsWithChildren } from 'react';

import { SvgPath } from '../../../../../../Reports/Fmhc/ReportsContent/components/components/SvgPath';

import { BACKGROUNDS } from './backgrounds';
import * as styled from './styled';

export const SummaryCard: FC<PropsWithChildren<SummaryCardProps>> = ({
  title,
  onClick,
  background,
  icon,
  children,
}) => {
  return (
    <styled.Root onClick={onClick} borderColor={BACKGROUNDS[background][1]}>
      <SvgPath />
      <styled.HeaderBackground backgroundColor={BACKGROUNDS[background][1]} src={BACKGROUNDS[background][0]} />
      <styled.HeaderIcon src={icon} />
      <styled.Title>{title}</styled.Title>
      <styled.Content>{children}</styled.Content>
    </styled.Root>
  );
};

export interface SummaryCardProps {
  title: string;
  onClick?: () => void;
  background: keyof typeof BACKGROUNDS;
  icon: string;
}
