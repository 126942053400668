import { format } from 'date-fns';
import { type FC, type PropsWithChildren, useState } from 'react';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { SmartFmhcCta } from '../../../../components/SmartFmhcCta';
import { type DomainScore, type FmhcReport } from '../../../../graphql/lo1/generated';
import { routes } from '../../../../routes';
import { useViewer } from '../../../../shared/contexts/Viewer';

import { DomainInfo, ImpactInfo, SubjectSelector, WorryDomains } from './components';
import { NUM_DATA_POINTS } from './components/threshold/constants';
import { areMatchingSubjects, mapContexts, mapSubDomainScoreTimeSeries, orderSubjects } from './mappers';
import { useFmhcOverTime, useWorryDomains } from './scenarios';
import { type AllWorryDomains } from './scenarios/useWorryDomains';
import * as Styled from './styled';
import { CATEGORICAL_SCORE, type Subject, SUBJECT_TYPE, type TimeSeries } from './types';

export const OverTime: FC<PropsWithChildren> = () => {
  const { viewer, loading: loadingViewer } = useViewer();
  const { loading: loadingFmhcOverTime, fmhcOverTimeData, subjects } = useFmhcOverTime();
  const { loading: loadingWorryDomains, ...allWorryDomains } = useWorryDomains();
  const loading = loadingFmhcOverTime || loadingWorryDomains || loadingViewer;

  const orderedSubjects = orderSubjects(subjects, viewer?.family?.children ?? []);

  const [selectedSubject, setSelectedSubject] = useState<Subject>(orderedSubjects[0]);
  const data: FmhcReport | undefined = fmhcOverTimeData?.FmhcOverTime?.reports.find((report) =>
    areMatchingSubjects(report.subject, selectedSubject)
  );
  const domainReports = [...(data?.domainReports ?? [])]
    .filter((d) => d.domainName !== 'Impact')
    .sort((a, b) => a.orderIndex - b.orderIndex);
  const worryDomains = getWorryDomains(allWorryDomains, selectedSubject);
  const impactReport = data?.domainReports.find((d) => d.domainName === 'Impact');
  const categoricalImpactScore = reduceCategoricalImpactSubScores(impactReport?.subDomainScores ?? []);

  const latestFmhcSubmitterName = fmhcOverTimeData?.FmhcOverTime?.submitterName;
  const latestFmhcSubmissionTime = fmhcOverTimeData?.FmhcOverTime
    ? new Date(fmhcOverTimeData.FmhcOverTime.submissionTime)
    : undefined;
  return loading ? (
    <PageWideLoading />
  ) : (
    <>
      <Styled.HeaderBgContainer>
        <Styled.HeaderBgLeft />
        <Styled.HeaderBgRight />
      </Styled.HeaderBgContainer>
      <Styled.Root>
        {latestFmhcSubmissionTime && latestFmhcSubmitterName && (
          <Styled.LatestCheckupStatus>
            Latest Check-Up reported by {latestFmhcSubmitterName} on {format(latestFmhcSubmissionTime, 'MMMM do, y')}.
            <a href={routes.reports.fmhcs.home.url()}>See previous reports</a>
          </Styled.LatestCheckupStatus>
        )}
        <SmartFmhcCta />
        <SubjectSelector
          subjects={orderedSubjects}
          selectedSubject={selectedSubject}
          onClickSubject={setSelectedSubject}
        />
        <WorryDomains worryDomains={worryDomains} />
        {impactReport && (
          <ImpactInfo
            key={`${selectedSubject.id}Impact`}
            name="Impact"
            description=""
            categoricalScore={categoricalImpactScore}
            impactSubScores={mapSubDomainScoreTimeSeries(impactReport.subDomainScores, selectedSubject)}
            contexts={mapContexts(impactReport.context)}
          />
        )}
        {domainReports.map((domainReport) => {
          const timeSeries: TimeSeries =
            domainReport.domainScore?.rawScoresOverTime
              .map((datum) => ({
                value: datum.value,
                date: new Date(datum.datetime),
              }))
              .slice(-NUM_DATA_POINTS) ?? [];
          return domainReport.domainScore && domainReport.domainScore.shownInReport ? (
            <DomainInfo
              key={`${selectedSubject.id}-${domainReport.domainName}`}
              name={domainReport.domainName}
              description=""
              latestScore={domainReport.domainScore.rawScore ?? undefined}
              threshold={domainReport.domainScore.threshold}
              maxScore={domainReport.domainScore.maxScore}
              timeSeries={timeSeries}
              contexts={mapContexts(domainReport.context)}
            />
          ) : null;
        })}
      </Styled.Root>
    </>
  );
};

// Reduces the categorical score of a list of sub domain scores to a single categorical score.
// If any score is impacting, then the list is reduced to impacting.
// If any score is missing, then the list is reduced to missing.
// If all scores are typical, then this is typical.
const reduceCategoricalImpactSubScores = (scores: DomainScore[]): CATEGORICAL_SCORE => {
  if (scores.filter((s) => (s.rawScore ?? 0) >= s.threshold).length > 0) {
    return CATEGORICAL_SCORE.IMPACTING;
  }
  if (scores.filter((s) => s.rawScore === undefined).length > 0) {
    return CATEGORICAL_SCORE.MISSING;
  }
  return CATEGORICAL_SCORE.TYPICAL;
};

const getWorryDomains = (allWorryDomains: AllWorryDomains, subject: Subject): string[] => {
  const worryDomainMap: { [key in SUBJECT_TYPE]: string[] } = {
    [SUBJECT_TYPE.FAMILY]: allWorryDomains.family,
    [SUBJECT_TYPE.CAREGIVER]: allWorryDomains.viewer,
    [SUBJECT_TYPE.CHILD]: allWorryDomains.children[subject.id],
  };
  return worryDomainMap[subject.type];
};
