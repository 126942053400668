import { type SyntheticEvent, useCallback, useState } from 'react';

export const useFileUpload = () => {
  const [newFile, setNewFile] = useState<File | null>(null);

  const onFileUploadChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    const file = (event.target as HTMLInputElement).files?.[0];

    if (file) {
      setNewFile(file);
    }

    // Reset file input after choosing the file. This fixes a bug that makes
    // the user unable to select the same file path twice, and we don't neet it
    // to keep its value.
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLInputElement).value = '';
  }, []);

  const resetFileUpload = useCallback(() => {
    setNewFile(null);
  }, []);

  return { newFile, onFileUploadChange, resetFileUpload };
};
