import { type FC, type PropsWithChildren } from 'react';

import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../routes';
import { useViewer } from '../../shared/contexts/Viewer';

import { Messages } from './Messages';

export const MessagesWithPrerequisites: FC<PropsWithChildren> = () => {
  const { isInAllowedState, isStaffViewer, loading } = useViewer();
  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: !isStaffViewer && !isInAllowedState,
          route: routes.home,
        },
      ]}
      loading={loading}
    >
      <Messages />
    </WithPrerequisites>
  );
};
