import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@littleotter/legacy-components';

import { useConversations } from '$shared/contexts/Conversations';

import { routes } from '../../routes';

import { NavbarIcon } from './components/NavbarIcon';

const MobileNavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: max(
    calc(env(safe-area-inset-bottom) + ${({ theme }) => math(`3.5 * ${theme.deprecated_.sizeBasis}`)}),
    ${({ theme }) => math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
  );
  padding: ${({ theme }) => `${theme.deprecated_.sizeBasis} ${math(`2 * ${theme.deprecated_.sizeBasis}`)}`};
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-top: ${({ theme }) =>
    `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${theme.deprecated_.border.color}`};
  z-index: 999;

  @media (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    display: none;
  }
`;

const StyledIconLink = styled(Link)<{ $matches: boolean }>`
  font-size: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 1.5`)};
  color: ${({ theme, $matches }) => ($matches ? theme.deprecated_.colors.text : theme.deprecated_.colors.gray)};
  text-align: center;

  &:visited {
    color: ${({ theme, $matches }) => ($matches ? theme.deprecated_.colors.text : theme.deprecated_.colors.gray)};
  }
`;

const NavLabel = styled.div`
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.smaller};
`;

type MobileNavProps = {
  isStaff?: boolean;
  isInAllowedState?: boolean;
};
export const MobileNav: FC<PropsWithChildren<MobileNavProps>> = ({ isStaff, isInAllowedState }) => {
  const location = useLocation();
  const { unreadMessagesCount } = useConversations();

  const homeLink = isStaff ? (
    <StyledIconLink href={routes.provider.home.url()} $matches={routes.provider.home.test(location.pathname)}>
      <NavbarIcon baseName="Home" fill={routes.provider.home.test(location.pathname)} aria-label="Provider Home" />
      <NavLabel>Provider Home</NavLabel>
    </StyledIconLink>
  ) : (
    <StyledIconLink href={routes.care.home.url()} $matches={routes.care.base.test(location.pathname)}>
      <NavbarIcon baseName="CarePlan" fill={routes.care.base.test(location.pathname)} aria-label="Care Den" />
      <NavLabel>Care Den</NavLabel>
    </StyledIconLink>
  );

  return (
    <MobileNavContainer role="navigation">
      {homeLink}
      {isInAllowedState && (
        <StyledIconLink href={routes.messages.home.url()} $matches={routes.messages.base.test(location.pathname)}>
          <NavbarIcon
            baseName="Messages"
            fill={routes.messages.base.test(location.pathname)}
            notification={!!unreadMessagesCount}
            aria-label="Messages"
          />
          <NavLabel>Messages</NavLabel>
        </StyledIconLink>
      )}
      <StyledIconLink href={routes.library.home.url()} $matches={routes.library.base.test(location.pathname)}>
        <NavbarIcon baseName="Resources" fill={routes.library.base.test(location.pathname)} aria-label="Library" />
        <NavLabel>Resources</NavLabel>
      </StyledIconLink>
      <StyledIconLink href={routes.profile.home.url()} $matches={routes.profile.base.test(location.pathname)}>
        <NavbarIcon
          baseName="User"
          fill={routes.profile.base.test(location.pathname)}
          aria-label="Account &amp; Settings"
        />
        <NavLabel>Account</NavLabel>
      </StyledIconLink>
    </MobileNavContainer>
  );
};

export const MobileNavBuffer = styled.div`
  height: max(
    calc(env(safe-area-inset-bottom) + ${({ theme }) => math(`3.5 * ${theme.deprecated_.sizeBasis}`)}),
    ${({ theme }) => math(`4.5 * ${theme.deprecated_.sizeBasis}`)}
  );
  @media only screen and (min-width: ${({ theme }) => theme.deprecated_.breakpoints.md}) {
    height: 0;
  }
`;
