import { gql, useMutation } from '@apollo/client';

import { WORRY_DOMAINS_CONNECTION_FRAGMENT } from '../../../../../graphql/worry-domains';

import {
  type UpdateOnboardingWorryDomainsMutation,
  type UpdateOnboardingWorryDomainsMutationVariables,
} from './__generated__/UpdateOnboardingWorryDomainsMutation';

const UPDATE_ONBOARDING_WORRY_DOMAINS_MUTATION = gql`
  mutation UpdateOnboardingWorryDomainsMutation(
    $caregiverWorryDomainPks: [Int!]!
    $familyWorryDomainPks: [Int!]!
    $childrenWorryDomainPksMap: JSONString!
  ) {
    updateFamilyWorryDomains(
      caregiverWorryDomainPks: $caregiverWorryDomainPks
      familyWorryDomainPks: $familyWorryDomainPks
      childrenWorryDomainPksMap: $childrenWorryDomainPksMap
    ) {
      __typename
      ... on FamilyType {
        id
        name
        worryDomains {
          ...WorryDomainsConnectionFragment
        }
        children {
          id
          worryDomains {
            ...WorryDomainsConnectionFragment
          }
        }
        caregivers {
          id
          worryDomains {
            ...WorryDomainsConnectionFragment
          }
        }
      }
      ... on UpdateFamilyWorryDomainsError {
        message
        fieldErrors {
          caregiverWorryDomainPks
          familyWorryDomainPks
          childrenWorryDomainPksMap
        }
      }
    }
  }

  ${WORRY_DOMAINS_CONNECTION_FRAGMENT}
`;

export const useUpdateOnboardingWorryDomainsMutation = () =>
  useMutation<UpdateOnboardingWorryDomainsMutation, UpdateOnboardingWorryDomainsMutationVariables>(
    UPDATE_ONBOARDING_WORRY_DOMAINS_MUTATION
  );
