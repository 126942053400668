import { datadogLogs, type LogsInitConfiguration } from '@datadog/browser-logs';

import { resolveEnvVar } from '@littleotter/kit/env';

import type { AppConfig, IEnvironmentService } from '../../environment/types';
import type { ErrorData, ILogHandler } from '../types';

import { BaseHandler } from './__BaseHandler';

// Omitted keys will be inferred automatically from the environment and should never be provided by the consuming application.
type DataDogClientConfig = Omit<LogsInitConfiguration, 'clientToken' | 'service' | 'env' | 'version'>;

const DATA_DOG_CLIENT_TOKEN = resolveEnvVar(
  process.env.DATA_DOG_CLIENT_TOKEN || process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN
);

/**
 * A log handler that wraps @datadog/browser-logs
 */
export class DataDogBrowserHandler extends BaseHandler implements ILogHandler {
  /**
   * The custom config overrides provided by the invoking application
   */
  private configOverrides;

  /**
   * The DataDog client provided by the datadog/browser-logs package
   */
  private client;

  constructor(configOverrides: DataDogClientConfig = {}) {
    super();
    this.client = datadogLogs;
    this.configOverrides = configOverrides;
  }

  // eslint-disable-next-line class-methods-use-this
  isEnabled(env: IEnvironmentService): boolean {
    return !!DATA_DOG_CLIENT_TOKEN && !env.isServer;
  }

  private createConfig = (appConfig: AppConfig): LogsInitConfiguration => {
    const defaultConfig = {
      clientToken: DATA_DOG_CLIENT_TOKEN || 'not set',
      /**
       * Note: We are intentionally sending errors & exceptions to DataDog, even though they might also be handled
       * by another handler. We are comfortable with the resulting duplication.
       */
      forwardErrorsToLogs: true,
      sampleRate: 100,
      site: 'datadoghq.com',
      service: appConfig.appID,
      version: appConfig.appVersion,
      env: appConfig.appEnvironment,
    };
    return {
      ...defaultConfig,
      ...this.configOverrides,
    };
  };

  init(appConfig: AppConfig) {
    const config = this.createConfig(appConfig);
    this.client.init(config);
  }

  DEBUG(message: string, context?: object) {
    this.client.logger.debug(message, { ...context });
  }

  INFO(message: string, context?: object) {
    this.client.logger.info(message, { ...context });
  }

  WARN(message: string, context?: object) {
    this.client.logger.warn(message, { ...context });
  }

  ERROR(error: string, errorData?: ErrorData, context?: object) {
    this.client.logger.error(error, { ...context, ...errorData });
  }
}
