import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Children,
  cloneElement,
  type FC,
  isValidElement,
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
} from 'react';

import { type BaseFlagObject } from '../types';

/**
 * FeatureToggle: On
 * Renders its children when the value of the parent's feature flag is TRUE.
 */
const FeatureToggleOn: FC<PropsWithChildren<FeatureToggleChildProps>> = ({ children, isFlagEnabled }) => {
  return isFlagEnabled && children ? <>{children}</> : null;
};

/**
 * FeatureToggle: Off
 * Renders its children when the value of the parent's feature flag is FALSE.
 */
const FeatureToggleOff: FC<PropsWithChildren<FeatureToggleChildProps>> = ({ children, isFlagEnabled }) => {
  return !isFlagEnabled && children ? <>{children}</> : null;
};

/**
 *
 */
export const createFeatureToggleComponent = <T extends BaseFlagObject>() => {
  /**
   * A component that allows for straightforward conditional rendering of feature components.
   * The aim is to make it easier to consume feature flags in a more readable/clean way.
   *
   * Example Usage:
   *
   * <FeatureToggle feature="flag-key">
   *  <FeatureToggle.On>
   *    <div>Feature is on</div>
   *  </FeatureToggle.On>
   *  <FeatureToggle.Off>
   *    <div>Feature is off</div>
   *  </FeatureToggle.Off>
   * </FeatureToggle>
   */
  const FeatureToggle = ({ feature, children }: FeatureToggleProps<T>) => {
    const flags = useFlags<T>();

    const selectedFlag = flags[feature];

    // Fail hard when we encounter non-boolean flag values in a boolean-specific component
    if (typeof selectedFlag !== 'boolean') {
      const requestedFlag = String(feature);
      const selectedFlagType = typeof selectedFlag;
      const selectedFlagValue = JSON.stringify(selectedFlag);

      throw new Error(
        `The feature flag "${requestedFlag}" did not resolve to a boolean. Received the value ${selectedFlagValue} which is typeof "${selectedFlagType}".`
      );
    }

    // Clone the immediate valid children and pass down the flag value
    const toggledChildren = Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return null;
      }

      return cloneElement(child as ReactElement, { isFlagEnabled: selectedFlag });
    });

    return <>{toggledChildren}</>;
  };

  // Export On/Off components as sub-components in the FeatureToggle
  FeatureToggle.On = FeatureToggleOn;
  FeatureToggle.Off = FeatureToggleOff;

  return FeatureToggle;
};

/* -------------------------------------------------------------------------------------------------
 * Types
 * -----------------------------------------------------------------------------------------------*/

interface FeatureToggleProps<T> {
  feature: keyof T;
  children: ReactNode;
}

interface FeatureToggleChildProps {
  isFlagEnabled?: boolean;
}
