import { type FC, type PropsWithChildren } from 'react';

import { BrandCard, Header, Link } from '@littleotter/legacy-components';

import {
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_caregiverAnxiety,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_caregiverDepression,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_generalRisks_caregiverMentalHealth,
} from '../../../../graphql/__generated__/BackToSchoolReportQuery';
import { ColorCard } from '../components';

import { CollapseSection } from './CollapseSection';
import { ColorCardContainer, Section } from './styled';

type CaregiverMentalHealthProps = {
  caregiverAnxietyResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_caregiverAnxiety | null;
  caregiverDepressionResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_caregiverDepression | null;
  generalCaregiverMentalHealthResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_generalRisks_caregiverMentalHealth | null;
};

export const CaregiverMentalHealth: FC<PropsWithChildren<CaregiverMentalHealthProps>> = ({
  caregiverAnxietyResult,
  caregiverDepressionResult,
  generalCaregiverMentalHealthResult,
}) => (
  <Section>
    <Header as="h4" id="caregiver-anxiety-and-depression">
      Caregiver anxiety and depression
    </Header>
    <p>
      {caregiverAnxietyResult && `Your own anxiety is ${caregiverAnxietyResult.risk}. `}
      {caregiverDepressionResult && `Your depression risk is ${caregiverDepressionResult.risk}.`}
    </p>

    <ColorCardContainer>
      <ColorCard risk={caregiverAnxietyResult?.risk ?? null} category="Anxiety" />
      <ColorCard risk={caregiverDepressionResult?.risk ?? null} category="Depression" />
    </ColorCardContainer>

    <CollapseSection
      whatICanDoToSupportCustomLabel="What can I do for myself?"
      activityToDoCustomLabel="Activity with your child (Nope, this is about you)"
      meaningContent={generalCaregiverMentalHealthResult?.content.meaning ?? ''}
      whatICanDoToSupportContent={generalCaregiverMentalHealthResult?.content.whatICanDoToSupport ?? ''}
      activityToDoContent={generalCaregiverMentalHealthResult?.content.activityToDo ?? ''}
    />

    <BrandCard>
      Little Otter provides care that puts families at the center. Sign up for our{' '}
      <Link isAnchor href="https://us06web.zoom.us/webinar/register/WN_m0YcIR_STrCL7fzlrKrdnQ">
        <strong>Webinar to hear from our co-founder on August 28th.</strong>
      </Link>
    </BrandCard>
  </Section>
);
