import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import { StyledArrowUp, StyledContainerArrowUp } from './styled';

export const BackToTop: FC<PropsWithChildren> = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <StyledContainerArrowUp onClick={scrollToTop} $hide={!showTopBtn}>
      <StyledArrowUp />
    </StyledContainerArrowUp>
  );
};
