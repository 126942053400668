import { type FC, type PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { linkStyles } from '../Link/linkStyles';
import { Loading } from '../Loading';

import { type ButtonSize, buttonStyles, type ButtonStylesProps, type ButtonVariant } from './buttonStyles';

const noStyles = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  text-align: inherit;
  color: inherit;
  letter-spacing: inherit;
`;

const StyledButton = styled.button<ButtonStylesProps>`
  ${({ $variant }) => {
    switch ($variant) {
      case 'clearStyles':
        return noStyles;
      case 'link':
        return css`
          ${noStyles}
          ${linkStyles}
        `;
      default:
        return buttonStyles;
    }
  }}
`;

export type ButtonProps = {
  variant?: ButtonVariant;
  disabled?: boolean;
  size?: ButtonSize;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
  isLoading?: boolean;
  className?: string;
  iconOnly?: boolean;
};

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  variant,
  disabled,
  size,
  onClick,
  type,
  isLoading,
  iconOnly,
  children,
  ...props
}) => (
  <StyledButton
    $variant={variant}
    $size={size}
    type={type}
    onClick={onClick}
    disabled={disabled || isLoading}
    iconOnly={iconOnly}
    {...props}
  >
    {isLoading && <Loading width="14" />}
    {children}
  </StyledButton>
);
