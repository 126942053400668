import { type FC, type PropsWithChildren } from 'react';

import { Collapse } from '@littleotter/legacy-components';

import { CATEGORICAL_SCORE, type DomainContexts, type TimeSeries } from '../../types';
import { DomainContext } from '../DomainContext';
import { DomainHeader } from '../DomainHeader';
import { DomainScoreOverTime } from '../DomainScoreOverTime';

import * as Styled from './styled';

export const DomainInfo: FC<PropsWithChildren<DomainInfoProps>> = ({
  name,
  description,
  latestScore,
  threshold,
  maxScore,
  timeSeries,
  contexts,
}) => {
  const categoricalScore = scoreToCategoricalScore(latestScore, threshold);
  return (
    <Styled.Root>
      <Collapse
        label={<DomainHeader title={name} categoricalScore={categoricalScore} />}
        iconVariant="plus-minus"
        containerVariant="card"
        maxHeight="10000px"
      >
        <Styled.Description>{description}</Styled.Description>
        <Styled.GraphContainer>
          <DomainScoreOverTime timeSeries={timeSeries} threshold={threshold} maxScore={maxScore} />
        </Styled.GraphContainer>
        <Styled.ContextContainer>
          {contexts.map((domainContext) => (
            <DomainContext
              key={domainContext.contextType}
              contextType={domainContext.contextType}
              context={domainContext.context}
            />
          ))}
        </Styled.ContextContainer>
      </Collapse>
    </Styled.Root>
  );
};

export interface DomainInfoProps {
  name: string;
  description: string;
  latestScore: number | undefined;
  threshold: number;
  maxScore: number;
  timeSeries: TimeSeries;
  contexts: DomainContexts;
}

const scoreToCategoricalScore = (score: number | undefined, threshold: number): CATEGORICAL_SCORE => {
  if (score === undefined) {
    return CATEGORICAL_SCORE.MISSING;
  }
  if (score < threshold) {
    return CATEGORICAL_SCORE.TYPICAL;
  }
  return CATEGORICAL_SCORE.CONCERNING;
};
