import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { MetadataRoute } from '../../../components/MetadataRoute';
import { CheckupFrame } from '../components';
import { type QuestionAnswer, type QuestionData, type SubjectQuestions } from '../types';

import { ChildIntroContent } from './components/ChildIntroContent/ChildIntroContent';
import { QuestionsContent } from './components/QuestionsContent/QuestionsContent';
import { type GetAnswers, type SetAnswer } from './components/QuestionsContent/types';

export type CheckupQuestionsContentProps = {
  baseRoute: string;
  childName?: string;
  questions: QuestionData[];
  isLastFamilyMember: boolean;
  getAnswers: GetAnswers;
  setAnswer: SetAnswer;
  onCompletion: (answers: QuestionAnswer[]) => void;
  isSeekingCare?: boolean;
  onSkipChild: () => void;
  subjectsQuestions: SubjectQuestions[];
  subjectIndex: number;
  trackingSubject?: string;
};

export const CheckupQuestionsContent: FC<PropsWithChildren<CheckupQuestionsContentProps>> = ({
  baseRoute,
  childName,
  questions,
  isLastFamilyMember,
  getAnswers,
  setAnswer,
  onCompletion,
  isSeekingCare,
  onSkipChild,
  subjectsQuestions,
  subjectIndex,
  trackingSubject,
}) => {
  const history = useHistory();
  const needsChildIntro = !!childName;
  const questionsBaseRoute = needsChildIntro ? `${baseRoute}/questions` : baseRoute;

  return (
    <>
      {needsChildIntro && (
        <MetadataRoute fullHeight exact path={baseRoute}>
          <CheckupFrame
            navbarShowLogo
            navbarDark
            goBack={history.goBack}
            question={questions[subjectIndex]}
            totalQuestions={questions.length}
            questionIndex={1}
            subjectsQuestionsLength={subjectsQuestions.length}
            subjectIndex={subjectIndex}
            subject={subjectsQuestions[subjectIndex].subject}
          >
            <ChildIntroContent
              isSeekingCare={isSeekingCare}
              onSkipChild={onSkipChild}
              childName={childName}
              trackingSubject={trackingSubject}
              goNextPage={() => history.push(questionsBaseRoute)}
            />
          </CheckupFrame>
        </MetadataRoute>
      )}
      <QuestionsContent
        lastButtonText={isLastFamilyMember ? 'Finished' : 'Next'}
        baseRoute={questionsBaseRoute}
        questions={questions}
        getAnswers={getAnswers}
        setAnswer={setAnswer}
        onCompletion={onCompletion}
        subjectsQuestions={subjectsQuestions}
        subjectIndex={subjectIndex}
        trackingSubject={trackingSubject}
      />
    </>
  );
};
