import { useHistory, useLocation } from 'react-router-dom';

import { Header } from '@littleotter/legacy-components';

import { useChildReferral } from '$shared/contexts/Child';
import { useGoto } from '$shared/utils/rerouter';
import { getLocationDescriptor, getRedirectValue } from '$shared/utils/rerouter/rerouter';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { routes } from '../../../../routes';

import { AuthorizationForm } from './components/AuthorizationForm';
import { Description, FormContainer, HeaderContainer } from './styled';

interface LocationState {
  from: string;
}

export const ReferralAuthorizationForm = () => {
  const { childInfo, childSelectedProvider } = useChildReferral();
  const { state } = useLocation<LocationState>();
  const goNextPage = useGoto({
    route: routes.referral.confirmation,
    forward: true,
    referrerLocation: state?.from,
    extraParams: [`providerId=${childSelectedProvider?.id}&childId=${childInfo?.id}`],
  });
  const history = useHistory();

  if (!childInfo) {
    return <PageWideLoading />;
  }

  const redirectValue = getRedirectValue();

  // If the redirect value is any of the profile pages then we return them there instead of the child summary page
  const shouldSkipSummary = redirectValue && redirectValue.includes(routes.profile.base.path);

  const continueWithoutReferralAction = () => {
    const continueWithoutReferralUrl = shouldSkipSummary
      ? redirectValue
      : getLocationDescriptor({
          route: routes.child.summary,
          forward: true,
          referrerLocation: state?.from,
        });
    history.push(continueWithoutReferralUrl);
  };

  return (
    <>
      <HeaderContainer>
        <Header as="h3">
          Please provide {childInfo?.firstName}'s {childSelectedProvider?.codeLabel} that you received from{' '}
          {childSelectedProvider?.name}.
        </Header>
      </HeaderContainer>
      {childSelectedProvider?.coverageInfo && (
        <Description>
          {childSelectedProvider?.name} coverage includes {childSelectedProvider?.coverageInfo}
        </Description>
      )}
      <FormContainer>
        <AuthorizationForm skipLinkAction={continueWithoutReferralAction} goNextPage={goNextPage} />
      </FormContainer>
    </>
  );
};
