import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { gqlChildToChildSubject } from '../../../../FmhcCheckup/mappers/gqlChildToChildSubject';

import { type FamilyWorryDomainsQuery } from './__generated__/FamilyWorryDomainsQuery';
import { FAMILY_WORRY_DOMAINS_QUERY } from './familyWorryDomainsQuery';
import { type ChildIdToChildSubjectMap } from './types';

export const useGetChildSubjectByChildIdForStaff = (familyId: string) => {
  const { data: fwdData, loading } = useQuery<FamilyWorryDomainsQuery>(FAMILY_WORRY_DOMAINS_QUERY, {
    variables: { familyId: Number(familyId) },
  });
  const childSubjectsByChildId = useMemo(
    () =>
      (fwdData?.family.children ?? []).map(gqlChildToChildSubject).reduce(
        (acc, child) => ({
          ...acc,
          [child.id]: child,
        }),
        {} as ChildIdToChildSubjectMap
      ),
    [fwdData]
  );
  return { childSubjectsByChildId, loading };
};
