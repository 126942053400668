import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { type UpcomingAppointmentViewer } from '../../../../../graphql/__generated__/UpcomingAppointmentViewer';
import { type ViewerQuery } from '../../../../../graphql/__generated__/ViewerQuery';
import { useGetFmhcQuery, useGetFmhcReportQuery } from '../../../../../graphql/lo1/generated';
import { UPCOMING_APPOINTMENT_VIEWER } from '../../../../../graphql/upcoming-appointment-viewer';
import { VIEWER_QUERY } from '../../../../../graphql/user';
import { gqlChildToChildSubject } from '../../../../FmhcCheckup/mappers/gqlChildToChildSubject';
import { useOnboardingWorryDomainsQuery } from '../../../../Onboarding/hooks';
import { type SubjectReportCollection, type UpcomingAppointmentDetails } from '../../types';

import { gqlUpcomingAppointmentToUpcomingAppointmentDetails } from './mappers/appointmentDetails';
import { handleGqlSubjectReports } from './mappers/handlers';
import { extractWorryDomains } from './mappers/worryDomains';
import { type ChildIdToChildSubjectMap, type ChildIdToWorryDomainsMap } from './types';
import { useFamilyWorryDomains } from './useFamilyWorryDomains';
import { useGetChildSubjectByChildIdForStaff } from './useGetChildSubjectByChildIdForStaff';

export const useSubmissionMetadata = (fmhcId: string) => {
  const { data: gqlFmhcReportData, loading } = useGetFmhcReportQuery({
    variables: { request: { fmhcId } },
  });

  const submissionDatetime = gqlFmhcReportData ? new Date(gqlFmhcReportData.GetFmhcReport.submissionTime) : new Date();
  return {
    submissionDatetime,
    submissionUser: gqlFmhcReportData?.GetFmhcReport.submitterName ?? '',
    loading,
  };
};

export const useUpcomingAppointments = (): {
  upcomingAppointmentDetails?: UpcomingAppointmentDetails;
  loading: boolean;
} => {
  const { data: gqlUpcomingAppointmentData, loading } =
    useQuery<UpcomingAppointmentViewer>(UPCOMING_APPOINTMENT_VIEWER);

  return {
    upcomingAppointmentDetails: gqlUpcomingAppointmentToUpcomingAppointmentDetails(gqlUpcomingAppointmentData),
    loading,
  };
};

const useChildSubjectsByChildId = () => {
  const { data: gqlViewerData, loading } = useQuery<ViewerQuery>(VIEWER_QUERY);
  const childSubjectsByChildId = useMemo(
    () =>
      (gqlViewerData?.viewer?.family?.children ?? []).map(gqlChildToChildSubject).reduce(
        (acc, child) => ({
          ...acc,
          [child.id]: child,
        }),
        {} as ChildIdToChildSubjectMap
      ),
    [gqlViewerData]
  );
  return { childSubjectsByChildId, loading };
};

const useWorryDomains = () => {
  const { data: wdData, loading } = useOnboardingWorryDomainsQuery();
  const caregiverWorryDomains = useMemo(() => extractWorryDomains(wdData?.viewer?.worryDomains), [wdData]);

  const familyWorryDomains = useMemo(() => extractWorryDomains(wdData?.viewer?.family?.worryDomains), [wdData]);

  const childrenWorryDomainsByChildId = useMemo(
    () =>
      (wdData?.viewer?.family?.children ?? []).reduce(
        (acc, child) => ({
          ...acc,
          [child.id]: extractWorryDomains(child.worryDomains),
        }),
        {} as ChildIdToWorryDomainsMap
      ),
    [wdData]
  );

  return { caregiverWorryDomains, familyWorryDomains, childrenWorryDomainsByChildId, loading };
};

const useGqlFmhcReportData = (fmhcId: string) => {
  const { data: gqlFmhcReportData, loading: gqlFmhcReportLoading } = useGetFmhcReportQuery({
    fetchPolicy: 'no-cache',
    variables: { request: { fmhcId } },
  });
  const gqlReports = useMemo(
    () =>
      (gqlFmhcReportData?.GetFmhcReport?.reports ?? []).map((report) => {
        const filteredReports = report.domainReports
          // TODO: Remove when domain score is no longer optional in gql
          .filter(
            (d) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              d.domainScore!.shownInReport ||
              (d.subDomainScores.length > 0 && d.subDomainScores.reduce((acc, score) => acc || score.shownInReport))
          );
        filteredReports.sort((d1, d2) => d1.orderIndex - d2.orderIndex);
        return {
          ...report,
          domainReports: filteredReports,
        };
      }),
    [gqlFmhcReportData]
  );

  return { loading: gqlFmhcReportLoading, gqlReports };
};

export const useIsStaff = () => {
  const { data: gqlViewerData, loading } = useQuery<ViewerQuery>(VIEWER_QUERY, { fetchPolicy: 'no-cache' });
  const isStaff = gqlViewerData?.viewer?.isStaff;
  return { isStaff, loading };
};

export const useAppReportsForSubjectsAsStaff = (
  fmhcId: string
): { loading: boolean; reports: SubjectReportCollection[]; isFreeAccount: boolean } => {
  // Staff should see paid content.
  const isFreeAccount = false;

  const { gqlReports, loading: gqlReportsLoading } = useGqlFmhcReportData(fmhcId);
  const { data: gqlFmhcData, loading: gqlFmhcLoading } = useGetFmhcQuery({
    variables: { request: { fmhcId } },
  });
  const {
    caregiverWorryDomains,
    familyWorryDomains,
    childrenWorryDomainsByChildId,
    loading: familyWorryDomainsLoading,
  } = useFamilyWorryDomains(gqlFmhcData?.GetFmhc.fmhc.familyId ?? '', gqlFmhcData?.GetFmhc.fmhc.submitterId ?? '');

  const { childSubjectsByChildId, loading: childLoading } = useGetChildSubjectByChildIdForStaff(
    gqlFmhcData?.GetFmhc.fmhc.familyId ?? ''
  );

  const reports = useMemo(
    () =>
      handleGqlSubjectReports(
        gqlReports,
        childrenWorryDomainsByChildId,
        childSubjectsByChildId,
        caregiverWorryDomains,
        familyWorryDomains,
        isFreeAccount
      ),
    [
      gqlReports,
      caregiverWorryDomains,
      childSubjectsByChildId,
      childrenWorryDomainsByChildId,
      familyWorryDomains,
      isFreeAccount,
    ]
  );

  return {
    loading: gqlReportsLoading || gqlFmhcLoading || familyWorryDomainsLoading || childLoading,
    reports,
    isFreeAccount,
  };
};

export const useAppReportsForSubjects = (
  fmhcId: string,
  isPaywalled: boolean
): { loading: boolean; reports: SubjectReportCollection[] } => {
  const { gqlReports, loading: gqlReportsLoading } = useGqlFmhcReportData(fmhcId);

  const {
    caregiverWorryDomains,
    familyWorryDomains,
    childrenWorryDomainsByChildId,
    loading: worryDomainsLoading,
  } = useWorryDomains();

  const { childSubjectsByChildId, loading: childSubjectsByIdLoading } = useChildSubjectsByChildId();

  const reports = useMemo(
    () =>
      handleGqlSubjectReports(
        gqlReports,
        childrenWorryDomainsByChildId,
        childSubjectsByChildId,
        caregiverWorryDomains,
        familyWorryDomains,
        isPaywalled
      ),
    [
      gqlReports,
      caregiverWorryDomains,
      childSubjectsByChildId,
      childrenWorryDomainsByChildId,
      familyWorryDomains,
      isPaywalled,
    ]
  );

  return {
    loading: worryDomainsLoading || gqlReportsLoading || childSubjectsByIdLoading,
    reports,
  };
};
