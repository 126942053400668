/**
 * This is a custom cookie we set manually in our Squarespace App on the Meta Landing Page.
 * We need to forward the cookie value to rudderstack events to improve ad performance.
 */
export const FACEBOOK_CLICK_ID_COOKIE_NAME = 'lo_fbc';

/**
 * This is a custom cookie we set manually in our Squarespace App on the Meta Landing Page.
 * We need to forward the cookie value to rudderstack events to improve ad performance.
 */
export const FACEBOOK_BROWSER_ID_COOKIE_NAME = 'lo_fbp';
