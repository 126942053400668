import { type FC, type MouseEventHandler, type PropsWithChildren } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { type ButtonSize, buttonStyles, type ButtonVariant } from '../Button/buttonStyles';

import { linkStyles } from './linkStyles';

const noStyles = css`
  text-decoration: inherit;
  color: inherit;
`;

type LinkVariant = 'link' | 'clearStyles' | 'whiteLink' | ButtonVariant;

type LinkStylesProps = { $variant: LinkVariant; $size?: ButtonSize; $hasBorderColor?: boolean };

const componentStyles = css<LinkStylesProps>`
  ${({ $variant = 'link' }) => {
    switch ($variant) {
      case 'clearStyles':
        return noStyles;
      case 'link':
        return linkStyles;
      case 'whiteLink':
        return css`
          ${linkStyles}
          color: white;

          &:hover {
            color: ${({ theme }) => theme.deprecated_.colors.brandPurple};
          }

          &:visited {
            color: white;
          }
        `;
      default:
        return css`
          ${noStyles}
          ${buttonStyles}
          display: inline-flex;
        `;
    }
  }}
`;

const StyledLink = styled.a<LinkStylesProps>`
  ${componentStyles}
`;

const StyledLinkRouter = styled(LinkRouter)<LinkStylesProps>`
  ${componentStyles}
`;

// From type definitions for history 4.7.2 (not in dependencies)
type LocationDescriptorObject = {
  pathname?: string;
  search?: string;
  state?: Record<string, unknown> | null | undefined;
  hash?: string;
  key?: string;
};

export type LinkProps = {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string | LocationDescriptorObject;
  isAnchor?: boolean;
  variant?: LinkVariant;
  size?: ButtonSize;
  hasBorderColor?: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
};

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  onClick,
  href = '',
  isAnchor = false,
  variant = 'link',
  size,
  hasBorderColor,
  children,
  ...props
}) =>
  isAnchor || isExternalPath(href) ? (
    <StyledLink
      href={typeof href === 'string' ? href : href.pathname}
      $variant={variant}
      $size={size}
      $hasBorderColor={hasBorderColor}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledLinkRouter
      to={href}
      $variant={variant}
      $size={size}
      $hasBorderColor={hasBorderColor}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledLinkRouter>
  );

// Simple check for external http urls
const isExternalPath = (href: string | LocationDescriptorObject) => {
  if (typeof href === 'string') {
    return href.startsWith('http');
  }
  // if href is not a string, we know it's an internal location object reference
  return false;
};

export { linkStyles };
