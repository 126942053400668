import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form6';
import styled from 'styled-components';

import { FullButton, Input, RadioGroup, Select } from '@littleotter/legacy-components';

import { logger } from '$services/logging';
import { useBannerMessage } from '$shared/hooks';

import { MarginChildren } from '../../../../../../components';
import {
  ChildGender,
  type ChildProfileFormData,
  Pronouns,
  Relationship,
  type SaveChildErrors,
  type SaveChildResult,
  YesNo,
} from '../../../../types';
import { StyledLabel } from '../../styled';

export type FormProps = {
  validationErrors?: SaveChildErrors;
  prepopulatedChild?: ChildProfileFormData;
  goNextPage?: (id?: string) => void;
  submitChildDataFn: (child: ChildProfileFormData) => Promise<SaveChildResult | void>;
  isCreateFlow?: boolean;
};

const enumToOption = (enu: { [key: string]: string }, valueAsLabel?: boolean) =>
  Object.keys(enu).map((enuKey) => ({
    label: valueAsLabel ? enu[enuKey].toString() : enuKey.toString(),
    value: enu[enuKey].toString(),
  }));

const initialChildState: ChildProfileFormData = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  relationship: '',
  gender: '',
  pronouns: '',
  isSeekingCare: undefined,
};

const OptionalSpan = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.gray};
`;

export const Form: FC<PropsWithChildren<FormProps>> = ({
  submitChildDataFn,
  prepopulatedChild,
  goNextPage,
  validationErrors,
  isCreateFlow,
  children,
}) => {
  const { errors, setError, clearErrors, control, reset, formState, setValue, handleSubmit } =
    useForm<ChildProfileFormData>({
      defaultValues: prepopulatedChild || initialChildState,
      mode: 'all',
    });
  useEffect(() => {
    Object.entries(validationErrors ?? {}).forEach(([formField, message]) => {
      if (message) {
        setError(formField as keyof ChildProfileFormData, { message });
      }
    });
  }, [setError, validationErrors]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setBannerMessage } = useBannerMessage();

  const getAllFormattedData = (submittedData: ChildProfileFormData) => {
    return {
      firstName: submittedData.firstName,
      lastName: submittedData.lastName,
      dateOfBirth: submittedData.dateOfBirth,
      relationship: Relationship[submittedData.relationship as keyof typeof Relationship],
      gender: submittedData.gender,
      pronouns: submittedData.pronouns as Pronouns,
      isSeekingCare: submittedData.isSeekingCare,
    };
  };

  const onSubmit = async (data: ChildProfileFormData) => {
    try {
      setLoading(true);
      clearErrors();
      const formChildData = getAllFormattedData(data);
      const submissionResult = await submitChildDataFn(formChildData);
      if (submissionResult?.isValid) {
        reset(initialChildState);
        if (goNextPage) goNextPage(submissionResult?.userChildId);
      }
    } catch (e) {
      logger.error(new Error('Error submitting ChildProfileFormData', { cause: e }));
      setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MarginChildren>
        <Controller
          as={Input}
          label="Child's first name"
          name="firstName"
          control={control}
          errors={errors}
          rules={{ required: "Child's first name is required" }}
        />
        <Controller
          as={Input}
          label="Child's last name"
          name="lastName"
          control={control}
          errors={errors}
          rules={{ required: "Child's last name is required" }}
        />
        <Controller
          as={Input}
          label="Child's date of birth"
          name="dateOfBirth"
          type="date"
          control={control}
          errors={errors}
          rules={{ required: "Child's date of birth is required" }}
        />
        <StyledLabel>
          <div>Sex Assigned at Birth</div>
          <Controller
            as={RadioGroup}
            displayAsTags
            options={enumToOption(ChildGender)}
            name="gender"
            control={control}
            onChange={(value: string | number) =>
              setValue('gender', value as string, { shouldValidate: true, shouldDirty: true })
            }
            rules={{
              required: 'Sex assigned at birth is required',
            }}
          />
        </StyledLabel>
        <Controller
          as={Select}
          label={
            <>
              Pronouns <OptionalSpan>(Optional)</OptionalSpan>
            </>
          }
          options={enumToOption(Pronouns, true)}
          name="pronouns"
          control={control}
          errors={errors}
        />
        {isCreateFlow && (
          <>
            <Controller
              as={Select}
              label="Your Relationship to the Child"
              name="relationship"
              options={enumToOption(Relationship, true)}
              control={control}
              errors={errors}
              rules={{ required: 'Relationship is required' }}
            />
            <StyledLabel>
              <div>Are you seeking care for this child?</div>
              <Controller
                as={RadioGroup}
                displayAsTags
                options={enumToOption(YesNo)}
                name="isSeekingCare"
                control={control}
                onChange={(value: string | number) =>
                  setValue('isSeekingCare', value as string, { shouldValidate: true, shouldDirty: true })
                }
                rules={{
                  required: errors?.isSeekingCare?.message,
                }}
              />
            </StyledLabel>
          </>
        )}
        {children}
        <FullButton type="submit" variant="secondary" isLoading={loading} disabled={!formState.isValid}>
          Save Child
        </FullButton>
      </MarginChildren>
    </form>
  );
};
