import { type FC, type PropsWithChildren } from 'react';

import { BaseTable } from '../../../components';

import { useAppointmentsTable } from './hooks';
import { type AppointmentsTableDataItem } from './types';

export type AppointmentsTableProps = {
  tableData: AppointmentsTableDataItem[];
};

/**
 * Table for the `Appointments` section
 *
 * @param props.tableData array of `AppointmentsTableDataItem`
 * objects needed for populating the table
 */
export const AppointmentsTable: FC<PropsWithChildren<AppointmentsTableProps>> = ({ tableData }) => {
  const tableInstance = useAppointmentsTable({ tableData });

  if (!tableInstance.rows.length) return <div>No data</div>;

  return <BaseTable tableInstance={tableInstance} />;
};
