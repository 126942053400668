import { type FC, type PropsWithChildren } from 'react';

import { formatDate } from '../../../../../Provider/utils';

import { ReportHeaderBackground } from './ReportHeaderBackground';
import { Container, ContainerText, SubTitle, Title } from './styled';

export type ReportHeaderProps = {
  completedBy: string;
  date: Date;
};

export const ReportHeader: FC<PropsWithChildren<ReportHeaderProps>> = ({ date, completedBy }) => (
  <Container>
    <ReportHeaderBackground />
    <ContainerText>
      <Title>Your Results</Title>
      <SubTitle>
        {formatDate(date)} • Completed by {completedBy}
      </SubTitle>
    </ContainerText>
  </Container>
);
