// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { type FC, type ReactNode, useCallback } from 'react';
import { type FieldErrors } from 'react-hook-form6';
import ReactSelect from 'react-select';
import { useTheme } from 'styled-components';

import { StyledError, StyledLabel, StyledLabelDiv } from './styled';

type Option = { label: string; value: string | number };
type MultiSelectOptions = ReadonlyArray<Option>;

export type MultiSelectProps = {
  options: MultiSelectOptions;
  selectedValues: MultiSelectOptions;
  onChange: (newValues: MultiSelectOptions) => void;
  onInputChange: (inputValue: string) => void;
  loading: boolean;
  name: string;
  label?: ReactNode;
  errors?: FieldErrors;
  placeholder?: string;
};

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  selectedValues,
  onChange,
  onInputChange,
  loading,
  name,
  label,
  errors,
  placeholder,
}) => {
  const theme = useTheme();

  const onReactSelectChange = useCallback<(newSelectedOptions: MultiSelectOptions) => void>(
    (newSelectedOptions) => {
      onChange(newSelectedOptions);
    },
    [onChange]
  );

  const errorMessage: string | undefined = errors?.[name]?.message;

  return (
    <StyledLabel>
      {label && <StyledLabelDiv>{label}</StyledLabelDiv>}

      <ReactSelect
        isMulti
        isLoading={loading}
        options={options}
        value={selectedValues}
        onChange={onReactSelectChange}
        onInputChange={onInputChange}
        placeholder={placeholder ?? ''}
        styles={{
          control: (provided, { isFocused }) => ({
            ...provided,
            minHeight: '3.125rem',
            paddingLeft: '0.5rem',
            fontSize: '1rem',
            border: `${theme.deprecated_.border.size} ${theme.deprecated_.border.style} ${(() => {
              if (isFocused) return theme.deprecated_.colors.text;
              if (errorMessage) return theme.deprecated_.colors.red;
              return theme.deprecated_.border.color;
            })()}`,
            borderRadius: theme.deprecated_.border.radius,
            transition: 'border 0.2s linear',
            boxShadow: 'none',
            '&:hover': {},
          }),
          dropdownIndicator: () => ({
            backgroundImage:
              'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23283E4C%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 0.7rem top 50%',
            backgroundSize: '0.65rem auto',
            width: '2rem',
            height: '100%',
            '& svg': { display: 'none' },
          }),
        }}
      />

      {errorMessage && <StyledError>{errorMessage}</StyledError>}
    </StyledLabel>
  );
};
