import { type FC, type PropsWithChildren } from 'react';

import { getChildIntroImage } from '$shared/utils/childIntroImage';

import { SvgPath } from '../components/SvgPath';

import { getBackground } from './backgroundUtils';
import {
  StyledBackground,
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledSubtitle,
  StyledTitle,
  StyledTitleContainer,
  StyledTitleIcon,
} from './styled';

type SummaryContentProps = {
  title: string;
  subtitle?: string;
  className?: string;
  onClick?: () => void;
};

export const SummaryContent: FC<PropsWithChildren<SummaryContentProps>> = ({
  title,
  subtitle,
  className,
  children,
  onClick,
}) => {
  const icon = getChildIntroImage(title);
  const backgroundImage = getBackground(title);

  return (
    <StyledContainer className={className} onClick={onClick}>
      <StyledHeader>
        <SvgPath />
        <StyledBackground src={backgroundImage} />
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        </StyledTitleContainer>
        <StyledTitleIcon src={icon} />
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  );
};
