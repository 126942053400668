import { type FieldProps, utils, type Widget } from '@rjsf/core';
import { type FC } from 'react';

const { getUiOptions, getWidget, getDefaultRegistry } = utils;

export const NullField: FC<FieldProps> = ({
  schema,
  uiSchema,
  idSchema,
  formData,
  required,
  disabled,
  readonly,
  autofocus,
  registry = getDefaultRegistry(),
  rawErrors,
}) => {
  const { widgets, formContext } = registry;
  const { widget, ...options } = getUiOptions(uiSchema) ?? {};

  const WidgetComponent = getWidget(schema, widget as Widget, widgets);

  return (
    <WidgetComponent
      schema={schema}
      uiSchema={uiSchema}
      id={idSchema && idSchema.$id}
      options={options}
      value={formData}
      required={required}
      disabled={disabled}
      readonly={readonly}
      formContext={formContext}
      autofocus={autofocus}
      rawErrors={rawErrors}
      onFocus={() => null}
      onChange={() => null}
      onBlur={() => null}
      placeholder=""
      label=""
      multiple={false}
    />
  );
};
