import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { getUrlRootFromWindow } from '$shared/utils/url';

import { PageWideLoading } from '../../components/PageWideLoading';
import { type AssessmentQuery, type AssessmentQueryVariables } from '../../graphql/__generated__/AssessmentQuery';
import { ASSESSMENT_QUERY } from '../../graphql/assessments';
import { MissingQueryDataError } from '../../graphql/errors';
import { routes } from '../../routes';

import { useAuth } from './hooks';

type AssessmentParams = {
  slug: string;
};

export const Assessment: FC<PropsWithChildren> = () => {
  const { slug } = useParams<AssessmentParams>();

  // Ensure user is authorized to view this assessment
  const { authorized, redirectTo } = useAuth(slug);

  const { data, loading, error } = useQuery<AssessmentQuery, AssessmentQueryVariables>(ASSESSMENT_QUERY, {
    variables: { slug },
  });
  useGraphQLErrorHandling(error);

  if (loading) {
    return <PageWideLoading />;
  }

  if (!authorized) {
    window.location.href = redirectTo || getUrlRootFromWindow();
    return <PageWideLoading />;
  }

  if (!data?.assessmentsNamespace.assessment) throw new MissingQueryDataError('AssessmentQuery');

  const { assessment } = data.assessmentsNamespace;

  return <Redirect to={routes.assessments.assessment.step.url({ slug, step: assessment.step.id })} />;
};
