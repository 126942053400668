import { useMutation } from '@apollo/client';
import React, { type FC, type PropsWithChildren } from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';

import 'react-contexify/dist/ReactContexify.css';
import { type HideChatMessage, type HideChatMessageVariables } from '../../graphql/__generated__/HideChatMessage';
import {
  type RestoreChatMessage,
  type RestoreChatMessageVariables,
} from '../../graphql/__generated__/RestoreChatMessage';
import { HIDE_CHAT_MESSAGE, RESTORE_CHAT_MESSAGE } from '../../graphql/messages';

type MessageContextMenuProps = {
  messageSid: string;
  isOutgoing: boolean;
  isDeleted?: boolean;
  conversationSid: string;
  isStaff: boolean;
};

export const MessageContextMenu: FC<PropsWithChildren<MessageContextMenuProps>> = ({
  children,
  messageSid,
  isDeleted,
  isStaff,
  conversationSid,
}) => {
  const { show } = useContextMenu({ id: messageSid });
  const [hideChatMessage] = useMutation<HideChatMessage, HideChatMessageVariables>(HIDE_CHAT_MESSAGE);
  const [restoreChatMessage] = useMutation<RestoreChatMessage, RestoreChatMessageVariables>(RESTORE_CHAT_MESSAGE);
  const input = {
    conversationSid,
    messageSid,
  };
  const hideMessage = async () => {
    await hideChatMessage({
      variables: { input },
    });
  };
  const restoreMessage = async () => {
    await restoreChatMessage({
      variables: { input },
    });
  };
  const displayMenu = (e: React.MouseEvent) => {
    if (isStaff) show(e);
  };

  const getMenuItem = () => {
    if (isDeleted) return <Item onClick={restoreMessage}>Show Message</Item>;
    return <Item onClick={hideMessage}>Hide Message</Item>;
  };

  return (
    <div>
      <div onContextMenu={show} />
      <div onContextMenu={displayMenu}>{children}</div>

      <Menu id={messageSid}>{getMenuItem()}</Menu>
    </div>
  );
};
