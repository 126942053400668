import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren, useState } from 'react';

import { hasErrorShape } from '@littleotter/kit/utils';
import { Collapse, Header } from '@littleotter/legacy-components';

import { ErrorBoundary, type ErrorComponentProps } from '$services/errors';

import { type ViewerQuery } from '../../graphql/__generated__/ViewerQuery';
import { VIEWER_QUERY } from '../../graphql/user';
import { routes } from '../../routes';

import {
  ButtonsContainer,
  NumberedList,
  StyledButton,
  StyledContextContainer,
  StyledError,
  StyledErrorContainer,
  StyledLink,
  StyledSection,
  StyledStackTrace,
} from './styles';

export const AppErrorBoundary: FC<PropsWithChildren> = ({ children }) => {
  // Use array of errors, for when the error boundary catches multiple errors at once.
  const [errors, setErrors] = useState<Omit<ErrorComponentProps, 'resetError'>[]>([]);
  const { data } = useQuery<ViewerQuery>(VIEWER_QUERY);
  return (
    <ErrorBoundary
      fallback={() => (
        <StyledSection>
          <Header as="h1">Something went wrong</Header>
          <ButtonsContainer>
            <StyledButton
              onClick={() => {
                window.location.reload();
              }}
            >
              Retry
            </StyledButton>
            {/* the link needs to be a regular anchor to refresh the entire page */}
            <StyledLink isAnchor href={routes.home.url()}>
              Back to Home
            </StyledLink>
          </ButtonsContainer>
          {data?.viewer?.isStaff
            ? // Error component for staff show more details
              errors.map(({ eventId, error, componentStack }) => (
                <StaffErrorMessage key={eventId} eventId={eventId} error={error} componentStack={componentStack} />
              ))
            : // Error component for non-staff only shows error ID
              errors.map(({ eventId }) => <ErrorMessage key={eventId} eventId={eventId} />)}
        </StyledSection>
      )}
      onError={(error, componentStack, eventId) => {
        setErrors([...errors, { eventId, error, componentStack: componentStack ?? '' }]);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const StaffErrorMessage: FC<PropsWithChildren<Omit<ErrorComponentProps, 'resetError'>>> = ({
  eventId,
  error,
  componentStack,
}) => {
  const _error = hasErrorShape(error) ? error : new Error('An unexpected error occurred');

  return (
    <StyledErrorContainer>
      <StyledContextContainer>
        An unexpected error occurred while loading this page. Follow the steps below to report this error to the team so
        we can help fix the problem!
        <NumberedList>
          <li>Take a screenshot of the error message and the expanded details below.</li>
          <li>Take note of the page you are on and the steps you took right before the error appeared.</li>
          <li>
            Post all of this information on our `#little-otter-support` Slack channel, where a member of our team can
            support you.
          </li>
        </NumberedList>
        Sorry again for the hassle and we hope to have your issue resolved shortly!
      </StyledContextContainer>
      <StyledError>{`Uncaught ${_error.name}(${eventId}): ${_error.message}`}</StyledError>
      <Collapse label="The above error occurred unexpectedly. Expand for more details...">
        <StyledStackTrace>{_error.stack}</StyledStackTrace>
        <StyledStackTrace>{componentStack}</StyledStackTrace>
      </Collapse>
    </StyledErrorContainer>
  );
};

const ErrorMessage: FC<PropsWithChildren<Pick<ErrorComponentProps, 'eventId'>>> = ({ eventId }) => (
  <StyledErrorContainer>
    <StyledError>{`Error ID: ${eventId}`}</StyledError>
  </StyledErrorContainer>
);
