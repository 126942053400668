import { UserProfile } from '@clerk/clerk-react';
import './index.css';

export const ClerkUserProfile = () => {
  return (
    <UserProfile
      appearance={{
        elements: {
          menuButton: 'lo-hide-child',
          profileSection__emailAddresses: 'lo-hide-parent',
          profileSectionPrimaryButton: 'lo-hide-child',
        },
      }}
    />
  );
};
