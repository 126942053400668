import { type FC, type PropsWithChildren } from 'react';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';

import { Conversation } from './Conversation';
import { ConversationsList } from './ConversationsList';

export const Messages: FC<PropsWithChildren> = () => {
  return (
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.messages.conversation)}>
        <Page>
          <Conversation />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.messages.home)}>
        <Page hasHeader headerTitle="Messages">
          <ConversationsList />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
