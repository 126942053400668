import { type FC, type PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePartnership } from '$shared/hooks/usePartnership';
import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../../components/MetadataRoute';
import { Page } from '../../../components/Page';
import { RouteSwitch } from '../../../components/RouteSwitch';
import { routes } from '../../../routes';

import { OrderConfirmationWithPrerequisites } from './OrderConfirmation';
import { type SessionData, SessionDetailsWithPrerequisites } from './SessionDetails';

export const WelcomeCallPaymentDetails: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const [sessionData, setSessionData] = useState<SessionData>();
  const { removePartnershipId } = usePartnership();
  const goToOrderConfirmation = (sessionDetails: SessionData) => {
    setSessionData(sessionDetails);
    // Removes stored partnershipId so that the user can select other partners when creating future children from their account
    removePartnershipId();
    history.push(routes.paymentDetails.welcomeCall.confirmation.url());
  };

  const goToFamilyMentalHealthCheckup = () => {
    history.push(routes.checkup.home.url());
  };

  return (
    <RouteSwitch>
      <MetadataRoute withoutMobileNavbar {...routeProps(routes.paymentDetails.welcomeCall.home)}>
        <Page>
          <SessionDetailsWithPrerequisites onSessionBooked={goToOrderConfirmation} />
        </Page>
      </MetadataRoute>
      <MetadataRoute withoutMobileNavbar {...routeProps(routes.paymentDetails.welcomeCall.confirmation)}>
        <Page>
          <OrderConfirmationWithPrerequisites
            sessionDetails={sessionData}
            goToFamilyMentalHealthCheckup={goToFamilyMentalHealthCheckup}
          />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
