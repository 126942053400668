import { useLazyQuery } from '@apollo/client';
import {
  type Participant,
  type Conversation as TwilioConversation,
  type Message as TwilioMessage,
} from '@twilio/conversations';
import { AnimatePresence } from 'framer-motion';
import { debounce } from 'lodash-es';
import { unparse } from 'papaparse';
import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { logger } from '$services/logging';
import { useConversations } from '$shared/contexts/Conversations';
import { downloadBlob } from '$shared/utils/downloadBlob';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { type ConversationInfo } from '../../../graphql/__generated__/ConversationInfo';
import { CONVERSATION_INFO_QUERY } from '../../../graphql/messages';
import { routes } from '../../../routes';
import { ConversationHeader } from '../components';
import { getConversationTitle, getConversationTitleWithTimezone } from '../utils/getConversationTitle';

import { ConversationAlertList, NewMessageForm, type NewMessageFormData } from './components';
import ConversationClosedBanner from './components/ConversationClosedBanner';
import { type Caregiver, CaregiverLinks } from './components/ConversationLinks';
import { ConversationOutOfOfficeMessage } from './components/ConversationOutOfOfficeMessage';
import {
  AnchorItem,
  type AnchorProps,
  BottomContainer,
  ConversationContainer,
  OutOfOfficeLabel,
  StyledList,
  StyledMessage,
} from './components/styled';
import { type MessageAttributes } from './types';
import { isWithinHolidayBreak } from './utils';
import { isOutOfOffice } from './utils/isOutOfOffice';

const Anchor: FC<PropsWithChildren<AnchorProps>> = ({ enabled }) => (
  <AnchorItem id="last-message-anchor" enabled={enabled}>
    <div />
  </AnchorItem>
);

export const Conversation: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { client: conversationsClient, isLoading, hasErrors } = useConversations();
  const [getConversationInfo, { data, loading, error }] = useLazyQuery<ConversationInfo>(CONVERSATION_INFO_QUERY);
  const params = useParams<{ id: string }>();
  const [messages, setMessages] = useState<TwilioMessage[]>([]);
  const [messageDraft, setMessageDraft] = useState<string>('');
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [conversation, setConversation] = useState<TwilioConversation | null>(null);
  const [conversationClosed, setConversationClosed] = useState<boolean>(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isLoadingCsvButton, setIsLoadingCsvButton] = useState(false);
  const [anchoredMessage, setAnchoredMessage] = useState<number>();
  const [isAnchoredToLastMessage, setIsAnchoredToLastMessage] = useState(false);
  const [scrollToAnchor, setScrollToAnchor] = useState<boolean | ScrollIntoViewOptions>(false);
  const listRef = useRef<HTMLOListElement>(null);

  useEffect(() => {
    const getConversationMessages = async () => {
      if (!conversationsClient || !params.id) {
        return;
      }
      try {
        const conversationFromTwilio = await conversationsClient.peekConversationBySid(params.id);
        setConversation(conversationFromTwilio);
        const closed = conversationFromTwilio.state?.current === 'closed';
        setConversationClosed(closed);
        getConversationInfo({ variables: { sid: conversationFromTwilio.sid } });
        let unreadMessageCount = 0;
        if (!closed) {
          unreadMessageCount = (await conversationFromTwilio.getUnreadMessagesCount()) || 0;
        }
        const messagesFromTwilio = await conversationFromTwilio.getMessages(10 + unreadMessageCount);
        setMessages(messagesFromTwilio.items);
        setHasMoreMessages(messagesFromTwilio.hasPrevPage);

        const lastReadMessage = conversationFromTwilio.lastReadMessageIndex;
        const lastMessage = conversationFromTwilio.lastMessage?.index;
        if (lastReadMessage === lastMessage || closed) {
          setAnchoredMessage(undefined);
          setIsAnchoredToLastMessage(true);
        } else {
          const anchoredMessageIndex = Math.min(lastReadMessage ?? 0, lastMessage ?? 0);
          setAnchoredMessage(anchoredMessageIndex);
          setIsAnchoredToLastMessage(false);
        }
        setScrollToAnchor(true);
        if (!closed) {
          conversationFromTwilio.setAllMessagesRead();
        }
      } catch (e) {
        const err = new Error('Error getting twilio conversation message', { cause: e });
        logger.error(err, {
          messageId: params.id,
        });
        history.push(routes.messages.home.url());
      }
    };
    getConversationMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationsClient, getConversationInfo, params.id]);

  useLayoutEffect(() => {
    if (!scrollToAnchor) {
      return;
    }

    setScrollToAnchor(false);

    // Delay the scrollIntoView call a bit in case elements are still being animated on the screen,
    // so we end up properly anchored to the element's final position
    setTimeout(() => {
      if (anchoredMessage) {
        document.getElementById(`message${anchoredMessage}`)?.scrollIntoView(scrollToAnchor);
      }
      if (isAnchoredToLastMessage) {
        document.getElementById('last-message-anchor')?.scrollIntoView(scrollToAnchor);
      }
    }, 400);
  }, [anchoredMessage, isAnchoredToLastMessage, scrollToAnchor]);

  useEffect(() => {
    const addNewMessage = (newMessage: TwilioMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      conversation?.setAllMessagesRead();
    };

    conversation?.on('messageAdded', addNewMessage);

    return () => {
      conversation?.off('messageAdded', addNewMessage);
    };
  }, [conversation]);

  useEffect(() => {
    const updateMessage = () => {
      conversation?.setAllMessagesRead();
    };

    conversation?.on('messageUpdated', updateMessage);

    return () => {
      conversation?.off('messageUpdated', updateMessage);
    };
  }, [conversation]);

  useEffect(() => {
    const getParticipants = async () => {
      const participantsFromTwilio = (await conversation?.getParticipants()) ?? [];
      setParticipants(participantsFromTwilio);
    };
    getParticipants();

    conversation?.on('participantUpdated', getParticipants);

    return () => {
      conversation?.off('participantUpdated', getParticipants);
    };
  }, [conversation]);

  const isMessageRead = useCallback(
    (message: TwilioMessage) => {
      if (message.author !== data?.viewer?.id) {
        return;
      }

      const recipients = participants.filter((participant) => participant.identity !== message?.author);
      return recipients.every((participant) => (participant.lastReadMessageIndex || -1) >= message?.index);
    },
    [data, participants]
  );

  const isDeleted = (message: TwilioMessage) => {
    // @ts-expect-error: TODO: find a way to type this method
    return message.attributes?.is_deleted;
  };

  const otherParticipants = useMemo(() => {
    if (!data || !data.viewer) return [];

    const {
      viewer,
      conversation: { participants: participantsData },
    } = data;

    return participantsData.filter((participant) => participant.id !== viewer.id);
  }, [data]);

  const caregivers: Caregiver[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.conversation.participants
      .filter((p) => !p.isStaff)
      .map((p) => {
        const caregiver: Caregiver = {
          id: p.id,
          familyId: p.family?.id,
          intakeQClientId: p.externalClientUuid || undefined,
          name: `${p.firstName} ${p.lastName}`,
        };
        return caregiver;
      });
  }, [data]);

  if (loading || !conversation || isLoading) {
    return <PageWideLoading />;
  }
  if (error) throw new Error(`Error in GraphQL response: ${error.message}`);

  // `hasErrors`: If we throw an error, we would trigger the error boundary on
  // certain cases because of a race condition on `useTwilioClient` caused by
  // `ConversationsViewerQuery`
  if (!data?.viewer || hasErrors) return null;

  const { viewer } = data;

  const onScroll = async () => {
    if (!listRef.current) {
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = listRef.current;
    if (scrollHeight - clientHeight - scrollTop < 1) {
      // We're scrolled all the way down, enable the sticky 'last message anchor'
      setAnchoredMessage(undefined);
      setIsAnchoredToLastMessage(true);
    } else {
      // As we scroll up, find the first message visible in the viewport and make it the new anchor
      const topMessage = messages.find((message) => {
        const top = document.getElementById(`message${message.index}`)?.offsetTop ?? 0;
        return top >= scrollTop;
      });
      if (!topMessage) {
        return;
      }
      setAnchoredMessage(topMessage.index);
      setIsAnchoredToLastMessage(false);
    }

    getMoreMessages();
  };

  const getMoreMessages = async () => {
    if (listRef.current && listRef.current.scrollTop < 500 && hasMoreMessages) {
      try {
        const previousFirstMessage = messages[0].index;
        const newMessages = await conversation.getMessages(10, previousFirstMessage - 1);
        if (newMessages?.items) {
          // If we're scrolled all the way up, the scroll position will be anchored to the top
          // instead of to the anchoredElement, so let's scroll down just a bit
          if (listRef.current.scrollTop === 0) {
            listRef.current.scrollTo({ top: 1 });
          }

          setMessages([...newMessages.items, ...messages]);
          setHasMoreMessages(newMessages.hasPrevPage);
        }
      } catch (e) {
        logger.error(new Error('Failed while trying to load more messages', { cause: e }));
      }
    }
  };

  const sendNewFile = async (newFile: File) => {
    // Note that @twilio/conversations v2.0.0 does have a 'filename' attribute for SendMediaOptions,
    // but still it doesn't seem to work properly as of now.
    // Apparently (due to a bug) the filename only shows up when uploading using multipart/form-data
    // and setting it in the Content-Disposition header.
    // So here it's being sent as a custom message attribute as well, to be used as a fallback.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: wonky typing of twilio files: "Type 'File' is not assignable to type 'string | Buffer'."
    await conversation.sendMessage({ contentType: newFile.type, media: newFile }, {
      filename: newFile.name,
    } as MessageAttributes);
  };

  const sendNewText = async (values: NewMessageFormData) => {
    await conversation.sendMessage(values.newMessage);

    setAnchoredMessage(undefined);
    setIsAnchoredToLastMessage(true);
    setScrollToAnchor({ behavior: 'smooth' });
  };

  const downloadAllMessagesCsv = async () => {
    setIsLoadingCsvButton(true);
    const messageCount = await conversation.getMessagesCount();
    const paginator = await conversation.getMessages(messageCount, undefined, 'forward');

    const conversationData = paginator.items.map((message) => ({
      sid: message.sid,
      conversationSid: message.conversation.sid,
      body: message.body,
      author: message.author,
      participantSid: message.participantSid,
      dateCreated: message.dateCreated,
      mediaSid: message.media?.sid ?? null,
      mediaFilename: message.media?.filename ?? null,
      mediaContentType: message.media?.contentType ?? null,
      mediaSize: message.media?.size ?? null,
    }));
    const blob = new Blob([unparse(conversationData)], { type: 'application/octet-stream' });
    downloadBlob(blob, 'conversation.csv');
    setIsLoadingCsvButton(false);
  };

  const headerTitle = viewer?.isStaff
    ? getConversationTitleWithTimezone(otherParticipants)
    : getConversationTitle(otherParticipants);

  const providersOutOfOffice = data.conversation.participants.filter((participant) =>
    isOutOfOffice(participant.outOfOfficeDates)
  );
  const alertMessages: string[] = [];
  if (isWithinHolidayBreak(new Date())) {
    alertMessages.push('Due to the holiday break, we may take longer than usual to reply to your messages.');
  }

  return (
    <ConversationContainer>
      <ConversationHeader title={headerTitle} />
      {viewer?.isStaff && caregivers?.length > 0 && <CaregiverLinks caregivers={caregivers} />}
      {providersOutOfOffice.length > 0 && <OutOfOfficeLabel>Out of office</OutOfOfficeLabel>}
      <AnimatePresence initial={false}>
        <StyledList layout conversationClosed={conversationClosed} ref={listRef} onScroll={debounce(onScroll, 500)}>
          {messages.map(
            (message) =>
              (!isDeleted(message) || data?.viewer?.isStaff) && (
                <StyledMessage
                  message={message}
                  read={isMessageRead(message)}
                  isDeleted={isDeleted(message)}
                  viewer={viewer}
                  isAnchored={message.index === anchoredMessage}
                  key={message.sid}
                />
              )
          )}
          <Anchor enabled={isAnchoredToLastMessage} />
        </StyledList>
      </AnimatePresence>
      <BottomContainer>
        <ConversationAlertList messages={alertMessages} />
        {providersOutOfOffice.length > 0 && <ConversationOutOfOfficeMessage providers={providersOutOfOffice} />}
        {conversationClosed ? (
          <ConversationClosedBanner
            otherParticipants={otherParticipants.map((p) => parseInt(p.id, 10))}
            isLoadingCsvButton={isLoadingCsvButton}
            onCsvDownloadClick={downloadAllMessagesCsv}
          />
        ) : (
          <NewMessageForm
            onSubmit={sendNewText}
            sendNewFile={sendNewFile}
            isLoadingCsvButton={isLoadingCsvButton}
            onCsvDownloadClick={downloadAllMessagesCsv}
            defaultMessageDraft={messageDraft}
            updateDraftMessage={setMessageDraft}
          />
        )}
      </BottomContainer>
    </ConversationContainer>
  );
};
