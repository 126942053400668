import { type FC, type PropsWithChildren } from 'react';

import { type BaseIconName, Icon, type IconName } from '@littleotter/legacy-components';

type NavbarIconProps = {
  baseName: BaseIconName;
  fill?: boolean;
  notification?: boolean;
};

export const NavbarIcon: FC<PropsWithChildren<NavbarIconProps>> = ({ baseName, fill, notification, ...rest }) => {
  const fillName = fill ? 'Fill' : '';
  const notificationName = notification ? 'Notification' : '';
  const iconName = `${baseName}${fillName}${notificationName}` as IconName;

  return <Icon name={iconName} {...rest} />;
};
