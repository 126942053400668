/**
 * Enter a string and return the number value.
 *
 * @param string String to be hashed
 *
 * @example
 * ```
 * hash('kevin') => 541
 * ```
 */
export const hashString = (key: string) => {
  let hash = 0;
  // eslint-disable-next-line
  for (let i = 0; i < key.length; i++) {
    hash += key.charCodeAt(i);
  }
  return hash;
};
