import { type SubjectInput } from '../../../graphql/lo1/generated';
import { isChildSubject, type Subject, SubjectType } from '../types';

import { genderToGqlSex } from './genderToGqlSex';

export const subjectToGqlSubjectInput = (subject: Subject): SubjectInput => {
  if (isChildSubject(subject)) {
    return {
      subjectType: SubjectType.Child,
      childSubject: {
        ageInMonths: subject.ageInMonths,
        childId: subject.id,
        sex: genderToGqlSex(subject.gender),
      },
    };
  }
  return {
    subjectType: subject.subjectType,
  };
};
