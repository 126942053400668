import { type Client } from '@twilio/conversations';
import { createContext, type PropsWithChildren, useContext } from 'react';

import { type _Pagination, initialPagination } from '@littleotter/legacy-components/src/components/Pagination';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import { usePaginatedData } from '../../hooks/usePaginatedData';

import { useConversationsClient } from './hooks/useConversationsClient';
import { type ConversationInfo, useConversationsInfo } from './hooks/useConversationsInfo';
import { useFilteredConversations } from './hooks/useFilteredConversations';
import { useMessagesNotification } from './hooks/useMessagesNotification';
import { useTwilioConversations } from './hooks/useTwilioConversations';
import { useUnreadMessagesCount } from './hooks/useUnreadMessagesCount';

type ConversationsContextValue = {
  client: Client | null;
  conversations: ConversationPreview[];
  totalConversations: number;
  pagination: _Pagination;
  conversationInfoBySid: Map<string, ConversationInfo>;
  unreadMessagesCount: number;
  isLoading: boolean;
  hasErrors: boolean;
  filterReady: boolean;
};

const defaultContextValue: ConversationsContextValue = {
  client: null,
  conversations: [],
  totalConversations: 0,
  pagination: initialPagination,
  conversationInfoBySid: new Map<string, ConversationInfo>(),
  unreadMessagesCount: 0,
  isLoading: false,
  hasErrors: false,
  filterReady: false,
};

const ConversationsContext = createContext<ConversationsContextValue>(defaultContextValue);

export const useConversations = () => useContext(ConversationsContext);

export const ConversationsProvider = ({ children }: PropsWithChildren) => {
  const [client, clientState] = useConversationsClient();
  const [allConversations, conversationsState] = useTwilioConversations(client);
  const { filteredConversations, filterReady } = useFilteredConversations(allConversations);
  const { visibleItems: conversations, pagination } = usePaginatedData<ConversationPreview>(filteredConversations);
  const [conversationsInfo] = useConversationsInfo(conversations);
  const unreadMessagesCount = useUnreadMessagesCount(client, conversations);
  useMessagesNotification(client);

  const queriesLoading = clientState === 'loading' || conversationsState === 'loading';
  const isLoading = queriesLoading && !conversations.length;
  const hasErrors = !client || clientState === 'error' || !conversations;

  const contextValue = {
    client,
    conversations,
    totalConversations: allConversations.length,
    pagination,
    conversationInfoBySid: conversationsInfo.reduce((acc, curr) => {
      acc.set(curr.conversationSid, curr);
      return acc;
    }, new Map()),
    unreadMessagesCount,
    isLoading,
    hasErrors,
    filterReady,
  };

  return <ConversationsContext.Provider value={contextValue}>{children}</ConversationsContext.Provider>;
};
