import { type FC, type PropsWithChildren } from 'react';

import { ImageIcon, type ImageIconType } from '../components/ImageIcon';
import { StepChip, type StepChipType } from '../components/StepChip';

import {
  BodyColumn,
  BodyContainer,
  BodyElement,
  DurationSpan,
  ImageContainer,
  LinkSpan,
  RowContainer,
  TitleSpan,
} from './styled';

export interface CheckRowProps {
  title: string;
  duration?: string;
  linkText: string;
  stepChip: StepChipType;
  image: ImageIconType;
  onClick?: () => void;
}

export const CheckRow: FC<PropsWithChildren<CheckRowProps>> = ({
  title,
  image,
  duration,
  stepChip,
  linkText,
  onClick,
}) => {
  return (
    <RowContainer onClick={onClick}>
      <BodyContainer>
        <BodyColumn>
          <StepChip type={stepChip} />
          <BodyElement>
            <div>
              <TitleSpan>{title}</TitleSpan>
              {duration && <DurationSpan>({duration})</DurationSpan>}
            </div>
          </BodyElement>
          <BodyElement>
            <LinkSpan>{linkText}</LinkSpan>
          </BodyElement>
        </BodyColumn>
        <ImageContainer>
          <ImageIcon type={image} />
        </ImageContainer>
      </BodyContainer>
    </RowContainer>
  );
};
