import { type FC, type PropsWithChildren, useMemo } from 'react';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { MissingQueryDataError } from '../../../../graphql/errors';
import { useLatestFmhcReportId } from '../../../Care/hooks/useLatestFmhcReportId';

import { useNextAppointmentQuery, useWorryDomains, useWorryDomainsQuery } from './hooks';
import { OrderConfirmationContent } from './OrderConfirmationContent';

type SessionDetails = {
  appointmentDatetime: Date;
  practitionerFirstName: string;
  practitionerLastName: string;
  practitionerCredentials?: string;
};

export type OrderConfirmationProps = {
  sessionDetails: SessionDetails | undefined;
  goToFamilyMentalHealthCheckup: () => void;
};

export const OrderConfirmation: FC<PropsWithChildren<OrderConfirmationProps>> = ({
  sessionDetails: passedSessionDetails,
  goToFamilyMentalHealthCheckup,
}) => {
  const {
    data: naData,
    loading: naLoading,
    error: naError,
  } = useNextAppointmentQuery({
    skip: !!passedSessionDetails,
  });
  const { data: wdData, loading: wdLoading, error: wdError } = useWorryDomainsQuery();

  const { latestFmhc, loading: latestFmhcIdLoading } = useLatestFmhcReportId(wdData?.viewer?.family?.id);
  const latestFmhcId = latestFmhc?.id;

  const appointmentDatetime = useMemo(
    () =>
      passedSessionDetails
        ? passedSessionDetails.appointmentDatetime
        : new Date(naData?.viewer?.nextAppointment?.startDatetime),
    [naData?.viewer?.nextAppointment?.startDatetime, passedSessionDetails]
  );

  const worryDomains = useWorryDomains(wdData?.viewer);

  const practitionerFirstName = passedSessionDetails
    ? passedSessionDetails.practitionerFirstName
    : naData?.viewer?.nextAppointment?.provider.firstName ?? '';
  const practitionerLastName = passedSessionDetails
    ? passedSessionDetails.practitionerLastName
    : naData?.viewer?.nextAppointment?.provider.lastName ?? '';
  const practitionerCredentials = passedSessionDetails ? passedSessionDetails.practitionerCredentials : '';

  if (naLoading || wdLoading || latestFmhcIdLoading) return <PageWideLoading />;
  if (naError) throw naError;
  if (wdError) throw wdError;

  if (naData && !naData.viewer?.nextAppointment) {
    throw new MissingQueryDataError('NextAppointmentQuery');
  }

  if (!wdData?.viewer || !wdData.viewer.family) {
    throw new MissingQueryDataError('OrderConfirmationWorryDomainsQuery');
  }

  return (
    <OrderConfirmationContent
      appointmentDatetime={appointmentDatetime}
      practitionerFirstName={practitionerFirstName}
      practitionerLastName={practitionerLastName}
      practitionerCredentials={practitionerCredentials}
      worryDomains={worryDomains}
      showHealthCheckupRow={latestFmhcId === undefined}
      onHealthCheckupRowClick={goToFamilyMentalHealthCheckup}
    />
  );
};
