import { format } from 'date-fns';
import { capitalize } from 'lodash-es';
import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { FiFileMinus } from 'react-icons/fi';
import { IoVideocamOutline } from 'react-icons/io5';
import styled from 'styled-components';

import { Header, Link } from '@littleotter/legacy-components';

import { type HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType } from '../../../graphql/__generated__/HolidayReportQuery';
import { routes } from '../../../routes';

import {
  CaregiverMentalHealth,
  ChildEmotionalAndBehavioralHealth,
  FamilyDynamics,
  HolidayStress,
  Summary,
} from './sections';
import { Article, Disclaimer, MainHeader, StyledGrayCard } from './styled';

export type HolidayReportContentProps = {
  reportResult: HolidayReportQuery_assessmentsNamespace_assessmentResponse_result_HolidayReportType;
  updatedAt: Date;
};

const StyledVideoIcon = styled(IoVideocamOutline)`
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

const StyledFileIcon = styled(FiFileMinus)`
  margin-right: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} / 2`)};
`;

export const HolidayReportContent: FC<PropsWithChildren<HolidayReportContentProps>> = ({ reportResult, updatedAt }) => {
  const { childInfo, summaryResult, familyDynamics, childEmotionAndBehavior, caregiverMentalHealth, holidayStress } =
    reportResult;

  return (
    <Article>
      <MainHeader>
        <Header as="h3">Holiday Mental Health Check-up</Header>
        <p>
          {format(updatedAt, 'MMMM d, y')} | {capitalize(childInfo.childName)} | {childInfo.childAgeYears} Years Old
        </p>
        <p>Completed by {capitalize(childInfo.parentName)}</p>
      </MainHeader>

      <StyledGrayCard>
        <strong>
          <StyledFileIcon />
          About the check-up
        </strong>
        <p>This is your personalized resource for the holidays. If you're looking for more help</p>
        <Link isAnchor href={routes.unauthOnboarding.url()}>
          Join Little Otter Today
        </Link>
      </StyledGrayCard>

      <Summary summaryResult={summaryResult} />

      {familyDynamics && <FamilyDynamics familyDynamics={familyDynamics} />}

      <ChildEmotionalAndBehavioralHealth
        emotionalChallengesResult={childEmotionAndBehavior.totalEmotional}
        behavioralChallengesResult={childEmotionAndBehavior.totalBehavioral}
      />

      {caregiverMentalHealth && <CaregiverMentalHealth caregiverMentalHealthResult={caregiverMentalHealth} />}

      <StyledGrayCard>
        <strong>
          <StyledVideoIcon />
          Talk to a Mental Health Provider
        </strong>
        <p>Little Otter provides personalized mental health support for families and kiddos.</p>
        <Link isAnchor href={routes.unauthOnboarding.url()}>
          Sign up for your first call today
        </Link>
      </StyledGrayCard>

      {holidayStress && <HolidayStress holidayStressResult={holidayStress} />}

      <StyledGrayCard>
        <p>
          We'd love to offer you a free kickoff session as a thank you for completing our check-up. We’re currently
          available in CA, UT, CO, NC, FL, TX, NY, PA, VA, GA and IL - sign-up now with code{' '}
          <strong>HOLIDAYTOOLKIT20</strong>
        </p>
      </StyledGrayCard>

      <Disclaimer>
        <em>
          The content is not intended to be a substitute for professional medical advice, diagnosis, or treatment.
          Always seek the advice of your physician or other qualified health provider with any questions you may have
          regarding a medical condition.
        </em>
      </Disclaimer>
    </Article>
  );
};
