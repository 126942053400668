import { ERROR_DISPATCH_LOG_LEVELS, LOG_LEVEL } from './constants';
import { type ErrorDispatchLogLevels } from './types';

import type { LogLevelDefinition, LogLevelName } from '.';

/**
 * A simple utility method for creating consistent definition objects for log levels.
 */
export const defineLogLevel = (level: LogLevelName): LogLevelDefinition => {
  return { name: level, value: LOG_LEVEL[level] };
};

/**
 * A simple utility method for determining if a log event should be dispatched as an error.
 */
export const shouldDispatchAsError = (level: LogLevelName): level is ErrorDispatchLogLevels => {
  return ERROR_DISPATCH_LOG_LEVELS.includes(level as ErrorDispatchLogLevels);
};
