import { Capacitor } from '@capacitor/core';
import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { Navbar } from '@littleotter/legacy-components';

import { MetadataRoute } from '../../components/MetadataRoute';

import { CheckupQuestionsContent } from './CheckupQuestions/CheckupQuestionsContent';
import { type AnswerCollection, type AnswerCollectionMap } from './CheckupQuestions/components/QuestionsContent/types';
import { CheckupIntroScreen } from './components/CheckupIntroScreen';
import { PageWrapper } from './styled';
import { isChildSubject, type QuestionAnswer, type SubjectAnswers, type SubjectQuestions } from './types';

enum TrackingSubjectEnum {
  PARENT_GUARDIAN = 'parent/guardian',
}

export type CheckupContentProps = {
  baseRoute: string;
  subjectsQuestions: SubjectQuestions[];
  onAssessmentCompletion: (answers: SubjectAnswers) => Promise<{ success: boolean }>;
  getFmhcId: () => string;
  onFmhcCompletion: () => Promise<void>;
};

const createCheckupQuestionsBaseRoute = (baseRoute: string, subjectIndex: number) => {
  const baseSlug = subjectIndex.toString();
  return `${baseRoute}/${baseSlug}`;
};

const FMHC_LOCAL_STORAGE_PREFIX = 'FMHC';

export const CheckupContent: FC<PropsWithChildren<CheckupContentProps>> = ({
  baseRoute,
  subjectsQuestions,
  onAssessmentCompletion,
  getFmhcId,
  onFmhcCompletion,
}) => {
  const history = useHistory();

  if (subjectsQuestions.length <= 0) {
    return null;
  }

  const firstAssessmentRoute = createCheckupQuestionsBaseRoute(baseRoute, 0);

  const loadAnswerCollectionMapFromStorage = (): AnswerCollectionMap => {
    const answerCollectionMapJSON = localStorage.getItem(`${FMHC_LOCAL_STORAGE_PREFIX}-${getFmhcId()}`);
    return answerCollectionMapJSON === null ? {} : JSON.parse(answerCollectionMapJSON);
  };
  const setAnswerCollectionInStorage = (subjectIndex: number, answerCollection: AnswerCollection) => {
    const answerCollectionMap = loadAnswerCollectionMapFromStorage();
    answerCollectionMap[subjectIndex] = answerCollection;
    localStorage.setItem(`${FMHC_LOCAL_STORAGE_PREFIX}-${getFmhcId()}`, JSON.stringify(answerCollectionMap));
  };

  const renderedCheckupAssessments = subjectsQuestions.map((subjectQuestions, subjectIndex) => {
    const { subject, questions } = subjectQuestions;

    const checkupQuestionsBaseRoute = createCheckupQuestionsBaseRoute(baseRoute, subjectIndex);
    const isLastFamilyMember = subjectIndex >= subjectsQuestions.length - 1;
    const trackingSubject: string =
      subjectIndex > subjectsQuestions.length - 2
        ? TrackingSubjectEnum.PARENT_GUARDIAN
        : `Child ${subjectIndex + 1} of ${subjectsQuestions.length - 2}`;
    const onAssessmentAnswered = (answers: QuestionAnswer[]) => {
      (async () => {
        const { success } = await onAssessmentCompletion({
          answers,
          subject,
        });
        if (!success) {
          return;
        }

        if (isLastFamilyMember) {
          await onFmhcCompletion();
          // clear local storage
          for (let i = 0; i < localStorage.length; i += 1) {
            const key = localStorage.key(i) ?? '';
            if (key.startsWith(FMHC_LOCAL_STORAGE_PREFIX)) {
              localStorage.removeItem(key);
            }
          }
        } else {
          const nextUrl = createCheckupQuestionsBaseRoute(baseRoute, subjectIndex + 1);
          history.push(nextUrl);
        }
      })();
    };

    const onSkipChild = () => {
      const nextUrl = createCheckupQuestionsBaseRoute(baseRoute, subjectIndex + 1);
      history.push(nextUrl);
    };

    const id = isChildSubject(subject) ? subject.id : subject.subjectType;

    const getAnswers = () => {
      const answers = loadAnswerCollectionMapFromStorage()[subjectIndex] ?? {};
      return answers;
    };
    const setAnswer = (variableName: string, answer: QuestionAnswer) => {
      let answerCollection = loadAnswerCollectionMapFromStorage()[subjectIndex] ?? {};
      answerCollection = { ...answerCollection, [variableName]: answer };
      setAnswerCollectionInStorage(subjectIndex, answerCollection);
    };

    return (
      <CheckupQuestionsContent
        isLastFamilyMember={isLastFamilyMember}
        childName={isChildSubject(subject) ? subject.firstName : undefined}
        key={`checkup-questions-${id}`}
        baseRoute={checkupQuestionsBaseRoute}
        questions={questions}
        getAnswers={getAnswers}
        setAnswer={setAnswer}
        onCompletion={onAssessmentAnswered}
        isSeekingCare={isChildSubject(subject) ? subject.isSeekingCare : undefined}
        onSkipChild={onSkipChild}
        subjectsQuestions={subjectsQuestions}
        subjectIndex={subjectIndex}
        trackingSubject={trackingSubject}
      />
    );
  });

  const isOniOS = Capacitor.getPlatform() === 'ios';

  return (
    <>
      <MetadataRoute fullHeight exact path={baseRoute}>
        <Navbar hasExtraIosPadding={isOniOS} goBack={history.goBack} />
        <PageWrapper>
          <CheckupIntroScreen beginCheckupHref={firstAssessmentRoute} />
        </PageWrapper>
      </MetadataRoute>
      {renderedCheckupAssessments}
    </>
  );
};
