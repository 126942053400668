import { type FC, type PropsWithChildren, type ReactElement } from 'react';

import { FullButton, Header } from '@littleotter/legacy-components';

import { routes } from '../../../../../../routes';

import {
  ButtonAndLinkContainer,
  Description,
  DescriptionContainer,
  FullLink,
  RowsContainer,
  SecondaryLink,
} from './styled';

export interface NextStepsProps {
  title: string;
  description: string;
  emptyDescription: string;
  checkRows: ReactElement[];
  onHealthCheckupButtonClick?: () => void;
}

export const NextSteps: FC<PropsWithChildren<NextStepsProps>> = ({
  description,
  emptyDescription,
  title,
  checkRows,
  onHealthCheckupButtonClick,
}) => {
  const renderDescription = checkRows.length === 0 ? emptyDescription : description;

  return (
    <>
      <Header as="h3">{title}</Header>
      <DescriptionContainer>
        <Description>{renderDescription}</Description>
      </DescriptionContainer>
      <RowsContainer>
        {checkRows.map((checkRow, checkRowIndex) => (
          <div key={checkRow.key}>
            {(checkRows.length === 1 || checkRowIndex > 0) && <hr />}
            {checkRow}
          </div>
        ))}
      </RowsContainer>

      {onHealthCheckupButtonClick ? (
        <ButtonAndLinkContainer>
          <FullButton onClick={onHealthCheckupButtonClick} variant="outline">
            Take a child & family mental health checkup
          </FullButton>
          <SecondaryLink href={routes.care.home.url()}>Go to Care Den</SecondaryLink>
        </ButtonAndLinkContainer>
      ) : (
        <FullLink href={routes.care.home.url()} variant="outline">
          Go to your Care Den
        </FullLink>
      )}
    </>
  );
};
