import { type AnchorHTMLAttributes, type FC, type PropsWithChildren } from 'react';
import { Anchorme } from 'react-anchorme';

import { MessageContextMenu } from '../../../../../../components/MessageContextMenu';
import { getTimestampString } from '../../../../utils/getTimestampString';

import { Attachment } from './Attachment';
import {
  ChatBubble,
  ChatBubbleTextContent,
  MessageBody,
  MessageMetaData,
  StyledLink,
  StyledName,
  StyledReadStatus,
} from './styled';

export type MessageItemProps = {
  isOutgoing: boolean;
  authorName: string;
  fileName?: string;
  mediaUrl?: string;
  contentType?: string;
  messageBody: string | null;
  dateCreated: Date | null;
  isRead: boolean;
  isDeleted?: boolean;
  isStaff: boolean;
  conversationSid: string;
  messageSid: string;
  onClick?: () => void;
};

export const MessageItem: FC<PropsWithChildren<MessageItemProps>> = ({
  isOutgoing,
  authorName,
  fileName,
  mediaUrl,
  contentType,
  messageBody,
  dateCreated,
  isRead,
  isDeleted,
  conversationSid,
  messageSid,
  isStaff,
  onClick,
}) => {
  const CustomLink = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
    return <StyledLink isOutgoing={isOutgoing} {...props} />;
  };
  const timestamp = (dateCreated && new Date(dateCreated)) || new Date();

  return (
    <ChatBubble isOutgoing={isOutgoing} isDeleted={isDeleted}>
      {!isOutgoing && <StyledName>{isDeleted ? `deleted by ${authorName}` : authorName}</StyledName>}
      <Attachment
        fileName={fileName}
        mediaUrl={mediaUrl}
        contentType={contentType}
        isOutgoing={isOutgoing}
        isDeleted={isDeleted}
        onClick={onClick}
      />
      <MessageContextMenu
        messageSid={messageSid}
        isOutgoing={isOutgoing}
        conversationSid={conversationSid}
        isDeleted={isDeleted}
        isStaff={isStaff}
      >
        <ChatBubbleTextContent>
          {messageBody && (
            <MessageBody>
              <Anchorme linkComponent={CustomLink}>{messageBody}</Anchorme>
            </MessageBody>
          )}
          <MessageMetaData isOutgoing={isOutgoing}>
            <span>{getTimestampString(timestamp)}</span>
            {isOutgoing && <StyledReadStatus read={isRead} />}
          </MessageMetaData>
        </ChatBubbleTextContent>
      </MessageContextMenu>
    </ChatBubble>
  );
};
