import { darken, getContrast, lighten } from 'polished';
import { type FC, type PropsWithChildren, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import { stringifySearchParams } from '@littleotter/kit/utils';
import { Link, Tag, type Theme } from '@littleotter/legacy-components';

import {
  type ResourcesFragment_edges_node_tags_edges_node,
  type ResourcesFragment_edges_node_worryDomains_edges_node,
} from '../../../../graphql/__generated__/ResourcesFragment';
import { routes } from '../../../../routes';

const StyledTag = styled(Tag)<{ $isLink?: boolean }>`
  ${({ $isLink, backgroundColorHex }) =>
    $isLink &&
    backgroundColorHex &&
    css`
      transition: all 0.2s linear;

      &:hover,
      &:focus {
        box-shadow: 0 0 8px ${backgroundColorHex};
      }

      &:active {
        background-color: ${darken(0.1, backgroundColorHex)};
      }
    `}
`;

type TagInfo = { base: keyof Theme['deprecated_']['colors']; lighten: number };

const tagTextToColorMap: {
  [index: string]: TagInfo;
} = {
  'Worries & Anxieties': { base: 'backgroundBlue', lighten: 0.1 },
  'Separation Worries': { base: 'backgroundBlue', lighten: 0.15 },
  'Big Worries': { base: 'backgroundBlue', lighten: 0.2 },
  'Social Worries & Shyness': { base: 'backgroundBlue', lighten: 0.25 },
  Fears: { base: 'backgroundBlue', lighten: 0.25 },
  'Focus & Attention': { base: 'green', lighten: 0 },
  Impulsivity: { base: 'green', lighten: 0.1 },
  Inattention: { base: 'green', lighten: 0.15 },
  Hyperactivity: { base: 'green', lighten: 0.2 },
  Irritability: { base: 'green', lighten: 0.25 },
  'Anger & Aggression': { base: 'brandPurple', lighten: 0 },
  'Hurting People or Animals': { base: 'brandPurple', lighten: 0.05 },
  'Talking Back': { base: 'brandPurple', lighten: -0.05 },
  Defiance: { base: 'brandPurple', lighten: -0.1 },
  Hitting: { base: 'brandPurple', lighten: -0.15 },
  Biting: { base: 'brandPurple', lighten: -0.2 },
  Tantrums: { base: 'brandPurple', lighten: -0.25 },
  'Sadness & Crying': { base: 'textBlue', lighten: 0.1 },
  'Talking About Death': { base: 'textBlue', lighten: 0.2 },
  'Sleep & Bedtime': { base: 'red', lighten: 0 },
  'Sleep Regression': { base: 'red', lighten: 0.1 },
  'Nightmares, Night Terrors, Sleep Walking': { base: 'red', lighten: 0.15 },
  'Falling & Staying Asleep': { base: 'lightRed', lighten: -0.05 },
  Napping: { base: 'lightRed', lighten: 0.1 },
  "Sleeping in Parent's Bed": { base: 'lightRed', lighten: 0 },
  Toileting: { base: 'yellow', lighten: 0 },
  'Toileting Accidents': { base: 'yellow', lighten: 0.1 },
  'Wetting the Bed': { base: 'yellow', lighten: -0.05 },
  'COVID-19': { base: 'textBlue', lighten: 0.4 },
  'My Mental Health': { base: 'text', lighten: 0.25 },
  'Sensory Sensitivities': { base: 'lightGray', lighten: 0 },
  Eating: { base: 'brown', lighten: 0.2 },
  'Parent Questions': { base: 'cream', lighten: -0.2 },
};

type WorryDomainOrResourceTagProps = {
  worryDomainOrResource:
    | ResourcesFragment_edges_node_worryDomains_edges_node
    | ResourcesFragment_edges_node_tags_edges_node
    | { name: string; __typename?: string; pk?: number };
  isLink?: boolean;
};

const searchParamMap = {
  WorryDomainType: 'worryDomainIds',
  ResourceTagType: 'tagIds',
};

export const WorryDomainOrResourceTag: FC<PropsWithChildren<WorryDomainOrResourceTagProps>> = ({
  worryDomainOrResource,
  isLink,
}) => {
  const themeContext = useContext(ThemeContext);
  const tagInfo = tagTextToColorMap[worryDomainOrResource.name] as TagInfo | undefined;

  let backgroundColorHex: string | undefined;
  let textColor: keyof Theme['deprecated_']['colors'] | undefined;

  if (tagInfo) {
    backgroundColorHex = lighten(tagInfo.lighten, themeContext.deprecated_.colors[tagInfo.base]);
    textColor = getContrast(backgroundColorHex, themeContext.deprecated_.colors.white) > 2 ? 'white' : 'text';
  }

  const searchParamName = searchParamMap[worryDomainOrResource.__typename as keyof typeof searchParamMap];

  const tag = (
    <StyledTag $isLink={isLink} backgroundColorHex={backgroundColorHex} textColor={textColor}>
      {worryDomainOrResource.name}
    </StyledTag>
  );

  if (isLink) {
    return (
      <Link
        variant="clearStyles"
        href={{
          pathname: routes.library.explore.url(),
          search: stringifySearchParams({ [searchParamName]: [String(worryDomainOrResource.pk)] }),
        }}
      >
        {tag}
      </Link>
    );
  }

  return tag;
};
