import { useMemo } from 'react';

import { type FmhcReport as GqlFmhcReport, useGetFmhcReportQuery } from '../../../../graphql/lo1/generated';
import { getSubjectHash } from '../../utils/subject';

export const useGqlFmhcReportData = (fmhcId: string) => {
  const { data: gqlFmhcReportData, loading: gqlFmhcReportLoading } = useGetFmhcReportQuery({
    fetchPolicy: 'no-cache',
    variables: { request: { fmhcId } },
  });
  const gqlFmhcReports = useMemo(
    () =>
      (gqlFmhcReportData?.GetFmhcReport?.reports ?? []).reduce((map, report) => {
        map.set(getSubjectHash(report.subject), report);
        return map;
      }, new Map<string, GqlFmhcReport>()),
    [gqlFmhcReportData]
  );

  return { loading: gqlFmhcReportLoading, gqlFmhcReports };
};
