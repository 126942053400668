import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_familyStress } from '../../../../graphql/__generated__/BackToSchoolReportQuery';

import { CollapseSection } from './CollapseSection';
import { CenteredSignalStrengthBars, Section } from './styled';

type FamilyStressProps = {
  familyStressResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_familyStress;
};

export const FamilyStress: FC<PropsWithChildren<FamilyStressProps>> = ({ familyStressResult: { score, content } }) => (
  <Section>
    <Header as="h4" id="family-stress">
      Family Stress
    </Header>
    <p>The colored bars show how much stress your family has experienced during the last year.</p>

    <CenteredSignalStrengthBars barAmount={16} activeBars={score} axisLabel="Stress Level" />

    <CollapseSection
      meaningContent={content.meaning}
      whatICanDoToSupportContent={content.whatICanDoToSupport}
      activityToDoContent={content.activityToDo}
    />
  </Section>
);
