import { Observable, type Operation } from '@apollo/client';
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { type ErrorTypes } from '$shared/utils/errorHandlers';

export type ErrorCode = ErrorTypes | null;
export type GraphQLOperationError = {
  code: ErrorCode;
  operation?: Operation;
};

type ErrorObservable = Observable<GraphQLOperationError>;
type ErrorHandler = (error: GraphQLOperationError) => void;

export type ErrorContextValue = {
  errorObservable?: ErrorObservable;
  errorHandler?: ErrorHandler;
};

const ErrorContext = createContext<ErrorContextValue>({});

export const useErrorHandler = () => useContext(ErrorContext);

export const useErrorHandlerProviderValue = (): ErrorContextValue => {
  const [errorObservable, setErrorObservable] = useState<ErrorObservable>();

  // This needs to be a ref instead of a state. Otherwise, the App component
  // will end up in a render loop because the reference to `errorHandler` will
  // change every time there's a new subscription to the Observable
  const errorHandlersRef = useRef<ErrorHandler[]>([]);

  useEffect(() => {
    const observable = new Observable<GraphQLOperationError>((observer) => {
      const errorHandler = (value: GraphQLOperationError) => observer.next(value);
      errorHandlersRef.current = [...errorHandlersRef.current, errorHandler];

      return () => {
        errorHandlersRef.current = errorHandlersRef.current.filter((item) => item !== errorHandler);
      };
    });
    setErrorObservable(observable);
  }, []);

  const errorHandler = useCallback<ErrorHandler>((value) => {
    errorHandlersRef.current.forEach((triggerErrorHandler) => {
      triggerErrorHandler(value);
    });
  }, []);

  return {
    errorObservable,
    errorHandler,
  };
};

export const ErrorHandlerProvider: FC<PropsWithChildren<ErrorContextValue>> = ({
  errorObservable,
  errorHandler,
  children,
}) => <ErrorContext.Provider value={{ errorObservable, errorHandler }}>{children}</ErrorContext.Provider>;
