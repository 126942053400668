import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isIntakeQProduction } from '$configs/env';
import { useGraphQLErrorHandling } from '$shared/hooks';
import { prettyPrintSnakeCase } from '$shared/utils/formatString';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { ProviderCard } from '../../../components/ProviderCard';
import { type GetCareTeam } from '../../../graphql/__generated__/GetCareTeam';
import { MissingQueryDataError } from '../../../graphql/errors';
import { GET_CARE_TEAM } from '../../../graphql/profile';
import { routes } from '../../../routes';
import { MarginChildren } from '../components';

import { CareTeamEmptyState } from './CareTeamEmptyState';

// TODO: get these from gql
const ParentingSpecialistServiceId = isIntakeQProduction()
  ? 'eee8a3d8-3c4c-4f1f-9803-6eefe4423c17'
  : '45e59131-a4fb-498e-aa21-3596d855dd8f';
const TherapyServiceId = isIntakeQProduction()
  ? 'bc15f62d-4012-464f-bffc-a59f05680f87'
  : 'c7856c2b-4907-4380-ba21-e0f11a9b9e9b';
const PsychiatryServiceId = isIntakeQProduction()
  ? '3a1f87cd-52c7-4952-b211-d2c01c7d004f'
  : '4313b00b-3a32-4b84-b225-e5103ff05ce0';

const getServiceIdFromTitle = (providerTitle: string | null) => {
  let serviceId = ParentingSpecialistServiceId;

  if (providerTitle === 'therapist') {
    serviceId = TherapyServiceId;
  } else if (providerTitle === 'psychiatrist') {
    serviceId = PsychiatryServiceId;
  }
  return serviceId;
};

export const CareTeam: FC<PropsWithChildren> = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<GetCareTeam>(GET_CARE_TEAM);
  useGraphQLErrorHandling(error);

  const [providerList, setProviderList] = useState<
    Array<{
      pk: number;
      id: string;
      fullName: string;
      title: string;
      profilePicture: string;
      externalProviderId: string | null;
      serviceId: string | null;
    }>
  >([]);

  useEffect(() => {
    if (data?.viewer?.careTeam) {
      const providers = data?.viewer?.careTeam.providers.map(
        ({ id, firstName, lastName, profilePicture, providerTitle, externalProviderId, __typename }) => ({
          pk: Number(id),
          id: `${__typename}-${id}`,
          fullName: `${firstName} ${lastName}`,
          title: prettyPrintSnakeCase(`${providerTitle}`),
          profilePicture,
          externalProviderId,
          serviceId: getServiceIdFromTitle(providerTitle),
        })
      );
      setProviderList(providers ?? []);
    }
  }, [data?.viewer?.careTeam]);

  if (loading) return <PageWideLoading />;

  const viewer = data?.viewer;

  if (!viewer) throw new MissingQueryDataError('GetCareTeam');

  return (
    <MarginChildren>
      {providerList.map((provider) => (
        <ProviderCard
          key={provider.pk}
          fullName={provider.fullName}
          profilePictureSrc={provider.profilePicture}
          title={provider.title}
          pk={provider.pk}
          onMessageButtonClick={() => history.push(routes.messages.home.url())}
        />
      ))}
      {!providerList.length && <CareTeamEmptyState />}
    </MarginChildren>
  );
};
