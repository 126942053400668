import { useMutation, useQuery } from '@apollo/client';
import { math } from 'polished';
import { type FC, type PropsWithChildren, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Header, Modal } from '@littleotter/legacy-components';

import {
  type AcceptTermsAndPolicies,
  type AcceptTermsAndPoliciesVariables,
} from '../../graphql/__generated__/AcceptTermsAndPolicies';
import { type GetTermsAndPoliciesNotAcceptedList } from '../../graphql/__generated__/GetTermsAndPoliciesNotAcceptedList';
import { ACCEPT_TERMS_AND_POLICIES, GET_TERMS_AND_POLICIES_NOT_ACCEPTED_LIST } from '../../graphql/terms';
import { useViewer } from '../../shared/contexts/Viewer';

import { TermsAndConditionsModalContent } from './ModalContent';

const StyledModal = styled(Modal)`
  padding: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};

  > h2 {
    margin-bottom: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 2`)};
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const TermsAndConditionsModal: FC<PropsWithChildren> = () => {
  const { viewer } = useViewer();
  const { data, refetch } = useQuery<GetTermsAndPoliciesNotAcceptedList>(GET_TERMS_AND_POLICIES_NOT_ACCEPTED_LIST, {
    fetchPolicy: 'no-cache',
    skip: !viewer,
  });

  useEffect(() => {
    if (viewer) {
      refetch();
    }
  }, [viewer, refetch]);

  const [acceptTerms] = useMutation<AcceptTermsAndPolicies, AcceptTermsAndPoliciesVariables>(ACCEPT_TERMS_AND_POLICIES);

  const [currentTermsIndex, setCurrentTermsIndex] = useState(0);

  const allTermsAccepted = data?.termsAndPoliciesNotAcceptedList.length === currentTermsIndex;

  const onAcceptTerms = useCallback(
    async (pk: number) => {
      await acceptTerms({ variables: { input: { agreedTermsAndPolicies: [pk] } } });
      setCurrentTermsIndex((index) => index + 1);
    },
    [acceptTerms]
  );

  if (!data || allTermsAccepted) {
    return null;
  }

  const { termsAndPoliciesNotAcceptedList } = data;
  const { pk, name, dateActive, info, text } = termsAndPoliciesNotAcceptedList[currentTermsIndex];

  return (
    <StyledModal isOpen>
      <Header as="h2">Updated Terms & Conditions</Header>
      <TermsAndConditionsModalContent name={name} dateActive={dateActive} info={info} text={text} />
      <StyledButton onClick={() => onAcceptTerms(pk)}>I Agree</StyledButton>
    </StyledModal>
  );
};
