import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import { CardContainer, CloseIcon, Description, SadOtterIcon, Spacer, StyledLink, Title } from './styled';

export const ToastError: FC<PropsWithChildren> = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1000);
  }, []);

  const handleClose = () => setIsOpen(false);

  return (
    <CardContainer isOpen={isOpen}>
      <SadOtterIcon />
      <div>
        <Title>We’re sorry, that didn’t work.</Title>
        <Description>
          Try again or <StyledLink>contact us</StyledLink>
        </Description>
      </div>
      <Spacer />
      <CloseIcon onClick={handleClose} />
    </CardContainer>
  );
};
