import { useFlags as ldUseFlags } from 'launchdarkly-react-client-sdk';

import { type BaseFlagObject } from '../types';

/**
 * A factory function that wraps the `useFlags` hook from the LaunchDarkly React SDK, providing
 * us the ability to infer flag types from the consumer application.
 *
 * @returns A correctly typed instance of the `useFlags` hook from the LaunchDarkly React SDK.
 */
export const createUseFlagsHook = <T extends BaseFlagObject>() => {
  const useFlagsHookInstance = ldUseFlags as () => T;
  return useFlagsHookInstance;
};
