import { type FC, type PropsWithChildren } from 'react';

import { useStaffFmhcsQuery } from '$shared/scenarios';

import { intakeQClientUrl, routes } from '../../../../../routes';

import { StyledCaregiverLinkRow } from './styled';

export type Caregiver = {
  id: string;
  name: string;
  familyId?: string;
  intakeQClientId?: string;
};

export const CaregiverLinkRow: FC<PropsWithChildren<{ caregiver: Caregiver; showName: boolean }>> = ({
  caregiver,
  showName,
}) => {
  const [latestSubmittedFmhcs] = useStaffFmhcsQuery({
    familyId: caregiver.familyId ?? '', // if familyId is unknown, `skipQuery` is true.
    submitterId: caregiver.id,
    isSubmitted: true,
    skipQuery: !caregiver.familyId,
  });
  const latestSubmittedFmhc = latestSubmittedFmhcs?.[0];
  const intakeQClientLink = intakeQClientUrl(caregiver.intakeQClientId);

  return (
    <StyledCaregiverLinkRow key={caregiver.name}>
      {showName && <span>{caregiver.name}</span>}
      {!!intakeQClientLink && (
        <a href={intakeQClientLink} target="_blank" rel="noreferrer">
          IntakeQ Client
        </a>
      )}
      {!!latestSubmittedFmhc && (
        <a
          href={routes.provider.family.reports.url({
            familyId: latestSubmittedFmhc.familyId,
            fmhcId: latestSubmittedFmhc.id,
          })}
          target="_blank"
          rel="noreferrer"
        >
          Latest FMHC
        </a>
      )}
    </StyledCaregiverLinkRow>
  );
};
