import { math } from 'polished';
import styled, { keyframes } from 'styled-components';

import { animationDuration, baseAnimationDelay, runningAnimationDelay } from '../constants';

import { ReactComponent as BaseHerb3Image } from '../assets/Herb3.svg';

const herb3Animation = keyframes`
  from {
    opacity: 0;
    transform: translate(2rem);
  }

  to {
    opacity: 1;
    transform: translate(0);
  }
`;

export const Herb3Image = styled(BaseHerb3Image)`
  position: absolute;
  width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 6.7`)};
  height: auto;
  top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5.2`)};
  left: 0rem;

  animation-name: ${herb3Animation};
  animation-duration: ${animationDuration};
  animation-delay: calc(${baseAnimationDelay} + (${animationDuration} + ${runningAnimationDelay}) * 2);
  animation-timing-function: linear;
  animation-fill-mode: both;
`;
