import { type FC, type PropsWithChildren } from 'react';

import { type CarePlanQuery_treatmentNotes } from '../../../../../../graphql/__generated__/CarePlanQuery';

import { CardContent } from './CardContent';

export type CardProps = {
  onClick: () => void;
  title: string;
  subtitle: string;
  avatar: string;
  treatmentNote: CarePlanQuery_treatmentNotes;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({ onClick, title, subtitle, avatar, treatmentNote }) => (
  <CardContent onClick={onClick} title={title} subtitle={subtitle} avatar={avatar}>
    {treatmentNote?.summary?.map((question, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <span key={`question ${treatmentNote.pk} ${index}`}>
        {question?.text && (
          <strong>
            {question.text}
            <br />
          </strong>
        )}
        {question?.answer}
        <br />
      </span>
    ))}
  </CardContent>
);
