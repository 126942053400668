import { type FC } from 'react';
import styled from 'styled-components';

import { StyledCircle as LoadingCircle, StyledSvg } from '../Loading';

import purpleOtter from '../../assets/purpleOtter.svg';

const Container = styled.div<{ $width: string }>`
  position: relative;
  max-width: calc(12 * ${({ theme }) => theme.deprecated_.sizeBasis});
  width: ${({ $width }) => $width};
`;

const StyledImage = styled.img`
  position: absolute;
  width: 88%;
  top: 0;
  left: 0;
  margin: 6%;
`;

const StyledCircle = styled(LoadingCircle)`
  stroke-width: 6px;
`;

export type StyledLogoLoadingProps = {
  width?: string;
};

export const LogoLoading: FC<StyledLogoLoadingProps> = ({ width = '100%' }) => (
  <Container $width={width}>
    <StyledSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <StyledCircle cx="50" cy="50" r="47" />
    </StyledSvg>
    <StyledImage src={purpleOtter} alt="purple otter" />
  </Container>
);
