import { type Client, type Message } from '@twilio/conversations';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useViewer } from '$shared/contexts/Viewer';

import { useLazyAuthorName } from '../../../../pages/Messages/Conversation/components/Message/hooks';
import { routes } from '../../../../routes';

import { useNotification } from './useNotification';

type UseMessagesNotification = (client: Client | null) => void;

type OnMessageAdded = (message: Message) => void;

export const useMessagesNotification: UseMessagesNotification = (client) => {
  const { viewer } = useViewer();
  const { pathname } = useLocation();

  const [getAuthorName] = useLazyAuthorName();
  const showNotification = useNotification();

  // When a message is added to a conversation, send a notification
  useEffect(() => {
    // Notifications not supported on safari iOS
    if (typeof Notification !== 'function') return;
    // Need to make sure we have permission to send the notification
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }

    const onMessageAdded: OnMessageAdded = async ({ author, body, conversation }) => {
      // Ensure that the author and message body have been set
      if (!author || !body) return;

      // Don't notify outgoing messages or when currently on the conversation page itself
      if (author === viewer?.id || pathname === routes.messages.conversation.url({ id: conversation.sid })) return;

      const authorName = await getAuthorName({
        authorId: author,
        conversationSid: conversation.sid,
      });
      showNotification({
        authorName,
        conversationSid: conversation.sid,
        messageBody: body,
      });
    };

    client?.on('messageAdded', onMessageAdded);
    return () => {
      client?.off('messageAdded', onMessageAdded);
    };
  }, [client, getAuthorName, pathname, showNotification, viewer?.id]);
};
