import { gql } from '@apollo/client';

import { PROVIDER_FRAGMENT } from './provider';
import { USER_FRAGMENT } from './user';

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($profile: UpdateProfileMutationInput!) {
    updateProfile(input: $profile) {
      __typename
      ... on UserType {
        id
        profilePicture
      }
      ... on UpdateProfileError {
        message
        fieldErrors {
          profilePicture
        }
      }
      ... on RateLimitError {
        message
      }
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordMutationInput!) {
    updatePassword(input: $input) {
      ... on UserType {
        id
      }
      ... on UpdatePasswordError {
        message
        fieldErrors {
          oldPassword
          newPassword1
          newPassword2
        }
      }
    }
  }
`;

export const UPDATE_CHILD_PROFILE_MUTATION = gql`
  mutation UpdateChildProfile($input: UpdateChildProfileMutationInput!) {
    updateChildProfile(input: $input) {
      __typename
      ... on ChildType {
        id
        firstName
        lastName
        preferredName
        dateOfBirth
        gender
        pronouns
        profilePicture
      }
      ... on UpdateChildProfileError {
        message
        fieldErrors {
          firstName
          lastName
          dateOfBirth
          gender
          profilePicture
          pronouns
          preferredName
        }
      }
      ... on RateLimitError {
        message
      }
    }
  }
`;

export const GET_CARE_TEAM = gql`
  query GetCareTeam {
    viewer {
      id
      careTeam {
        providers {
          ...ProviderFragment
        }
      }
    }
  }

  ${PROVIDER_FRAGMENT}
`;

export const PROFILE_FAMILY_QUERY = gql`
  query ProfileFamilyQuery {
    viewer {
      id
      firstName
      lastName
      profilePicture
      family {
        id
        caregivers {
          id
          firstName
          lastName
          profilePicture
        }
        children {
          id
          firstName
          lastName
          preferredName
          displayName
          profilePicture
        }
      }
    }
  }
`;

export const PROFILE_VIEWER_QUERY = gql`
  query ProfileViewerQuery {
    viewer {
      id
      family {
        id
        caregivers {
          id
          profilePicture
        }
        children {
          id
          providers {
            id
            profilePicture
          }
        }
      }
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_CHILD_QUERY = gql`
  query ProfileChildQuery($id: Int!) {
    child(id: $id) {
      id
      firstName
      lastName
      preferredName
      displayName
      dateOfBirth
      gender
      pronouns
      profilePicture
      worryDomains {
        edges {
          node {
            id
            name
            shortName
          }
        }
      }
      insuranceAuthorizations {
        id
        createdDatetime
        insuranceReferral {
          typeOfService
          insurancePartner {
            id
            name
            codeLabel
            coverageInfo
          }
          referralNumber
          startDate
          endDate
        }
      }
    }
  }
`;

export const ADD_NEW_CAREGIVER_MUTATION = gql`
  mutation AddNewCaregiverMutation(
    $firstName: String!
    $lastName: String!
    $state: String!
    $email: String!
    $lifecycleStage: String!
  ) {
    inviteCaregiver(
      firstName: $firstName
      lastName: $lastName
      state: $state
      email: $email
      lifecycleStage: $lifecycleStage
    ) {
      __typename
      ... on UserType {
        id
      }
      ... on InviteCaregiverError {
        message
        fieldErrors {
          firstName
          lastName
          email
          state
        }
      }
    }
  }
`;

export const PROVIDER_BIO_QUERY = gql`
  query ProviderBioQuery($id: Int!) {
    viewer {
      id
      firstName
      lastName
      email
      phoneNumber
    }
    user(id: $id) {
      id
      firstName
      lastName
      providerTitle
      profilePicture
      externalProviderId
      bio
    }
  }
`;
