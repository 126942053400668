import { type FC, type PropsWithChildren, useEffect, useState } from 'react';

import { type Segment } from '@littleotter/legacy-components/src/components/SegmentedProgressBar/types';

import { type DomainScore, type Maybe } from '../../../../../graphql/lo1/generated';
import {
  StyledCollapse,
  StyledScoreConcerning,
  StyledScoreMissing,
  StyledScoreTypical,
  StyledSegmentedProgressBar,
  StyledSubtitle,
  StyledTitle,
} from '../CollapseDomain/styles';

import { type ImpactCollapseDomainProps, ScoreLabel } from './types';

export type stringScore = {
  value: string;
  scoreType: string;
};

const stringifyScore = (domainScore: DomainScore, childName: string, caregiverName: string) => {
  if (domainScore?.rawScore !== null && domainScore?.rawScore !== undefined) {
    const valueString = `${domainScore.rawScore} out of ${domainScore.maxScore} (threshold ${domainScore.threshold})`;
    const scoreType =
      domainScore.rawScore < domainScore.threshold ? (
        <StyledScoreTypical> Typical </StyledScoreTypical>
      ) : (
        <StyledScoreConcerning> Concerning </StyledScoreConcerning>
      );
    return (
      <StyledSubtitle>
        {stringifyScoreLabel(domainScore.label, childName, caregiverName)} • {valueString} • {scoreType}
      </StyledSubtitle>
    );
  }

  const missingScoreType = <StyledScoreMissing> Missing </StyledScoreMissing>;

  return (
    <StyledSubtitle>
      ?? out of {domainScore?.maxScore} (threshold {domainScore?.threshold}) • {missingScoreType}
    </StyledSubtitle>
  );
};

const stringifyScoreLabel = (label: string, childName: string, caregiverName: string) => {
  switch (label) {
    case ScoreLabel.Caregiver:
      return `${caregiverName} (reporter)`;
    case ScoreLabel.Child:
      return childName;
    default:
      return label;
  }
};

const getProgressPercentage = (domainScore: Maybe<DomainScore> | undefined) => {
  if (domainScore?.rawScore !== null && domainScore?.rawScore !== undefined) {
    if (domainScore.rawScore === 0) {
      return 1;
    }

    // if score is equal to threshold, add 1 to display bump to show domain is concerning
    let normalizedScore = (domainScore.rawScore / domainScore.maxScore) * 100;
    if (domainScore.rawScore === domainScore.threshold) {
      normalizedScore += 1;
    }

    return normalizedScore;
  }

  return 0;
};

const getSegments = (domainScore: Maybe<DomainScore> | undefined) => {
  const segments: Segment[] = [{ threshold: 100, color: 'red' }];
  if (domainScore?.threshold) {
    segments.unshift({ threshold: (domainScore.threshold / domainScore.maxScore) * 100, color: 'green' });
  }

  return segments;
};

export const ImpactCollapseDomain: FC<PropsWithChildren<ImpactCollapseDomainProps>> = ({
  title,
  children,
  subDomainScores,
  childName,
  caregiverName,
  openCollapse,
}) => {
  const [open, setOpen] = useState(openCollapse);
  useEffect(() => setOpen(openCollapse), [openCollapse]);
  const onClick = () => setOpen(!open);

  const COLLAPSE_MAX_HEIGHT = '10000px';

  const headerTitle = (
    <>
      <StyledTitle>{title}</StyledTitle>
      {subDomainScores.map((score) => {
        return (
          <div key={score.label}>
            {stringifyScore(score, childName, caregiverName)}
            <StyledSegmentedProgressBar
              size="small"
              progress={getProgressPercentage(score)}
              segments={getSegments(score)}
            />
          </div>
        );
      })}
    </>
  );

  return (
    <StyledCollapse
      label={headerTitle}
      maxHeight={COLLAPSE_MAX_HEIGHT}
      containerVariant="card"
      iconVariant="plus-minus"
      isOpen={open}
      onClick={onClick}
    >
      {children}
    </StyledCollapse>
  );
};
