import { type ApolloError } from '@apollo/client';

import { type GraphQLError, handledKey } from '../../utils/errorHandlers';

import { MultipleApolloError } from './MultipleApolloError';

type UseGraphQLErrorHandlingResult = {
  hasGraphQLErrors: boolean;
  allErrorsHandled: boolean | undefined;
};

type UseGraphQLErrorHandling = (...errors: (ApolloError | undefined)[]) => UseGraphQLErrorHandlingResult;

/**
 * React hook that receives one or more Apollo error objects (that may be undefined).
 * If one of the following is true:
 * - There are client errors
 * - There are network errors
 * - There are **unhandled** GraphQL errors
 * it throws the ApolloError error in case there's only one, or a MultipleApolloError in case there's multiple,
 * so that our AppErrorBoundary component can catch it, handle it (e.g. log it to datadog),
 * and show the proper error screen.
 *
 * It also returns two boolean values:
 * - `hasGraphQLErrors`: a boolean indicating if there are GraphQL errors in the Apollo response.
 * - `allErrorsHandled`: undefined if there are no GraphQL errors, a boolean if there are errors,
 *   indicating if all GraphQL errors have been handled (e.g. by Apollo link error handlers).
 */
export const useGraphQLErrorHandling: UseGraphQLErrorHandling = (...errors) => {
  const hasClientErrors = errors.some((error) => !!error && error.clientErrors.length > 0);
  const hasNetworkError = errors.some((error) => !!error?.networkError);

  const hasGraphQLErrors = errors.some((error) => !!error && error.graphQLErrors.length > 0);
  const allErrorsHandled = hasGraphQLErrors
    ? errors.every(
        (error) =>
          !!error && error.graphQLErrors.every((gqlError) => (gqlError as GraphQLError).extensions?.[handledKey])
      )
    : undefined;

  // If (at least one of) the passed Apollo error(s) correspond to client errors or a network error
  // or unhandled GraphQL errors, throw an exception so our AppErrorBoundary can catch it
  if (hasClientErrors || hasNetworkError || (hasGraphQLErrors && !allErrorsHandled)) {
    const actualErrors = errors.filter((error): error is ApolloError => !!error);

    if (actualErrors.length === 1) {
      throw actualErrors[0];
    }

    throw new MultipleApolloError(actualErrors);
  }

  return {
    hasGraphQLErrors,
    allErrorsHandled,
  };
};
