import Cookie from 'js-cookie';

import { useViewer } from '$shared/contexts/Viewer';

import { type ViewerQuery_viewer } from 'src/graphql/__generated__/ViewerQuery';

import { BaseCta, type BaseCtaProps, BaseCtaSkeleton } from './BaseCta';

export const SmartContinueOnboardingCta = () => {
  const { viewer, loading } = useViewer();
  const skippedInsurance = Cookie.get('skipped_insurance');
  const bookedKickoff = Cookie.get('booked_kickoff');

  const presenter = present(viewer, skippedInsurance, bookedKickoff);

  if (loading) {
    return <BaseCtaSkeleton />;
  }
  return presenter ? <BaseCta {...presenter} /> : null;
};

const CHECK_AVAILABILITY_CTA_PROPS: BaseCtaProps = {
  vm: {
    title: 'Next Steps',
    steps: ['Check availability for care', 'Take the Family Mental Health Checkup assessment'],
    buttonText: 'Check availability',
  },
  actions: {
    onClick: () => {
      window.location.href = `${process.env.NEXT_GEN_PROXY_URL}/onboarding/state`;
    },
  },
};

const ADD_CHILD_CTA_PROPS: BaseCtaProps = {
  vm: {
    title: 'Next Steps',
    steps: ['Complete new family intake forms', 'Take the Family Mental Health Checkup', 'Get matched with a provider'],
    buttonText: 'Book a session',
  },
  actions: {
    onClick: () => {
      window.location.href = `${process.env.NEXT_GEN_PROXY_URL}/onboarding/child`;
    },
  },
};

const PHONE_NUMBER_CTA_PROPS: BaseCtaProps = {
  vm: {
    title: 'Next Steps',
    steps: [
      'Complete profile and schedule session',
      'Take the Family Mental Health Checkup',
      'Get matched with a provider',
    ],
    buttonText: 'Book a session',
  },
  actions: {
    onClick: () => {
      window.location.href = `${process.env.NEXT_GEN_PROXY_URL}/onboarding/phone-number`;
    },
  },
};

const WELCOME_CALL_CTA_PROPS: BaseCtaProps = {
  vm: {
    title: 'Next Steps',
    steps: ['Schedule a session', 'Take the Family Mental Health Checkup', 'Get matched with a provider'],
    buttonText: 'Book a session',
  },
  actions: {
    onClick: () => {
      window.location.href = `${process.env.NEXT_GEN_PROXY_URL}/onboarding/schedule`;
    },
  },
};

/**
 * If no viewer state, use check availability CTA
 * If no insurance, use insurance CTA
 * If no address, use caregiver info CTA
 * If no welcome call, use welcome call CTA
 */
const present = (
  viewer: ViewerQuery_viewer | null,
  skippedInsurance?: string,
  bookedKickoff?: string
): BaseCtaProps | null => {
  // Early return: loading state
  if (!viewer) {
    return null;
  }
  // Early return: already done with onboarding!
  if (viewer.hasConfirmedAppointments || viewer.hasCompletedAppointments) {
    return null;
  }
  if (viewer.asCaregiver?.address?.state === null) {
    return CHECK_AVAILABILITY_CTA_PROPS;
  }
  // Early return: not in allowed state
  if (!viewer.asCaregiver?.address?.state.allowed) {
    return null;
  }

  // First we need to check if user didn't add any children so we send them to the new onboarding child form
  if (viewer.family?.children.length === 0) {
    return ADD_CHILD_CTA_PROPS;
  }
  /*
   * If the user has children, no "validInsuranceAuthorizations" and didn't skip insurance while onboarding we also
   * send them to the child form page (because it already has logic for scoping the onboarding to the last created child
   * and continuing with the flow for that child)
   *
   * family.hasValidInsuranceAuthorizations (poorly named) property takes into account if the family or any
   * children in the family are covered or have submitted partner invite codes or insurance auth codes/subscribers.
   * This way we are making sure this family has never added any partner/insurance related stuff before we send them
   * back to the add child form in onboarding.
   */
  if (!viewer.family?.hasValidInsuranceAuthorizations && !skippedInsurance) {
    return ADD_CHILD_CTA_PROPS;
  }

  if (!viewer.phoneNumber) {
    return PHONE_NUMBER_CTA_PROPS;
  }
  if (!bookedKickoff) {
    return WELCOME_CALL_CTA_PROPS;
  }

  return null;
};
