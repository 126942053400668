import { format } from 'date-fns';
import { type FC, type PropsWithChildren, useRef, useState } from 'react';

import { LogoLoading } from '@littleotter/legacy-components';

import {
  type AssessmentSubmission,
  type DomainReport,
  type DomainReportContext,
  DomainReportContextType,
  type Subject,
  SubjectType,
} from '../../../graphql/lo1/generated';

import { CollapseDomain } from './components/CollapseDomain/CollapseDomain';
import { ContextContent } from './components/ContextContent/ContextContent';
import { ImpactCollapseDomain } from './components/ImpactCollapseDomain/ImpactCollapseDomain';
import {
  ReportContainer,
  ReportContentTitle,
  StyledAnswer,
  StyledContextContent,
  StyledList,
  StyledLoadingContainer,
  SubmissionContainer,
  SubmissionWrapper,
} from './styled';
import { SubmissionHeader } from './SubmissionHeader';
import { type Caregiver, type FamilyData, type FmhcReport, type WorryDomainConnection } from './types';

export type Member = {
  name: string;
  isSeekingCare?: boolean;
  isCaregiver?: boolean;
  isFamily?: boolean;
  age?: number;
  pronouns?: string;
  gender?: string;
  dateOfBirth?: string;
};

export type Score = {
  label?: string;
  rawScore: number | null;
  threshold?: number;
  maxScore?: number;
};

export type SubmissionContentProps = {
  fmhcReport: FmhcReport;
  familyData?: FamilyData;
  id: string;
  member: Member;
};

const renderQuestionAndAnswers = (domainReport: DomainReport, submissionData: AssessmentSubmission | undefined) => {
  const questionSet = submissionData?.domainQuestionSets.find((set) => {
    return set.domainName === domainReport.domainName;
  });

  if (!questionSet) return;

  const questionsList = questionSet.questionAnswers.map((questionAnswer) => {
    // The domainQuestionSet from submission data should only include singleSelectOptions
    const questionText =
      questionAnswer.question.questionConfig.singleSelectOptions?.questionText ?? 'Question text missing';

    const answerText = (
      <StyledAnswer>
        {questionAnswer.answer
          ? `• ${questionAnswer.answer?.choice.choice} (${questionAnswer.answer?.choice.value})`
          : '• Skipped Question'}
      </StyledAnswer>
    );

    return (
      <li key={questionAnswer.question.variableName}>
        {questionText} {answerText}
      </li>
    );
  });

  return (
    <>
      <ReportContentTitle>Questions</ReportContentTitle>
      <StyledList>{questionsList}</StyledList>
    </>
  );
};

const renderContextContent = (contexts: DomainReportContext[]) => {
  const explanation = contexts.find((context) => context.contextType === DomainReportContextType.Explanation);
  const cta = contexts.find((context) => context.contextType === DomainReportContextType.CallToAction);

  return (
    <StyledContextContent>
      <ReportContentTitle>Family-Facing Information</ReportContentTitle>
      {explanation && <ContextContent {...explanation} />} {cta && <ContextContent {...cta} />}
    </StyledContextContent>
  );
};

/**
 * There is a bug with creating Date objs when using the input format 'yyyy-mm-dd'
 * Given that format, it does weird things with time zone, resulting in the date being
 * 1 day prior to the actual given date.  This can be fixed by providing it with a default
 * timezone.
 *
 * @example
 * new Date("05-12-1992")
 * Tue May 12 1992 00:00:00 GMT-0700 (Pacific Daylight Time)
 *
 * new Date("1992-05-12")
 * Mon May 11 1992 17:00:00 GMT-0700 (Pacific Daylight Time)
 *
 * new Date("1992-05-12T00:00:00")
 * Tue May 12 1992 00:00:00 GMT-0700 (Pacific Daylight Time)
 */
const formatDate = (dateOfBirth: string | undefined) => {
  return dateOfBirth ? `(${format(new Date(`${dateOfBirth}T00:00:00`), 'MM-dd-yyyy')})` : '';
};

export const stringifySubject = (subject: Subject) =>
  subject.subjectType === SubjectType.Child ? `Child mental health` : `${subject.subjectType} mental health`;

export const SubmissionContent: FC<PropsWithChildren<SubmissionContentProps>> = ({
  familyData,
  fmhcReport,
  member,
  id,
}) => {
  const cardRef = useRef<HTMLElement>(null);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [processingCopy, setProcessingCopy] = useState(false);

  const getFamilyMembersName = () => {
    if (!familyData) return '';

    const caregiversNames = familyData.family.caregivers.reduce((prev: string[], curr) => {
      return [...prev, curr.firstName];
    }, []);

    const childrenNames = familyData.family.children.reduce((prev: string[], curr) => {
      return [...prev, `${curr.firstName} ${curr.lastName}`];
    }, []);

    return [...caregiversNames, ...childrenNames].join(', ');
  };

  const getParsedAge = () => {
    if (!member.age) return;
    const formattedDate = formatDate(member.dateOfBirth);
    return member.age >= 24 ? `${Math.floor(member.age / 12)}yo ${formattedDate}` : `${member.age}mo ${formattedDate}`;
  };

  const reduceWorryDomains = (worryDomains: WorryDomainConnection | undefined) => {
    if (!worryDomains) return '';
    return (
      worryDomains.edges.reduce((prev, curr) => {
        const currentName: string = curr?.node?.name ?? '';
        return prev ? `${prev}, ${currentName}` : currentName;
      }, '') ?? ''
    );
  };

  const stringifyWorries = (subject: Subject) => {
    if (subject.subjectType === SubjectType.Family) {
      return reduceWorryDomains(familyData?.family.worryDomains);
    }

    if (subject.subjectType === SubjectType.Child) {
      const child = familyData?.family.children.find((_child) => _child.id === id);
      return reduceWorryDomains(child?.worryDomains);
    }

    if (subject.subjectType === SubjectType.Caregiver) {
      const caregiver = familyData?.family.caregivers.find((_caregiver) => _caregiver.id === id);
      return reduceWorryDomains(caregiver?.worryDomains);
    }

    return '';
  };

  const getSubjectNameString = () => {
    const name = member.isFamily ? getFamilyMembersName() : member.name;
    return member.pronouns ? `${name} (${member.pronouns})` : name;
  };

  const copySubmissionContent = async () => {
    setProcessingCopy(true);
    copySubmissionData();
  };

  const copySubmissionDetailedContent = async () => {
    setProcessingCopy(true);
    setOpenCollapse(true);
    // wait until collapse animation ends
    setTimeout(() => {
      copySubmissionData();
    }, 500);
  };

  const copySubmissionData = async () => {
    if (!cardRef.current) return;
    // We copy the formatted info from the submission card
    const range = document.createRange();
    range.selectNode(cardRef.current);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand('copy');
    const clipboardText = await navigator.clipboard.readText();
    // Get rid of unecessary data
    navigator.clipboard.writeText(
      clipboardText
        .replace(/[•\t.+]/g, '')
        .replace('Copy Info', '')
        .replace('Copy Detailed Info', '')
        .replace(/^\s*\n/gm, '')
    );
    window.getSelection()?.removeAllRanges();
    setOpenCollapse(false);
    // wait until collapse animation ends
    setTimeout(() => {
      setProcessingCopy(false);
    }, 500);
  };

  return (
    <SubmissionWrapper>
      {processingCopy && (
        <StyledLoadingContainer>
          <LogoLoading />
        </StyledLoadingContainer>
      )}
      <SubmissionContainer id={id} ref={cardRef}>
        <SubmissionHeader
          title={stringifySubject(fmhcReport.subject)}
          worries={stringifyWorries(fmhcReport.subject)}
          age={getParsedAge()}
          subject={getSubjectNameString()}
          seekingCare={member.isSeekingCare}
          isChild={!member.isFamily && !member.isCaregiver}
          handleCopy={copySubmissionContent}
          handleDetailedCopy={copySubmissionDetailedContent}
        />
        {fmhcReport.gqlFmhcReport.domainReports.map((domainReport) => {
          if (domainReport.domainName === 'Impact') {
            return (
              <ImpactCollapseDomain
                key={domainReport.domainName}
                title={domainReport.domainName}
                subDomainScores={domainReport.subDomainScores}
                childName={member.name}
                caregiverName={getReporter(
                  fmhcReport.submissionMetadata.submitterId,
                  familyData?.family.caregivers ?? []
                )}
                openCollapse={openCollapse}
              >
                <ReportContainer key={domainReport.domainName}>
                  {renderQuestionAndAnswers(domainReport, fmhcReport.submissionData)}
                  {/* Context is conditionally rendered because we don't need that info when copying the content */}
                  {!processingCopy && renderContextContent(domainReport.context)}
                </ReportContainer>
              </ImpactCollapseDomain>
            );
          }
          return (
            <CollapseDomain
              key={domainReport.domainName}
              title={domainReport.domainName}
              domainScore={domainReport.domainScore}
              openCollapse={openCollapse}
            >
              <ReportContainer key={domainReport.domainName}>
                {renderQuestionAndAnswers(domainReport, fmhcReport.submissionData)}
                {/* Context is conditionally rendered because we don't need that info when copying the content */}
                {!processingCopy && renderContextContent(domainReport.context)}
              </ReportContainer>
            </CollapseDomain>
          );
        })}
      </SubmissionContainer>
    </SubmissionWrapper>
  );
};

const getReporter = (submitterId: string, caregivers: Caregiver[]) => {
  return caregivers.filter((caregiver) => caregiver.id === submitterId)[0].firstName ?? submitterId;
};
