import { boolEnv, resolveEnvVar } from '@littleotter/kit/env';

import { type ILoggerService } from '../../../logging/types';
import { type EventPropsShape, type IEventTrackingProvider } from '../../types';

import { FACEBOOK_BROWSER_ID_COOKIE_NAME, FACEBOOK_CLICK_ID_COOKIE_NAME } from './__internal__/constants';
import { initRudderstackSdk } from './__internal__/initRudderstackSdk';
import type { RudderEventProps, RudderstackMethods } from './types';

/**
 * The RudderstackProvider is a wrapper around the Rudderstack SDK. It provides a common Provider interface
 * for the `PlatformEventTrackingService` to use and handles translating common event tracking methods and properties into the
 * appropriate Rudderstack SDK calls.
 *
 * This separation of concerns allows us to change underlying event tracking providers without having to change any of the
 * code that uses the Event Tracking service. It also allows us to make internal API changes to the Rudderstack provider
 * without having to touch consumer code.
 *
 * ? Why aren't we using `rudder-sdk-js` directly here? Rudderstack recommends using the script tag to load the SDK, so we're
 * ? following their recommendation. They recommend against using the npm package if at all possible. The only reason
 * ? we have it installed is to get the Rudderstack event types.
 *
 * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/
 */
export class RudderstackProvider implements IEventTrackingProvider {
  name = 'rudderstack';

  private logger: ILoggerService;

  private writeKey?: string;

  private dataplaneUrl?: string;

  private sdkUrl?: string;

  private facebookFbcCookieValue?: string;

  private facebookFbpCookieValue?: string;

  /**
   * Whether or not to send the adblock page event to Rudderstack
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/detecting-adblocked-pages/
   */
  private sendAdblockPage?: boolean;

  constructor(logger: ILoggerService) {
    this.logger = logger;

    this.dataplaneUrl = resolveEnvVar(
      process.env.RUDDERSTACK_DATAPLANE_URL || process.env.NEXT_PUBLIC_RUDDERSTACK_DATAPLANE_URL
    );

    this.writeKey = resolveEnvVar(process.env.RUDDERSTACK_WRITE_KEY || process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY);

    this.sdkUrl = resolveEnvVar(process.env.RUDDERSTACK_SDK_URL || process.env.NEXT_PUBLIC_RUDDERSTACK_SDK_URL);

    this.sendAdblockPage = boolEnv(
      resolveEnvVar(
        process.env.RUDDERSTACK_SEND_ADBLOCK_PAGE || process.env.NEXT_PUBLIC_RUDDERSTACK_SEND_ADBLOCK_PAGE,
        '0'
      )
    );

    // When the Rudderstack Provider initializes, we want to read the Facebook/Meta cookies once and store them
    this.facebookFbcCookieValue = RudderstackProvider.getCookieValue(FACEBOOK_CLICK_ID_COOKIE_NAME);
    this.facebookFbpCookieValue = RudderstackProvider.getCookieValue(FACEBOOK_BROWSER_ID_COOKIE_NAME);
  }

  /**
   * Load the Rudderstack SDK by loading the initialization code as defined in their documentation.
   * This method should be called before any other event tracking methods are called.
   *
   * @see https://www.rudderstack.com/docs/user-guides/how-to-guides/rudderstack-jamstack-integration/rudderstack-nextjs-integration
   *
   * ! The Rudderstack SDK automatically calls a `page` method internally on initialization. This means
   * ! we don't need to call `page` manually on first page load.
   */
  init() {
    if (!this.hasValidEnvConfig()) {
      return;
    }
    try {
      initRudderstackSdk(this.logger, this.dataplaneUrl, this.writeKey, this.sdkUrl, this.sendAdblockPage);
      this.logger.debug(`[platform.event-tracking.rudderstack] Attempting to initialize Rudderstack SDK`);
    } catch (error) {
      this.logger.error(
        new Error(`[platform.event-tracking.rudderstack] Error initializing Rudderstack SDK: ${this.name}`, {
          cause: error,
        })
      );
    }
  }

  /**
   * The JavaScript SDK provides the ready API with a callback parameter that triggers when the SDK is done
   * initializing itself and the other third-party native SDK destinations.
   *
   * Note that a callback can not be registered until the SDK mounts itself to the window, so we still do a check
   * before dispatching the ready event + callback.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/quick-start-guide/#step-2-check-ready-state
   */
  ready(callback: () => void) {
    this.safelyDispatch('ready', () => window.rudderanalytics?.ready(callback));
  }

  /**
   * The identify method lets you identify a user and associate them to their actions. It also lets you record any traits
   * about them like their name, email, etc.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#identify
   *
   * @param userId The unique user identifier in the database. When provided, the SDK sends this argument to the destinations instead of anonymousId.
   * @param traits Contains the user’s traits or the properties associated with userId such as email, address, etc.
   * @param callback Called after the successful execution of the identify method.
   */
  identify(userId: string, traits?: EventPropsShape, callback?: () => void) {
    this.safelyDispatch(
      'identify',
      () => window.rudderanalytics?.identify(userId, traits as RudderEventProps, {}, callback)
    );
  }

  /**
   * The `clearIdentity` method (rudderstack calls this `reset`) clears the ID and traits of both the user and the group
   * by clearing Rudderstack cookie and localStorage data.
   *
   * ! Note: We are intentionally force-clearing the anonymousId when we run this method by passing `true` to underlying
   * ! `reset` method. We want all concept of identity on the user's current device destroyed to prevent any
   * ! cross-contamination of data between users.
   *
   * ! Note: `reset` does not clear the information stored by the integrated destinations.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#reset
   */
  clearIdentity() {
    this.safelyDispatch('reset', () => window.rudderanalytics?.reset(true));
  }

  /**
   * The group call lets you associate an identified user with a group such as a company, organization, or an account.
   * Note: An "account" can also be something like a "subscription group" in downstream apps/services.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#group
   *
   * @param groupId The unique group identifier. RudderStack calls the relevant destination APIs to associate the identified user to this group.
   * @param traits The group-related traits. RudderStack passes these traits to the destination to enhance the group properties.
   * @param callback Called after the successful execution of the identify method.
   */
  group(groupId: string, traits: EventPropsShape, callback?: () => void) {
    this.safelyDispatch(
      'group',
      () => window.rudderanalytics?.group(groupId, traits as RudderEventProps, {}, callback)
    );
  }

  /**
   * The alias call lets you merge different identities of a known user.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#identify
   *
   * @param userId New user identifier.
   * @param traits Old user identifier which will be an alias for the to parameter. If not provided, the SDK populates this as the currently identified userId, or anonymousId in case of anonymous users.
   * @param callback Called after the successful execution of the identify method.
   */
  alias(to: string, from?: string, callback?: () => void) {
    this.safelyDispatch('alias', () => window.rudderanalytics?.alias(to, from, {}, callback));
  }

  /**
   * The track call lets you capture user events along with the associated properties.

   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#track
   *
   * Note that Rudderstack supports sending `traits` to each track call as a property on a separate object. However to make life
   * easier to our consumers, we are going to allow them to pass `traits` as a top-level property on the `properties`
   * object. We will then extract it and pass it to the Rudderstack SDK as the `options` argument.
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#use-case-1
   *
   * @param event The name of the tracked event.
   * @param properties The event-related properties.
   * @param callback Called after the successful execution of the identify method.
   */
  track(event: string, properties?: EventPropsShape, callback?: () => void) {
    // ! HACK: We have to do some work to get the Facebook/Meta cookie data into the properties object
    const facebookConversionProperties = this.getFacebookConversionProperties();

    const _properties = {
      ...properties,
      ...facebookConversionProperties,
    };

    this.safelyDispatch(
      'track',
      () => window.rudderanalytics?.track(event, _properties as RudderEventProps, {}, callback)
    );
  }

  /**
   * The page call lets you record page views on a website, along with optional extra information about the page being viewed.
   *
   * We made the decision in our application for the time being to not track the unique name of each page because it
   * adds a manual step for us to have to add a unique name to every page. By default, Rudderstack will use the page's
   * URL and meta Title, which is good enough for our purposes.
   *
   * @see https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#page
   *
   * @param category Category of the page.
   * @param pageName Name of the page.
   * @param properties Properties of the page auto-captured by the SDK.
   * @param callback Called after the successful execution of the identify method.
   */
  page(properties?: EventPropsShape, callback?: () => void) {
    this.safelyDispatch('page', () => window.rudderanalytics?.page(properties as RudderEventProps, {}, callback));
  }

  /**
   * Checks to make sure that there is a window.rudderanalytics object (meaning the Rudderstack SDK has been loaded on the page)
   */
  private isInitializedOnWindow() {
    if (window?.rudderanalytics) {
      return true;
    }
    this.logger.error('[platform.event-tracking.rudderstack] Rudderstack not initialized on window');
    return false;
  }

  /**
   * Checks to make sure that the Rudderstack env vars are configured correctly
   */
  private hasValidEnvConfig() {
    const isValid = this.writeKey && this.dataplaneUrl && this.sdkUrl;

    if (!isValid) {
      this.logger.error('[platform.event-tracking.rudderstack] Rudderstack env vars not configured correctly', {
        writeKey: this.writeKey,
        dataplaneUrl: this.dataplaneUrl,
        sdkUrl: this.sdkUrl,
      });
      return false;
    }

    return true;
  }

  /**
   * Safely attempts to dispatch an event to Rudderstack, logging any errors that occur
   *
   * Scenarios where an event might not be able to be dispatched:
   * - Window is not initialized (it was called on the server -- which likely was an accident)
   * - Rudderstack is not initialized on window (sdk was not loaded before event was dispatched)
   * - Rudderstack env vars are not configured correctly (writeKey, dataplaneUrl, sdkUrl)
   * - Event dispatch failed for an unknown reason
   */
  private safelyDispatch(eventName: RudderstackMethods, eventFn: () => void) {
    if (!this.isInitializedOnWindow() || !this.hasValidEnvConfig()) {
      this.logger.error(
        new Error(`[platform.event-tracking.rudderstack] Failed to dispatch Rudderstack event: ${eventName}`, {
          cause: {
            isInitializedOnWindow: this.isInitializedOnWindow(),
            hasValidEnvConfig: this.hasValidEnvConfig(),
          },
        })
      );
      return;
    }
    try {
      eventFn();
    } catch (e) {
      this.logger.error(
        new Error(`[platform.event-tracking.rudderstack] Error dispatching Rudderstack event: ${eventName}`, {
          cause: e,
        })
      );
    }
  }

  /**
   * Reads some facebook/meta related cookies and extract the values into an object that can be spread onto the event properties object
   * ! NOTE: At present, we only want to do this on TRACK events. We intentionally limit events being sent to Meta.
   *
   * Ohhhhh Facebook/Meta. We have to do some weird/nasty stuff to get the Click ID and Browser ID into our events and improve
   * ad match performance
   *
   * We are intentionally NOT using the Pixel in Device Mode to track events. We are using the Conversion API
   * so we can process events in the cloud and have greater control over PII.
   *
   * As a result, we have to manually forward the fbclid (click id) and fbp (browser id) to Rudderstack events. We opted to use cookies
   * for this because it allows us to avoid forwarding URL Params everywhere.
   *
   * NOTE: These cookies are set in our Squarespace app on meta landing pages.
   * NOTE:  Note that we will have to manually map fbc and fbp to event.context for the meta destinations in Rudderstack. it doesn't infer them from properties
   *
   * @see https://developers.facebook.com/docs/marketing-api/conversions-api/parameters for info on Conversion API parameter names
   */
  private getFacebookConversionProperties() {
    return {
      fbc: this.facebookFbcCookieValue,
      fbp: this.facebookFbpCookieValue,
    };
  }

  /**
   * Get a cookie value from the document.
   * TODO: Move to a centralized cookie utility in `kit`
   */
  private static getCookieValue(cookieName: string) {
    return document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith(`${cookieName}=`))
      ?.split('=')[1];
  }
}
