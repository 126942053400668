import { type FC, type PropsWithChildren } from 'react';
import { type DefaultTheme } from 'styled-components';

import { Link } from '@littleotter/legacy-components';

import { CardContent, type CardContentProps } from './CardContent';
import { Card } from './styled';
import { type Category, type Risk } from './types';

import anxietySrc from './assets/Anxiety.svg';
import caregiverMentalHealthRiskSrc from './assets/CaregiverMentalHealthRisk.svg';
import childEmotionalAndSocialChallengesSrc from './assets/ChildEmotionalAndSocialChallenges.svg';
import depressionSrc from './assets/Depression.svg';
import familyStressSrc from './assets/FamilyStress.svg';
import schoolFeelingsSrc from './assets/SchoolFeelings.svg';

const getColorFromRisk = (impact: Risk): keyof DefaultTheme['deprecated_']['colors'] =>
  (
    ({
      high: 'lightRed',
      medium: 'yellow',
      low: 'green',
      null: 'lightGray',
    }) as const
  )[impact ?? 'null'];

const getContentPropsFromCategory = (category: Category): Omit<CardContentProps, 'risk'> =>
  ({
    ChildEmotionalAndSocialChallenges: {
      outerTitle: 'Child Emotional & Social Challenges',
      illustrationSrc: childEmotionalAndSocialChallengesSrc,
    },
    CaregiverMentalHealthRisk: {
      outerTitle: 'Caregiver Mental',
      innerTitle: 'Health Risk',
      illustrationSrc: caregiverMentalHealthRiskSrc,
    },
    FamilyStress: {
      innerTitle: 'Family Stress',
      illustrationSrc: familyStressSrc,
    },
    SchoolFeelings: {
      innerTitle: 'School Feelings',
      illustrationSrc: schoolFeelingsSrc,
    },
    Anxiety: {
      innerTitle: 'Anxiety',
      illustrationSrc: anxietySrc,
    },
    Depression: {
      innerTitle: 'Depression',
      illustrationSrc: depressionSrc,
    },
  })[category];

const Container: FC<PropsWithChildren<{ href?: string }>> = ({ href, children }) =>
  href ? (
    <Link isAnchor variant="clearStyles" href={href}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export type ColorCardProps = {
  category: Category;
  risk: Risk;
  href?: string;
};

export const ColorCard: FC<PropsWithChildren<ColorCardProps>> = ({ category, risk, href }) => {
  return (
    <Container href={href}>
      <Card backgroundColor={getColorFromRisk(risk)}>
        <CardContent risk={risk} {...getContentPropsFromCategory(category)} />
      </Card>
    </Container>
  );
};
