import { math } from 'polished';
import { type FC, Fragment, type PropsWithChildren } from 'react';
import { BsBookmarkFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  ActionButton,
  HorizontalScrollingContainer,
  Tag,
  TagsContainer,
  Tooltip,
} from '@littleotter/legacy-components';

import { limitList } from '$shared/utils/lists';
import { getReadTime } from '$shared/utils/text';

import {
  type ResourcesFragment,
  type ResourcesFragment_edges_node_tags_edges_node,
  type ResourcesFragment_edges_node_worryDomains_edges_node,
} from '../../../../graphql/__generated__/ResourcesFragment';
import { getTagList, getTagNames, getTooltipText, isTagListElementArray } from '../../utils/tagList';
import { ResourceIllustration } from '../ResourceIllustration';
import { WorryDomainOrResourceTag } from '../WorryDomainOrResourceTag';

const ResourcesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  margin: ${(props) => math(`-${props.theme.deprecated_.sizeBasis} * 0.75`)};
`;

const Wrapper = styled.div`
  border-radius: ${(props) => math(`${props.theme.deprecated_.border.radius} * 2`)};
  box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 19`)};
  margin: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 0.75`)};
`;

const Resource = styled.div`
  background-color: ${(props) => props.theme.deprecated_.colors.white};
  height: 100%;
  padding: ${(props) => props.theme.deprecated_.sizeBasis};
  border-radius: ${(props) => math(`${props.theme.deprecated_.border.radius} * 2`)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.deprecated_.border.color};
    box-shadow: ${(props) => `0 4px 8px ${props.theme.deprecated_.colors.lightGray}`};
  }
`;

const StyledImage = styled.div`
  height: ${(props) => math(`${props.theme.deprecated_.sizeBasis} * 10.33`)};
  margin: ${(props) => `-${props.theme.deprecated_.sizeBasis}`};
  margin-bottom: ${(props) => props.theme.deprecated_.sizeBasis};
  background-color: ${(props) => props.theme.deprecated_.colors.gray50};
  overflow: hidden;
`;

const StyledTitle = styled.div`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.medium};
  letter-spacing: 0.03em;
  line-height: 150%;
  margin-bottom: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 2`)};
  display: flex;
`;

const StyledText = styled.div`
  color: ${(props) => props.theme.deprecated_.colors.gray};
  font-size: ${(props) => props.theme.deprecated_.fontSizes.smaller};
  margin-bottom: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 2`)};
  line-height: 150%;
`;

const BottomSection = styled.div`
  margin-top: auto;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
  padding-top: ${(props) => math(`${props.theme.deprecated_.sizeBasis} / 4`)};

  & > button {
    padding: 0;
  }
`;

type ResourcesProps = {
  resources: ResourcesFragment['edges'];
  isSaved?: boolean;
  horizontalScrolling?: boolean;
};

const Resources: FC<PropsWithChildren<ResourcesProps>> = ({ resources, isSaved, horizontalScrolling }) => {
  const history = useHistory();

  const goToResource = (name: string, pk: number) => {
    history.push(`/library/${pk}`, { name });
  };

  const WrapperComponent = horizontalScrolling ? HorizontalScrollingContainer : ResourcesRow;

  return (
    <WrapperComponent>
      {resources.map((edge) => {
        if (!edge?.node) {
          return null;
        }

        const { title, content, worryDomains, tags, pk } = edge.node;

        const worryDomainNodes = worryDomains.edges
          .map((worryDomainEdge) => worryDomainEdge?.node)
          .filter(
            (worryDomainNode): worryDomainNode is ResourcesFragment_edges_node_worryDomains_edges_node =>
              !!worryDomainNode
          );

        const tagNodes = tags.edges
          .map((tagEdge) => tagEdge?.node)
          .filter((tagNode): tagNode is ResourcesFragment_edges_node_tags_edges_node => !!tagNode);

        const tagList = getTagList(worryDomainNodes, tagNodes);
        const tagNames = getTagNames(tagList);

        const numberOfWorryDomainsToShow = 3;

        return (
          <Wrapper key={pk}>
            <Resource onClick={() => goToResource(title, pk)}>
              <StyledImage>
                <ResourceIllustration pk={pk} tags={tagNames} />
              </StyledImage>
              <StyledTitle>
                <div>{title}</div>
                {isSaved && (
                  <ActionWrapper>
                    <ActionButton icon={<BsBookmarkFill />} />
                  </ActionWrapper>
                )}
              </StyledTitle>
              <StyledText>{getReadTime(content)} min read</StyledText>
              <BottomSection>
                <TagsContainer>
                  {limitList(tagList, numberOfWorryDomainsToShow).map((listElement) =>
                    isTagListElementArray(listElement) ? (
                      <Fragment key={`+${listElement.length}`}>
                        <Tag data-tip={getTooltipText(listElement)}>{`+${listElement.length}`}</Tag>
                        <Tooltip effect="solid" multiline />
                      </Fragment>
                    ) : (
                      <WorryDomainOrResourceTag
                        key={`${listElement.__typename}:${listElement.id}`}
                        worryDomainOrResource={listElement}
                      />
                    )
                  )}
                </TagsContainer>
              </BottomSection>
            </Resource>
          </Wrapper>
        );
      })}
    </WrapperComponent>
  );
};

export default Resources;
