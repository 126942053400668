import { type FC, type PropsWithChildren } from 'react';

import { type Caregiver, CaregiverLinkRow } from './CaregiverLinkRow';
import { StyledCaregiverLinks } from './styled';

export const CaregiverLinks: FC<PropsWithChildren<{ caregivers: Caregiver[] }>> = ({ caregivers }) => {
  return (
    <StyledCaregiverLinks>
      {caregivers.map((c) => {
        return <CaregiverLinkRow key={c.name} caregiver={c} showName={caregivers.length > 1} />;
      })}
    </StyledCaregiverLinks>
  );
};
