import { type FC, type PropsWithChildren } from 'react';

import { StyledDiv } from './styled';

import desktopBackgroundImageSrc from './assets/DesktopBackground.svg';
import mobileBackgroundImageSrc from './assets/MobileBackground.svg';

export const Background: FC<PropsWithChildren> = () => (
  <StyledDiv mobileBackgroundSrc={mobileBackgroundImageSrc} desktopBackgroundSrc={desktopBackgroundImageSrc} />
);
