import { math } from 'polished';
import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';

import { Header, Link } from '@littleotter/legacy-components';

import { routes } from '../../routes';

import backgroundUrl from './assets/Background.svg';
import { ReactComponent as LostSloth } from './assets/LostSloth.svg';

const Background = createGlobalStyle`
  body {
    @media (max-width: ${({ theme }) => theme.deprecated_.breakpoints.sm}) {
      background-image: url(${backgroundUrl});
      background-repeat: no-repeat;
      background-position: center top;
    }
  }
`;

const StyledLostSloth = styled(LostSloth)`
  display: block;
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 20`)};
  max-height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 16`)};
  width: 100%;
  height: auto;
  margin: 0 auto;
`;

const StyledSection = styled.section`
  padding-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 5`)};
  text-align: center;

  > h1 {
    font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.largest} * 3.5`)};
    line-height: 1;
    margin: ${({ theme }) => `${theme.deprecated_.sizeBasis} 0`};
  }

  > h2 {
    font-size: ${({ theme }) => theme.deprecated_.fontSizes.largest};
    line-height: 1;
    opacity: 0.64;
  }
`;

const StyledLink = styled(Link).attrs({ variant: 'primary' })`
  background: ${({ theme }) => theme.deprecated_.colors.green};
  border-radius: ${({ theme }) => theme.deprecated_.border.radius};
  color: ${({ theme }) => theme.deprecated_.colors.white};
  font-size: ${({ theme }) => math(`${theme.deprecated_.fontSizes.regular} * 1.35`)};
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.bold};
  margin: 0 auto;
  margin-top: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 3.5`)};
  max-width: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 21`)};
  width: 100%;
  height: ${({ theme }) => math(`${theme.deprecated_.sizeBasis} * 4.5`)};
`;

export const PageNotFound: FC<PropsWithChildren> = () => (
  <>
    <Helmet>
      <title>Little Otter | Page Not Found</title>
    </Helmet>
    <Background />
    <StyledSection>
      <StyledLostSloth />
      <Header as="h1">404</Header>
      <Header as="h2">Whoops, you've floated too far off!</Header>
      <StyledLink href={routes.home.url()}>Back to Home</StyledLink>
    </StyledSection>
  </>
);
