import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';

import { Banner } from '@littleotter/legacy-components';

import { useBannerMessage, useGraphQLErrorHandling } from '$shared/hooks';
import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { PageWideLoading } from '../../components/PageWideLoading';
import { RouteSwitch } from '../../components/RouteSwitch';
import { type ProfileViewerQuery } from '../../graphql/__generated__/ProfileViewerQuery';
import { MissingQueryDataError } from '../../graphql/errors';
import { PROFILE_VIEWER_QUERY } from '../../graphql/profile';
import { routes } from '../../routes';

import { CareTeamWithPrerequisites } from './CareTeam';
import { ProviderBioPage } from './CareTeam/ProviderBioPage';
import { ChildProfile } from './ChildProfile';
import { ClerkUserProfile } from './ClerkUserProfile/ClerkUserProfile';
import { FamilyPage } from './FamilyPage';
import { ProfileBasePage } from './ProfileBasePage';

export const Profile: FC<PropsWithChildren> = () => {
  const { data, error, loading } = useQuery<ProfileViewerQuery>(PROFILE_VIEWER_QUERY);
  useGraphQLErrorHandling(error);

  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();

  if (loading) return <PageWideLoading />;

  const viewer = data?.viewer;
  if (!viewer) throw new MissingQueryDataError('ProfileViewerQuery');

  return (
    <>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <RouteSwitch>
        <MetadataRoute {...routeProps(routes.profile.family)}>
          <Page hasHeader headerTitle="Family management" hasBackButton>
            <FamilyPage setBannerMessage={setBannerMessage} />
          </Page>
        </MetadataRoute>
        <MetadataRoute {...routeProps(routes.profile.me)}>
          <Page hasHeader headerTitle="Edit Profile" hasBackButton>
            <ClerkUserProfile />
          </Page>
        </MetadataRoute>
        <MetadataRoute {...routeProps(routes.profile.child)}>
          <Page hasHeader headerTitle="Edit Child" hasBackButton backButtonRouteOverride={routes.profile.family.url()}>
            <ChildProfile setBannerMessage={setBannerMessage} />
          </Page>
        </MetadataRoute>
        <MetadataRoute {...routeProps(routes.profile.care.bio)}>
          <Page hasHeader headerTitle="Provider Bio" hasBackButton>
            <ProviderBioPage />
          </Page>
        </MetadataRoute>
        <MetadataRoute {...routeProps(routes.profile.care.home)}>
          <Page hasHeader headerTitle="Care Team" hasBackButton>
            <CareTeamWithPrerequisites />
          </Page>
        </MetadataRoute>
        <MetadataRoute {...routeProps(routes.profile.home)}>
          <Page hasHeader headerTitle="Account Settings">
            <ProfileBasePage user={viewer} />
          </Page>
        </MetadataRoute>
      </RouteSwitch>
    </>
  );
};
