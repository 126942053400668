import { useMutation } from '@apollo/client';
import { type FC, type PropsWithChildren, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { strToBool } from '@littleotter/kit/utils';
import { Header, HIPAACompliantPopup, Link } from '@littleotter/legacy-components';

import { useChildReferral } from '$shared/contexts/Child';
import { usePartnership } from '$shared/hooks/usePartnership';
import { getLocationDescriptor } from '$shared/utils/rerouter/rerouter';

import { type ChildAuthorizationQuery_insuranceReferral } from 'src/graphql/__generated__/ChildAuthorizationQuery';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import {
  type AddUserChild,
  type AddUserChild_addUserChild,
  type AddUserChildVariables,
} from '../../../../graphql/__generated__/AddUserChild';
import { ADD_USER_CHILD_MUTATION } from '../../../../graphql/user';
import { routes } from '../../../../routes';
import { type ChildProfileFormData, type SaveChildErrors, type SaveChildResult } from '../../types';

import { Form } from './Components/Form';
import { Description, DescriptionContainer, HeaderContainer } from './styled';

const formatGqlErrorMessages: (addUserChild?: AddUserChild_addUserChild) => SaveChildErrors | undefined = (
  addUserChild
) => {
  if (addUserChild?.__typename === 'AddUserChildError') {
    const formatErrorArray = (errorArray: string[] | null | undefined) => (errorArray || []).join(', ');
    return {
      firstName: formatErrorArray(addUserChild.fieldErrors.firstName),
      lastName: formatErrorArray(addUserChild.fieldErrors.lastName),
      dateOfBirth: formatErrorArray(addUserChild.fieldErrors.dateOfBirth),
      gender: formatErrorArray(addUserChild.fieldErrors.gender),
      pronouns: formatErrorArray(addUserChild.fieldErrors.pronouns),
    };
  }
};

const formatPrepopulatedChildData = (childData: ChildAuthorizationQuery_insuranceReferral | null | undefined) => {
  if (!childData) return;
  const { firstName, lastName, dateOfBirth } = childData;
  return {
    profilePicture: '',
    firstName,
    lastName,
    dateOfBirth,
    gender: '',
    pronouns: '',
    relationship: '',
  };
};

export const ChildForm: FC<PropsWithChildren> = () => {
  const history = useHistory();
  const { childAuthorizationData, isChildAuthorizationLoading } = useChildReferral();
  const [validationErrors, setValidationErrors] = useState<SaveChildErrors>();
  const { partnershipId } = usePartnership();

  const goNextPage = (childId?: string) => {
    let route = routes.referral.selector;
    let extraParams = [`childId=${childId}`];
    // If we have partnerId stored in session(from partner onboarding) or if we have the authorization data then we
    // know which partner is it and we can skip the provider selector page
    if ((!isChildAuthorizationLoading && childAuthorizationData) || !!partnershipId) {
      const partnerId = childAuthorizationData?.insurancePartner.id ?? partnershipId;
      route = routes.referral.details;
      extraParams = [`childId=${childId}&providerId=${partnerId}`];
    }
    const routeWithDynamicParam = getLocationDescriptor({
      route,
      forward: true,
      extraParams,
    });
    history.push(routeWithDynamicParam);
  };

  const [addUserChild] = useMutation<AddUserChild, AddUserChildVariables>(ADD_USER_CHILD_MUTATION);

  const onSaveChild = useCallback(
    async (child: ChildProfileFormData): Promise<SaveChildResult> => {
      const { data: addUserChildData, errors: gqlErrors } = await addUserChild({
        variables: {
          input: {
            firstName: child.firstName,
            lastName: child.lastName,
            dateOfBirth: child.dateOfBirth,
            gender: child.gender?.toString() || null,
            pronouns: child.pronouns?.toString() || null,
            relationship: child.relationship?.toString() || null,
            isSeekingCare: strToBool(child.isSeekingCare),
          },
        },
      });
      const errorMessages = formatGqlErrorMessages(addUserChildData?.addUserChild);

      setValidationErrors(errorMessages);
      const isValid = !gqlErrors && !errorMessages;

      return {
        isValid,
        errorMessages,
        userChildId:
          addUserChildData?.addUserChild?.__typename === 'AddUserChildResultType'
            ? addUserChildData?.addUserChild?.child.id
            : undefined,
      };
    },
    [addUserChild]
  );

  if (isChildAuthorizationLoading) return <PageWideLoading />;

  return (
    <>
      <HeaderContainer>
        <Header as="h3">Tell us about your child</Header>
        <DescriptionContainer>
          <Description>
            Don't worry, Little Otter will only use and disclose this information in accordance with our{' '}
            <Link href="https://www.littleotterhealth.com/privacy" isAnchor>
              privacy policy
            </Link>
            .
          </Description>
          <HIPAACompliantPopup />
        </DescriptionContainer>
      </HeaderContainer>
      <Form
        goNextPage={goNextPage}
        submitChildDataFn={onSaveChild}
        prepopulatedChild={formatPrepopulatedChildData(childAuthorizationData)}
        validationErrors={validationErrors}
        isCreateFlow
      />
    </>
  );
};
