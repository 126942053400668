import { type FC, type PropsWithChildren, useEffect } from 'react';

import { useEventTracking } from '$services/event-tracking';

import { type QuestionAnswer, type QuestionData } from '../../../../types';
import { StyledContainer } from '../styled';

import { QuestionPrompt } from './QuestionPrompt';
import { SingleSelectionQuestionInput } from './SingleSelectionQuestionInput';

export type QuestionContentProps = {
  question: QuestionData;
  questionIndex: number;
  totalQuestions: number;
  getAnswer: () => QuestionAnswer | undefined;
  nextButtonText?: string;
  onAnswer: (answer?: QuestionAnswer) => void;
  trackingSubject?: string;
};

export const QuestionContent: FC<PropsWithChildren<QuestionContentProps>> = ({
  question,
  questionIndex,
  totalQuestions,
  nextButtonText,
  getAnswer,
  onAnswer,
  trackingSubject,
}) => {
  const { promptOptions, singleSelectOptions } = question.question;

  const { track } = useEventTracking();
  useEffect(() => {
    track('VIEWED_CHECKUP_STEP', {
      step: `${questionIndex} of ${totalQuestions}`,
      subject: trackingSubject,
    });
  }, [track, trackingSubject, questionIndex, totalQuestions]);

  return (
    <StyledContainer>
      {promptOptions ? (
        <QuestionPrompt
          goNext={onAnswer}
          totalQuestions={totalQuestions}
          promptOptions={promptOptions}
          nextButtonText={nextButtonText}
        />
      ) : (
        singleSelectOptions && (
          <SingleSelectionQuestionInput
            questionId={question.question.id}
            answer={getAnswer()}
            required={question.required}
            current={questionIndex}
            total={totalQuestions}
            nextButtonText={nextButtonText}
            onAnswer={(submittedAnswer) => onAnswer(submittedAnswer)}
            singleSelectOptions={singleSelectOptions}
          />
        )
      )}
    </StyledContainer>
  );
};
