import { StateMachineProvider } from 'little-state-machine';
import { Helmet } from 'react-helmet';

import { Logo } from '@littleotter/legacy-components';

import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../../components/MetadataRoute';
import { Page } from '../../../components/Page';
import { RouteSwitch } from '../../../components/RouteSwitch';
import { routes } from '../../../routes';

import { ChildInfo } from './ChildInfo';
import { FeedbackWithPrerequisites } from './Feedback';
import { Intro } from './Intro';
import { TantrumSurveyWithPrerequisites } from './TantrumSurvey';

export const PublicTantrumScreen = () => (
  <StateMachineProvider>
    <Helmet>
      <meta name="description" content="Take the Temper Tantrum Assessment in the Little Otter Health app" />
    </Helmet>
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.tantrums.home)}>
        <Page hasHeader headerTitle={<Logo variant="wordmark" sizeType="height" sizeAmount="1.5rem" />}>
          <Intro />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.tantrums.child)}>
        <Page hasHeader hasBackButton headerTitle={<Logo variant="wordmark" sizeType="height" sizeAmount="1.5rem" />}>
          <ChildInfo />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.tantrums.survey)}>
        <Page hasHeader hasBackButton headerTitle={<Logo variant="wordmark" sizeType="height" sizeAmount="1.5rem" />}>
          <TantrumSurveyWithPrerequisites />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.tantrums.feedback)}>
        <Page hasHeader hasBackButton headerTitle={<Logo variant="wordmark" sizeType="height" sizeAmount="1.5rem" />}>
          <FeedbackWithPrerequisites />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  </StateMachineProvider>
);
