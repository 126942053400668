import { type ReactElement, type ReactNode } from 'react';

import { TimelineHeader } from '../TimelineHeader';

import {
  TimelineCardContainer,
  TimelineCardContent,
  TimelineCardsContainer,
  TimelineContainer,
  TimelineDate,
  TimelineMonth,
  TimelineTime,
} from './styled';

export type TimelineProps<Content extends TimelineContent> = {
  sectionTitle: string;
  content: Content[];
  seeAllUrl: string;
  children: (content: Content) => ReactNode;
  removePaddingTop?: boolean;
};

export type TimelineContent<OriginalContent extends object | unknown = unknown> = {
  date: Date;
  title: string;
  subtitle?: string;
  content?: string;
  avatar?: string;
  id: string;
  original?: OriginalContent;
};

export const Timeline = <Content extends TimelineContent>({
  removePaddingTop,
  sectionTitle,
  content,
  seeAllUrl,
  children,
}: TimelineProps<Content>): ReactElement => {
  const getLocaleDate = (date: Date) => date.toLocaleString('en-US', { day: '2-digit' });
  const getLocaleMonth = (date: Date) => date.toLocaleString('en-US', { month: 'short' });
  const getFullDate = (date: Date) =>
    `${date.getFullYear()}-${date.toLocaleString('en-US', { month: '2-digit' })}-${getLocaleDate(date)}`;

  const sortedContent = [...content].sort((dateA, dateB) => dateB.date.getTime() - dateA.date.getTime()).slice(0, 3);

  return (
    <TimelineContainer>
      <TimelineHeader removePaddingTop={removePaddingTop} text={sectionTitle} seeAllUrl={seeAllUrl} />
      <TimelineCardsContainer>
        {sortedContent.map((card) => (
          <TimelineCardContainer key={card.id}>
            <TimelineTime dateTime={getFullDate(card.date)}>
              <TimelineDate>{card.date.getDate()}</TimelineDate>
              <TimelineMonth>{getLocaleMonth(card.date)}</TimelineMonth>
            </TimelineTime>
            <TimelineCardContent>{children(card)}</TimelineCardContent>
          </TimelineCardContainer>
        ))}
      </TimelineCardsContainer>
    </TimelineContainer>
  );
};
