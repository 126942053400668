import { type FC, type PropsWithChildren } from 'react';

import { type ButtonProps, FullButton } from '@littleotter/legacy-components';

import { MarginChildren } from '../../../../components';

import { StyledActionsWrapper, StyledLink } from './styled';

interface BottomActionButtonsProps {
  submitText: string;
  submitProps: ButtonProps;
  showLink?: boolean;
  linkText?: string;
  linkAction: () => void;
}

export const BottomActionButtons: FC<PropsWithChildren<BottomActionButtonsProps>> = ({
  submitText,
  submitProps,
  showLink,
  linkText,
  linkAction,
}) => {
  return (
    <StyledActionsWrapper>
      <MarginChildren>
        <FullButton {...submitProps}>{submitText}</FullButton>
      </MarginChildren>
      {showLink && <StyledLink onClick={linkAction}>{linkText}</StyledLink>}
    </StyledActionsWrapper>
  );
};
