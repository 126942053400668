import { type Maybe } from 'graphql/jsutils/Maybe';

import { logger } from '$services/logging';

import {
  type AssessmentDefinition,
  type AssessmentQuestion,
  type Choice as GqlChoice,
  type Question as GqlQuestion,
  QuestionType as GqlQuestionType,
  type PromptOptions,
  type SingleSelectOptions,
} from '../../../graphql/lo1/generated';
import { type Choice, type Question, type QuestionData, QuestionType } from '../types';

export const gqlAssessmentDefinitionToQuestion = (assessmentDefinition: AssessmentDefinition): QuestionData[] => {
  return assessmentDefinition.questions.map((question) => getQuestionData(question));
};

const getQuestionData = (assessmentQuestion: AssessmentQuestion): QuestionData => {
  return {
    required: assessmentQuestion.questionOptions?.required ?? false,
    question: getQuestion(assessmentQuestion.question),
  };
};

const getQuestion = (gqlQuestion: GqlQuestion): Question => {
  switch (gqlQuestion.questionConfig.questionType) {
    case GqlQuestionType.SingleSelect: {
      const { singleSelectOptions } = gqlQuestion.questionConfig;

      if (!singleSelectOptions) {
        throw new Error('Missing expected options for SingleSelect QuestionType');
      }

      return getSingleSelectQuestion(gqlQuestion.variableName, singleSelectOptions);
    }
    case GqlQuestionType.Prompt: {
      const { promptOptions } = gqlQuestion.questionConfig;

      if (!promptOptions) {
        throw new Error('Missing expected options for Prompt QuestionType');
      }

      return getPrompt(gqlQuestion.variableName, promptOptions);
    }
    default: {
      const err = new Error('Unknown question type found');
      logger.error(err, {
        questionType: gqlQuestion.questionConfig.questionType,
        questionId: gqlQuestion.variableName,
      });
      return assertNever(gqlQuestion.questionConfig.questionType);
    }
  }
};

const getPrompt = (questionId: string, gqlPromptOptions: PromptOptions): Question => {
  return {
    id: questionId,
    type: QuestionType.Prompt,
    promptOptions: {
      prompt: gqlPromptOptions.prompt,
      promptImage: gqlPromptOptions.imageUrl ?? undefined,
      questionText: gqlPromptOptions.subPrompt,
      duration: gqlPromptOptions.approximateTime ?? undefined,
    },
  };
};

const getSingleSelectQuestion = (questionId: string, gqlSingleSelectOptions: SingleSelectOptions): Question => {
  return {
    id: questionId,
    type: QuestionType.SingleSelect,
    singleSelectOptions: {
      prompt: gqlSingleSelectOptions.prompt,
      questionText: gqlSingleSelectOptions.questionText,
      choices: getChoices(gqlSingleSelectOptions.choices),
    },
  };
};

const getChoices = (gqlChoices: Array<Maybe<GqlChoice>>): Choice[] => {
  return gqlChoices.map((choice) => {
    if (!choice) {
      throw new Error('Missing expected optional choice');
    }
    return {
      id: choice.id,
      choice: choice.choice,
      value: choice.value,
    };
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertNever = (_: never): never => {
  throw new Error('Unexpected value. Should have been never.');
};
