import { type FC, type PropsWithChildren } from 'react';

import { BaseTable } from '../BaseTable';

import { useMyClientsTable } from './hooks';
import { type MyClientsTableDataItem } from './types';

export type MyClientsTableProps = {
  tableData: MyClientsTableDataItem[];
  providerId?: string;
};

/**
 * Table for the `My Clients` section
 *
 * It automatically sorts rows by family name alphabetically
 *
 * @param props.tableData array of `MyClientsTableDataItem` objects needed for
 * populating the table
 *
 * @param props.providerId if this is set, it only shows the appointments for
 * the given provider id. Otherwise, it shows everything
 */
export const MyClientsTable: FC<PropsWithChildren<MyClientsTableProps>> = ({ tableData, providerId }) => {
  const tableInstance = useMyClientsTable({ tableData, providerId });

  if (!tableInstance.rows.length) return <div>No data</div>;

  return <BaseTable tableInstance={tableInstance} />;
};
