import { useQuery } from '@apollo/client';
import { useAuth } from '@clerk/clerk-react';
import { createContext, type PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { useEventTracking } from '$services/event-tracking';
import { type IdentityManager } from '$services/featureFlags';
import { logger } from '$services/logging';

import { type ViewerQuery, type ViewerQuery_viewer } from '../../../graphql/__generated__/ViewerQuery';
import { VIEWER_QUERY } from '../../../graphql/user';
import { useGraphQLErrorHandling } from '../../hooks';

type ContextStateType = {
  loading: boolean;
  isAuthenticatedViewer: boolean;
  viewer: ViewerQuery_viewer | null;
  isStaffViewer: boolean;
  reloadViewer: () => Promise<void>;
  isActivelyInCare: boolean;
  isMember: boolean;
  hasConfirmedAppointments: boolean;
  isInAllowedState: boolean;
  // ! HACK(PD-1731)
  wdVisited: boolean;
};
type ContextType = ContextStateType & {
  clearContext: () => void;
  setWdVisited: (b: boolean) => void;
};
const defaultContextState: ContextStateType = {
  loading: true,
  isAuthenticatedViewer: false,
  viewer: null,
  isStaffViewer: false,
  reloadViewer: () => Promise.resolve(),
  isActivelyInCare: false,
  isMember: false,
  hasConfirmedAppointments: false,
  isInAllowedState: false,
  // ! HACK(PD-1731)
  wdVisited: false,
};

const ViewerContext = createContext<ContextType>({
  ...defaultContextState,
  clearContext: () => undefined,
  setWdVisited: () => undefined,
});

export const useViewer = () => useContext(ViewerContext);

export interface ViewerProviderProps extends PropsWithChildren {
  identityManager: IdentityManager;
}

export const ViewerProvider = ({ children, identityManager }: ViewerProviderProps) => {
  const [contextState, setContextState] = useState<ContextStateType>(defaultContextState);
  const { identify } = useEventTracking();
  const { isLoaded: isClerkLoaded, userId, actor } = useAuth();

  // ! HACK(PD-1731)
  const [wdVisited, setWdVisited] = useState(false);

  const { data, error, loading, refetch } = useQuery<ViewerQuery>(VIEWER_QUERY);
  useGraphQLErrorHandling(error);

  const isLoading = !isClerkLoaded || loading;
  const isImpersonatedSession = userId && actor;

  // This allows other components to reload the viewer after logging in/out, creating the account, etc.
  const reloadViewerUntyped = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    setContextState({
      viewer: data?.viewer ?? null,
      loading: isLoading,
      isAuthenticatedViewer: data?.viewer != null,
      isStaffViewer: data?.viewer?.isStaff ?? false,
      reloadViewer: reloadViewerUntyped,
      isActivelyInCare: data?.viewer?.family?.careStatus.activelyInCare ?? false,
      isMember: data?.viewer?.family?.careStatus.isMember ?? false,
      hasConfirmedAppointments: data?.viewer?.hasConfirmedAppointments ?? false,
      isInAllowedState: data?.viewer?.asCaregiver?.address?.state?.allowed ?? false,
      // ! HACK(PD-1731)
      wdVisited,
    });
    // Do not identify impersonated users
    if (data?.viewer) {
      if (isImpersonatedSession) {
        logger.debug('skipping identify bc current user is impersonated');
      } else {
        identityManager.setIdentity(data.viewer.id);

        identify(data.viewer.email, {
          loId: data.viewer.id,
          firstName: data.viewer.firstName ?? undefined,
          lastName: data.viewer.lastName ?? undefined,
          email: data.viewer.email, // We intentionally are keeping email as a trait, even though it is the unique id, because lots of destinations use this trait.
          isStaff: data.viewer.isStaff,
        });
      }
    } else if (!isLoading) {
      // This is to handle the edge case where a user deletes their cookies to log out, instead of
      // going through the /logout route.
      identityManager.clearIdentity();
    }
  }, [data, identityManager, reloadViewerUntyped, wdVisited, identify, isImpersonatedSession, isLoading]);

  const clearContext = useCallback(() => {
    identityManager.clearIdentity();
    setContextState(defaultContextState);
  }, [identityManager, setContextState]);

  return (
    <ViewerContext.Provider value={{ ...contextState, clearContext, setWdVisited }}>{children}</ViewerContext.Provider>
  );
};
