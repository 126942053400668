import { type FC, type PropsWithChildren } from 'react';

import { type Subject } from '../../types';

import * as Styled from './styled';

export const SubjectSelector: FC<PropsWithChildren<SubjectSelectorProps>> = ({
  subjects,
  selectedSubject,
  onClickSubject,
}) => {
  return (
    <Styled.Root>
      <Styled.Container>
        {subjects.map((subject) => (
          <Styled.Subject
            key={subject.id}
            selected={subject.id === selectedSubject.id}
            onClick={() => onClickSubject(subject)}
          >
            {subject.displayName}
          </Styled.Subject>
        ))}
      </Styled.Container>
    </Styled.Root>
  );
};

export interface SubjectSelectorProps {
  subjects: Subject[];
  selectedSubject: Subject;
  onClickSubject: (s: Subject) => void;
}
