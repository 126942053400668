import { type FC, type PropsWithChildren } from 'react';

import { Link } from '@littleotter/legacy-components';

import { ReportGrayCard } from '../components/ReportGrayCard';

export type FreeReportCardProps = {
  className?: string;
  isInAllowedState: boolean;
  goScheduleWelcomeCall: () => void;
  goPurchaseReport?: () => void;
};

const icon = (
  <img
    src="https://littleotter-assets.s3.us-west-2.amazonaws.com/care_den/sprout.svg"
    width="30"
    height="45"
    alt="Sprout img"
  />
);

export const FreeReportCard: FC<PropsWithChildren<FreeReportCardProps>> = ({
  className,
  isInAllowedState,
  goScheduleWelcomeCall,
  goPurchaseReport,
}) => (
  <ReportGrayCard className={className} title="" icon={icon}>
    {isInAllowedState && (
      <p>
        <strong>This is the high-level version of your report. </strong>
        <Link variant="link" isAnchor onClick={goScheduleWelcomeCall}>
          <strong>Schedule your first session</strong>
        </Link>{' '}
        to go in-depth into what this means for your family, get actionable feedback, and leave with recommendations you
        can implement right away.
      </p>
    )}
    {!isInAllowedState && goPurchaseReport && (
      <p>
        This is the high-level version of your report.&nbsp;
        {/* eslint-disable-next-line no-script-url */}
        <Link variant="link" href="javascript:void(0);" isAnchor onClick={goPurchaseReport}>
          <strong>Purchase the full report</strong>
        </Link>{' '}
        to go in-depth into what this means for your family.
      </p>
    )}
  </ReportGrayCard>
);
