import { useEffect } from 'react';
import { type TableInstance, useGlobalFilter, useSortBy, useTable } from 'react-table';

import { type MyClientsTableDataItem } from '../types';

import { useTableOptions } from './useTableOptions';

type UseMyClientsTable = (options: {
  tableData: MyClientsTableDataItem[];
  providerId?: string;
}) => TableInstance<MyClientsTableDataItem>;

export const useMyClientsTable: UseMyClientsTable = ({ tableData, providerId }) => {
  const tableOptions = useTableOptions(tableData);
  const tableInstance = useTable(tableOptions, useGlobalFilter, useSortBy);

  useEffect(() => {
    tableInstance.setGlobalFilter({ providerId });
  }, [providerId, tableInstance]);

  return tableInstance;
};
