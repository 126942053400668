import { type FC, type PropsWithChildren } from 'react';

import { Header, ProfilePicture } from '@littleotter/legacy-components';

import { getStateNameFromAbbreviation } from '$shared/utils/stateList';

import { type ProviderClientQuery_user } from '../../../graphql/__generated__/ProviderClientQuery';

import { Card, ProfileDetails } from './components';

type ClientDetailsCardProps = {
  user: ProviderClientQuery_user;
};

export const ClientDetailsCard: FC<PropsWithChildren<ClientDetailsCardProps>> = ({
  user: { profilePicture, firstName, lastName, email, state },
}) => (
  <Card>
    <ProfilePicture size="small" src={profilePicture} />
    <ProfileDetails>
      <Header as="h2">
        {firstName} {lastName}
      </Header>
      <div>{email}</div>
      {state && <div>{getStateNameFromAbbreviation(state)}</div>}
    </ProfileDetails>
  </Card>
);
