/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
/* eslint-disable func-names */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { type ILoggerService } from '../../../../logging/types';

/**
 * Initializes the Rudderstack SDK
 * @see https://www.rudderstack.com/docs/user-guides/how-to-guides/rudderstack-jamstack-integration/rudderstack-nextjs-integration/
 *
 * Rudderstack provides us this snippet to initialize their SDK. For the most part we're using it as-is (except for a
 * guard against undefined configs and a log on successful load). This means we have
 * to disable a bunch of eslint and typescripts rules to get it to work, but it's worth it to avoid
 * having to maintain a very custom version of this snippet.
 *
 * ! IMPORTANT: Rudderstack recommends calling `page` immediately when the SDK mounts (after the `ready` event), but the
 * ! issue with that is the first page view won't have app metadata (like feature flags) attached. For this reason,
 * ! we're going to manually handle the initial page view in the consumer app implementation.
 */
export const initRudderstackSdk = async (
  logger: ILoggerService,
  dataplaneUrl?: string,
  writeKey?: string,
  sdkUrl?: string,
  sendAdBlockPageEvent?: boolean
) => {
  if (!dataplaneUrl || !writeKey || !sdkUrl) {
    return;
  }

  (function () {
    const e = (window.rudderanalytics = window.rudderanalytics || []);
    (e.methods = [
      'load',
      'page',
      'track',
      'identify',
      'alias',
      'group',
      'ready',
      'reset',
      'getAnonymousId',
      'setAnonymousId',
      'getUserId',
      'getUserTraits',
      'getGroupId',
      'getGroupTraits',
      'startSession',
      'endSession',
    ]),
      (e.factory = function (t) {
        return function () {
          e.push([t].concat(Array.prototype.slice.call(arguments)));
        };
      });
    for (let t = 0; t < e.methods.length; t++) {
      const r = e.methods[t];
      e[r] = e.factory(r);
    }
    (e.loadJS = function (e, t) {
      const r = document.createElement('script');
      (r.type = 'text/javascript'), (r.async = !0), (r.src = sdkUrl);
      const a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(r, a);
    }),
      e.loadJS(),
      e.load(writeKey, dataplaneUrl, {
        sendAdblockPage: sendAdBlockPageEvent,
        sendAdblockPageOptions: {
          integrations: {
            All: false,
            Mixpanel: true,
            'Amazon S3': true,
          },
        },
      }),
      e.ready(() => {
        logger.debug('[platform.event-tracking.rudderstack.sdk] Rudderstack SDK loaded & ready');
      });
  })();
};
