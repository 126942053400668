import { type IEnvironmentService } from '../../environment/types';
import type { ErrorData, ILogHandler } from '../types';

import { BaseHandler } from './__BaseHandler';

/**
 * A log handler that logs to the browser's console.
 */
export class ConsoleHandler extends BaseHandler implements ILogHandler {
  /**
   * The console object is a global object that provides access to the browser's debugging console (e.g. the Web Console in Firefox).
   */
  console;

  constructor() {
    super();
    /**
     * We are only setting a local instance of the console here to bypass ESLint errors that yell about
     * console-related methods being called directly. IE... we can call `this.console.info()` instead of `console.info()`.
     */
    this.console = console;
  }

  // eslint-disable-next-line class-methods-use-this
  isEnabled(env: IEnvironmentService): boolean {
    return env.isLocalEnvironment || env.isServer;
  }

  TRACE(message: string, context?: object) {
    this.console.trace(message, { ...context });
  }

  DEBUG(message: string, context?: object) {
    this.console.debug(message, { ...context });
  }

  INFO(message: string, context?: object) {
    this.console.info(message, { ...context });
  }

  WARN(message: string, context?: object) {
    this.console.warn(message, { ...context });
  }

  ERROR(error: string, errorData?: ErrorData, context?: object) {
    this.console.error(error, { ...context, ...errorData });
  }
}
