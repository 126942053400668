import { type FC, useState } from 'react';
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import styled, { useTheme } from 'styled-components';

const ToolTipContainer = styled.div`
  margin-top: 5px;
  position: relative;
`;

const ToolTipContent = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: absolute;
  margin-top: 10px;
  flex-direction: column;
  width: 300px;
  height: 60px;
  text-align: center;
  user-select: none;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 1;
  background-color: white;
`;

const LinkAnchor = styled.a`
  font-family: ${({ theme }) => theme.deprecated_.fontFamilies.body};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  letter-spacing: 0.03em;
  color: #276ebe;
  cursor: pointer;
`;

const IconAligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const privacyPolicyUrl = 'https://www.littleotterhealth.com/privacy';

export const HIPAACompliantPopup: FC = () => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <ToolTipContainer>
      <IconAligner>
        <BsFillQuestionCircleFill
          color={theme.deprecated_.colors.gray}
          onClick={() => {
            setTooltipIsOpen((prevState) => !prevState);
          }}
        />
      </IconAligner>
      <ToolTipContent isOpen={tooltipIsOpen}>
        <span>Our platform is HIPAA compliant.</span>
        <LinkAnchor href={privacyPolicyUrl} target="_blank" rel="noopener,noreferrer">
          Learn more about our privacy policy here
        </LinkAnchor>
      </ToolTipContent>
    </ToolTipContainer>
  );
};
