import { debounce } from 'lodash-es';
import { type FC, type PropsWithChildren, useEffect, useMemo } from 'react';

import { useSearchParams } from '@littleotter/kit/react';
import { Input } from '@littleotter/legacy-components';

import { StyledContent } from './styled';

export const SearchBar: FC<PropsWithChildren<{ disabled: boolean }>> = ({ disabled }) => {
  const [{ q: query }, { pushSearchParams }] = useSearchParams<{ q: string }>();

  const handleChange = useMemo(() => {
    return debounce((value: string | number) => {
      if (typeof value === 'string') {
        pushSearchParams({ q: value });
      }
    }, 250);
  }, [pushSearchParams]);

  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [handleChange]);

  return (
    <StyledContent>
      <Input
        disabled={disabled}
        defaultValue={query}
        name="search"
        placeholder={disabled ? 'Loading search bar...' : 'Search for conversations by title'}
        onChange={handleChange}
      />
    </StyledContent>
  );
};
