import { type FC, type PropsWithChildren } from 'react';

import { BrandCard, Header, Link } from '@littleotter/legacy-components';

import {
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_newSchool,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_schoolAnxiety,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_socialStrengths,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_teacherCalled,
  type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_wordCloud,
} from '../../../../graphql/__generated__/BackToSchoolReportQuery';
import { routes } from '../../../../routes';
import { WordCloud } from '../components';

import { CollapseSection } from './CollapseSection';
import { Section } from './styled';

type SchoolFeelingsProps = {
  wordCloud: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_wordCloud[];
  schoolAnxiety: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_schoolAnxiety | null;
  newSchool: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_newSchool | null;
  teacherCalled: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_teacherCalled | null;
  socialStrengths: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_socialStrengths | null;
};

export const SchoolFeelings: FC<PropsWithChildren<SchoolFeelingsProps>> = ({
  wordCloud,
  schoolAnxiety,
  newSchool,
  teacherCalled,
  socialStrengths,
}) => (
  <Section>
    <Header as="h4" id="school-feelings">
      School Feelings
    </Header>

    <div>
      <WordCloud words={wordCloud} />
    </div>

    {socialStrengths && (
      <CollapseSection
        sectionTitle="Your child has social strengths"
        meaningContent={socialStrengths.content.meaning}
        whatICanDoToSupportContent={socialStrengths.content.whatICanDoToSupport}
        activityToDoContent={socialStrengths.content.activityToDo}
      />
    )}

    {schoolAnxiety && (
      <CollapseSection
        sectionTitle="School anxieties"
        meaningContent={schoolAnxiety.content.meaning}
        whatICanDoToSupportContent={schoolAnxiety.content.whatICanDoToSupport}
        activityToDoContent={schoolAnxiety.content.activityToDo}
      />
    )}

    {newSchool && (
      <CollapseSection
        sectionTitle="Starting at a new school or program"
        meaningContent={newSchool.content.meaning}
        whatICanDoToSupportContent={newSchool.content.whatICanDoToSupport}
        activityToDoContent={newSchool.content.activityToDo}
      />
    )}

    {teacherCalled && (
      <CollapseSection
        sectionTitle="Your child had some challenges at school last year"
        meaningContent={teacherCalled.content.meaning}
        whatICanDoToSupportContent={teacherCalled.content.whatICanDoToSupport}
        activityToDoContent={teacherCalled.content.activityToDo}
      />
    )}

    <BrandCard>
      <Link isAnchor href={routes.unauthOnboarding.url()}>
        <strong>Join Little Otter</strong>
      </Link>{' '}
      to get even more mental health check-ups and on-demand care
    </BrandCard>
  </Section>
);
