import { useEffect, useMemo, useState } from 'react';

import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';

import { getConversationTitleWithTimezone } from '../../../../pages/Messages/utils/getConversationTitle';
import { useFilteredData } from '../../../hooks/useFilteredData';
import { useViewer } from '../../Viewer';

import { type ConversationInfo, useConversationsInfo } from './useConversationsInfo';

export const useFilteredConversations = (
  conversations: ConversationPreview[]
): { filteredConversations: ConversationPreview[]; filterReady: boolean } => {
  const { viewer } = useViewer();
  const [conversationsInfo] = useConversationsInfo(conversations);
  const [titlesBySid, setTitlesBySid] = useState<{ [key: string]: string }>();
  const [filterReady, setFilterReady] = useState<boolean>(false);

  useEffect(() => {
    if (conversationsInfo.length) {
      const titles: { [key: string]: string } = {};
      conversationsInfo.forEach((ci: ConversationInfo) => {
        const otherParticipants = ci.participants.filter((p) => p.id !== viewer?.id);
        titles[ci.conversationSid] = getConversationTitleWithTimezone(otherParticipants).toLowerCase();
      });
      setTitlesBySid(titles);
    }
  }, [conversationsInfo, viewer?.id]);

  const filterFunction = useMemo(() => {
    if (!titlesBySid) {
      // Disable the filter until we've fetched all conversation titles.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (c: ConversationPreview, query: string | undefined) => true;
    }
    setFilterReady(true);
    return (c: ConversationPreview, query: string | undefined) => {
      if (!(c.sid in titlesBySid)) {
        return false;
      }
      if (!query) {
        return true;
      }
      const title = titlesBySid[c.sid] || '';
      return title.includes(query.toLowerCase());
    };
  }, [titlesBySid]);

  const { visibleItems: filteredConversations } = useFilteredData<ConversationPreview>(conversations, filterFunction);

  return {
    filteredConversations,
    filterReady,
  };
};
