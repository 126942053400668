import { type FC, type PropsWithChildren, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Logo } from '@littleotter/legacy-components';

import { useEventTracking } from '$services/event-tracking';

import * as Styled from './styled';

export type PaymentConfirmationProps = {
  nextRoute: string;
};

export const PaymentConfirmation: FC<PropsWithChildren<PaymentConfirmationProps>> = ({ nextRoute }) => {
  const { track } = useEventTracking();
  const { fmhcId } = useParams<{ fmhcId: string }>();
  useEffect(() => {
    track('FMHC_REPORT_PURCHASED', { fmhcId });
  }, [fmhcId, track]);
  return (
    <>
      <Styled.Background />
      <Styled.Root>
        <Styled.Body>
          <Logo sizeType="height" sizeAmount={160} />
          <Styled.Message>Your report is ready!</Styled.Message>
          <Styled.Message>Schedule your free kickoff session with code:</Styled.Message>
          <Styled.Code>KICKOFF20</Styled.Code>
        </Styled.Body>
        <Styled.FullReportAnchor href={nextRoute}>View Full Report</Styled.FullReportAnchor>
      </Styled.Root>
    </>
  );
};
