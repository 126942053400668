import * as styled from './styled';

import clipboardIconImg from './assets/clipboard.svg';
import { ReactComponent as StarIcon } from './assets/star.svg';
import { ReactComponent as ArrowIcon } from '../../../pages/Care/Home/assets/arrowRight.svg';

export const BaseFmhcCtaCard = ({ href, title, label, isEnabled = true }: BaseFmhcCtaCardProps) => {
  return isEnabled && href ? (
    <styled.Link to={href}>
      <styled.Root>
        <styled.IconContainer>
          <img src={clipboardIconImg} alt="An illustrated clipboard with three checklist items on it" />
        </styled.IconContainer>

        <div>
          <styled.Required>
            <StarIcon /> {label}
          </styled.Required>
          <styled.Title>{title}</styled.Title>
        </div>

        <styled.ArrowContainer>
          <ArrowIcon />
        </styled.ArrowContainer>
      </styled.Root>
    </styled.Link>
  ) : (
    <styled.DisabledLink>
      <styled.Root disabled>
        <styled.IconContainer>
          <img src={clipboardIconImg} alt="An illustrated clipboard with three checklist items on it" />
        </styled.IconContainer>

        <div>
          <styled.Title>{title}</styled.Title>
        </div>
      </styled.Root>
    </styled.DisabledLink>
  );
};

export interface BaseFmhcCtaCardProps {
  href?: string;
  title: string;
  label?: string;
  isEnabled?: boolean;
}
