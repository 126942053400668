import { gql, useQuery } from '@apollo/client';

import { useGraphQLErrorHandling } from '../../../../../shared/hooks';

import { type WorryDomainsQuery, type WorryDomainsQuery_viewer_worryDomains } from './__generated__/WorryDomainsQuery';

const WORRY_DOMAIN_FRAGMENT = gql`
  fragment ReportWorryDomainFragment on WorryDomainTypeConnection {
    edges {
      node {
        pk
        name
      }
    }
  }
`;

const WORRY_DOMAINS_QUERY = gql`
  query WorryDomainsQuery {
    viewer {
      id
      worryDomains {
        ...ReportWorryDomainFragment
      }
      family {
        id
        worryDomains {
          ...ReportWorryDomainFragment
        }
        children {
          id
          worryDomains {
            ...ReportWorryDomainFragment
          }
        }
      }
    }
  }

  ${WORRY_DOMAIN_FRAGMENT}
`;
export type AllWorryDomains = {
  viewer: string[];
  family: string[];
  children: { [key: string]: string[] };
};

/**
 * useWorryDomains loads worry domains for family, viewer, and their children.
 * @returns the list of worry domains for the family and viewer, and for their children
 * a mapping from the child id to their worry domains
 */
export const useWorryDomains = (): AllWorryDomains & { loading: boolean } => {
  const { data, loading, error } = useQuery<WorryDomainsQuery>(WORRY_DOMAINS_QUERY);
  useGraphQLErrorHandling(error);

  return {
    viewer: flattenWorryDomains(data?.viewer?.worryDomains),
    family: flattenWorryDomains(data?.viewer?.family?.worryDomains),
    children:
      data?.viewer?.family?.children.reduce(
        (agg, c) => ({ ...agg, [c.id]: flattenWorryDomains(c.worryDomains) }),
        {} as { [key: number]: string[] }
      ) ?? {},
    loading,
  };
};

/**
 * flattenWorryDomains maps from the complicated GQL worry domains type to a simple list of strings
 * @param worries gql worry domains
 * @returns `string[]` of worry domains
 */
const flattenWorryDomains = (worries: WorryDomainsQuery_viewer_worryDomains | undefined) =>
  worries?.edges.map((e) => e?.node?.name).filter(isString) ?? [];

const isString = (s: string | undefined): s is string => !!s;
