import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';

import { Icon } from '../Icon';

import { CollapseContainer, CollapsibleContent, InnerContent, StyledToggleIcon, Toggle, ToggleContent } from './styled';
import { type CollapseContentContainerVariant, type CollapseContentIconVariant } from './types';

const ToggleIcon: FC<{ variant: CollapseContentIconVariant; isOpen: boolean }> = ({ variant, isOpen }) => {
  const iconElement = {
    chevron: <Icon name="ChevronRight" />,
    'plus-minus': isOpen ? <IoIosRemove /> : <IoIosAdd />,
  }[variant];

  return (
    <StyledToggleIcon isOpen={isOpen} variant={variant}>
      {iconElement}
    </StyledToggleIcon>
  );
};

type CollapseContentProps = {
  onClick?: () => void;
  isOpen: boolean;
  label: ReactNode;
  maxHeight: string;
  iconVariant?: CollapseContentIconVariant;
  containerVariant?: CollapseContentContainerVariant;
  className?: string;
};

export const CollapseContent: FC<PropsWithChildren<CollapseContentProps>> = ({
  onClick,
  isOpen,
  label,
  maxHeight,
  iconVariant = 'chevron',
  containerVariant = 'compact',
  children,
  className,
}) => (
  <CollapseContainer containerVariant={containerVariant} className={className}>
    <Toggle containerVariant={containerVariant} onClick={onClick}>
      <ToggleContent>{label}</ToggleContent>
      <ToggleIcon variant={iconVariant} isOpen={isOpen} />
    </Toggle>
    <CollapsibleContent isOpen={isOpen} maxHeight={maxHeight}>
      <InnerContent containerVariant={containerVariant}>{children}</InnerContent>
    </CollapsibleContent>
  </CollapseContainer>
);
