import { type FC, type PropsWithChildren } from 'react';

import { SelectableTag, type SelectableTagProps } from '../SelectableTag';
import { Section, TagsContainer, Text } from '../styled';
import { type FilterItem, getFilterItems } from '../utils';

export type AgeFilterProps = {
  data: FilterItem[] | undefined;
  selected: FilterItem[];
  onSelect: (item: FilterItem) => SelectableTagProps['onSelect'];
};

export const AgeFilter: FC<PropsWithChildren<AgeFilterProps>> = ({ data, selected, onSelect }) => {
  const items = getFilterItems(data);

  const isSelected = (item: FilterItem) => selected.some((selectedAgeRange) => selectedAgeRange.pk === item.pk);

  return (
    <Section>
      <Text>Filter content by children age</Text>
      <TagsContainer>
        {items.map((ageTag) => (
          <SelectableTag key={ageTag.pk} selected={isSelected(ageTag)} onSelect={onSelect(ageTag)}>
            {ageTag.name}
          </SelectableTag>
        ))}
      </TagsContainer>
    </Section>
  );
};
