import { type FC, type PropsWithChildren } from 'react';

import { Header } from '@littleotter/legacy-components';

import { type BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_generalRisks } from '../../../../graphql/__generated__/BackToSchoolReportQuery';
import { ColorCard } from '../components';

import { ColorCardContainer, Section } from './styled';

type SummaryProps = {
  generalRisksResult: BackToSchoolReportQuery_assessmentsNamespace_assessmentResponse_result_BackToSchoolReportType_generalRisks;
};

export const Summary: FC<PropsWithChildren<SummaryProps>> = ({
  generalRisksResult: { childSocialEmotional, caregiverMentalHealth, familyStress, schoolFeelings },
}) => (
  <Section>
    <Header as="h4">Summary of Results</Header>
    <p>
      These boxes show what we learned in four areas important for <strong>child</strong> and{' '}
      <strong>caregiver mental health</strong>, <strong>family stress</strong>, and <strong>school</strong>. The color
      of the box shows levels of possible concern in each area.
    </p>

    <ColorCardContainer>
      <ColorCard
        category="ChildEmotionalAndSocialChallenges"
        risk={childSocialEmotional.risk}
        href="#child-emotional-and-social-health"
      />
      <ColorCard
        category="CaregiverMentalHealthRisk"
        risk={caregiverMentalHealth?.risk ?? null}
        href={caregiverMentalHealth ? '#caregiver-anxiety-and-depression' : undefined}
      />
      <ColorCard category="FamilyStress" risk={familyStress.risk} href="#family-stress" />
      <ColorCard category="SchoolFeelings" risk={schoolFeelings.risk} href="#school-feelings" />
    </ColorCardContainer>
  </Section>
);
