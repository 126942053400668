import { type FC, type PropsWithChildren } from 'react';

import { theme } from '../../styles';
import { Logo } from '../Logo';

import { Card } from './Card';

export type BrandCardProps = {
  className?: string;
};

export const BrandCard: FC<PropsWithChildren<BrandCardProps>> = (props) => (
  <Card
    Icon={<Logo className="icon" />}
    alignIcon="top"
    {...props}
    background={theme.deprecated_.colors.lightestGray}
  />
);
