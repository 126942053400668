import { SubjectType } from '../../../../graphql/lo1/generated';
import { type FamilyData, type FmhcReport } from '../types';

// TODO(PD-1103): unify business logic to order [[...children seeking care], [...other children], [...caregivers], family]
// Children seeking care first, then other children, then caregivers, then family.
export const sortFmhcReports = (
  fmhcReports: readonly FmhcReport[],
  familyData: FamilyData | undefined
): FmhcReport[] => {
  const subjectTypeOrdering = new Map<SubjectType, number>([
    [SubjectType.Child, 1],
    [SubjectType.Caregiver, 2],
    [SubjectType.Family, 3],
  ]);
  return [...fmhcReports].sort((a, b) => {
    if (a.subject.subjectType === SubjectType.Child && b.subject.subjectType === SubjectType.Child) {
      if (!familyData) {
        return 0;
      }
      const firstChildIsSeekingCare = familyData.family.children.find(
        (child) => child.id === a.subject.childSubject?.childId
      )?.isSeekingCare
        ? 1
        : 0 ?? 0;
      const secondChildIsSeekingCare = familyData.family.children.find(
        (child) => child.id === b.subject.childSubject?.childId
      )?.isSeekingCare
        ? 1
        : 0 ?? 0;
      return secondChildIsSeekingCare - firstChildIsSeekingCare;
    }
    return (
      (subjectTypeOrdering.get(a.subject.subjectType) ?? 0) - (subjectTypeOrdering.get(b.subject.subjectType) ?? 0)
    );
  });
};
