import styled from 'styled-components';

import { Card } from '../Card';

import cardNavImage from '../../assets/cardNavImage.svg';

export const StyledRequiredText = styled.p`
  color: ${({ theme }) => theme.deprecated_.colors.darkestPurple};
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
`;

export const StyledTitle = styled.h1`
  font-weight: ${({ theme }) => theme.deprecated_.fontWeights.semiBold};
  color: ${({ theme }) => theme.deprecated_.colors.text};
  font-size: ${({ theme }) => theme.deprecated_.fontSizes.regular};
`;

export const StyledTime = styled.span`
  color: ${({ theme }) => theme.deprecated_.colors.text}99;
`;

export const StyledHeader = styled.div``;

export const StyledContent = styled.div`
  color: ${({ theme }) => theme.deprecated_.colors.text}B2;
`;

export const StyledWhiteBackground = styled.div`
  background-color: ${({ theme }) => theme.deprecated_.colors.white};
  border-radius: 1rem;
`;

export const StyledCard = styled(Card)<{
  $required?: boolean;
}>`
  background-color: ${({ theme, $required }) =>
    $required ? theme.deprecated_.colors.lightPurple : theme.deprecated_.colors.white};
  min-height: ${({ $required }) => ($required ? '5.625rem' : '4.875rem')};
  background-image: url(${cardNavImage});
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: ${({ $required }) =>
    `${$required ? '0.8125rem' : '1.0625rem'} 1rem 0.8125rem ${$required ? '3.875rem' : '2.875rem'}`};
`;
