import { type FC, type PropsWithChildren } from 'react';

import { parseFullMarkdown } from '../../../../../FmhcCheckup/utils/markdownParser';
import { CollapseWithIcon } from '../components/CollapseWithIcon';

import { StyledHtmlContainer } from './styled';

export type DomainCardType = 'Explanation' | 'CallToAction';

type CardProps = {
  icon: 'Message' | 'Bulb';
  label: string;
};

const cardPropsByDomainType: Record<DomainCardType, CardProps> = {
  Explanation: {
    icon: 'Message',
    label: 'What does this mean?',
  },
  CallToAction: {
    icon: 'Bulb',
    label: 'What can I do?',
  },
};

export type DomainCardProps = {
  type: DomainCardType;
  content: string;
  disabled?: boolean;
  defaultOpen?: boolean;
  onClick?: () => void;
};

export const DomainCard: FC<PropsWithChildren<DomainCardProps>> = ({
  type,
  content,
  disabled,
  defaultOpen,
  onClick,
}) => {
  const { icon, label } = cardPropsByDomainType[type];

  return (
    <CollapseWithIcon
      label={label}
      iconName={icon}
      disabled={disabled}
      defaultOpen={defaultOpen}
      maxHeight="1500px"
      onClick={onClick}
    >
      <StyledHtmlContainer content={parseFullMarkdown({ template: content })} />
    </CollapseWithIcon>
  );
};
