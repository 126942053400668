import { useAuth, useUser } from '@clerk/clerk-react';
import { type FC, type PropsWithChildren, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useEventTracking } from '$services/event-tracking';
import { logger } from '$services/logging';
import { useViewer } from '$shared/contexts/Viewer';
import { useGraphQLErrorHandling } from '$shared/hooks';
import { STORAGE_KEY, useSessionStorage } from '$shared/hooks/useStorage';
import { delay } from '$shared/utils/delay';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { MissingQueryDataError } from '../../../../graphql/errors';
import { type StateLocationSource, StateLocationSourceEnum } from '../types';

import { PaymentConfirmedModal } from './components';
import { useLazyProviderQuery, useWelcomeCallQuery } from './hooks';
import { SessionDetailsContent, type SessionDetailsContentProps } from './SessionDetailsContent';
import { HiddenSection } from './styled';

const paymentConfirmedModalDelayMs = 3000;

type OnContentLoaded = NonNullable<SessionDetailsContentProps['onLoaded']>;
type OnSessionScheduled = NonNullable<SessionDetailsContentProps['onScheduled']>;

export type SessionData = {
  appointmentDatetime: Date;
  practitionerFirstName: string;
  practitionerLastName: string;
  practitionerCredentials?: string;
};

export type SessionDetailsProps = {
  onSessionBooked: (sessionDetails: SessionData) => void;
};

export const SessionDetails: FC<PropsWithChildren<SessionDetailsProps>> = ({ onSessionBooked }) => {
  const { userId, actor } = useAuth();
  const { user } = useUser();
  const { data, loading, error } = useWelcomeCallQuery();
  const { viewer } = useViewer();
  useGraphQLErrorHandling(error);

  const [getProvider] = useLazyProviderQuery();
  const [contentLoading, setContentLoading] = useState(true);
  const [isPaymentConfirmedModalVisible, setIsPaymentConfirmedModalVisible] = useState(false);

  const location = useLocation<StateLocationSource>();
  const state = location?.state || {};
  const [welcomeCallSourceFromStorage, , clearWelcomeCallStorage] = useSessionStorage(
    STORAGE_KEY.WELCOME_CALL_SOURCE,
    StateLocationSourceEnum.Unknown
  );
  const source = state?.source ?? welcomeCallSourceFromStorage;
  const isImpersonatedSession = userId && actor;

  const { identify } = useEventTracking();
  const onContentLoaded = useCallback<OnContentLoaded>(() => {
    setContentLoading(false);
  }, []);

  const onSessionScheduled = useCallback<OnSessionScheduled>(
    async (appointmentData) => {
      setIsPaymentConfirmedModalVisible(true);
      // Load provider data asynchronously.
      // Show the payment confirmation modal for at least {paymentConfirmedModalDelayMs} ms, or as long
      // as necessary for the provider query to complete.
      const [{ data: providerData, loading: providerLoading }] = await Promise.all([
        getProvider({ variables: { providerId: appointmentData.MemberId } }),
        delay(paymentConfirmedModalDelayMs),
      ]);

      if (!providerLoading && !providerData?.provider) {
        throw new MissingQueryDataError('ProviderQuery');
      }

      if (user?.primaryEmailAddress?.emailAddress) {
        // Do not identify impersonated users
        if (isImpersonatedSession) {
          logger.debug('skipping identify bc current user is impersonated');
        } else {
          identify(user.primaryEmailAddress.emailAddress, { welcomeCallSource: source });
        }
      }
      clearWelcomeCallStorage();

      onSessionBooked({
        appointmentDatetime: new Date(appointmentData.Date),
        practitionerFirstName: providerData?.provider?.firstName ?? '',
        practitionerLastName: providerData?.provider?.lastName ?? '',
      });
    },
    [clearWelcomeCallStorage, getProvider, onSessionBooked, source, identify, isImpersonatedSession, user]
  );
  if (!loading && (!viewer || !data?.authenticatedWelcomeCallService)) {
    throw new MissingQueryDataError('WelcomeCallQuery');
  }
  const welcomeCallService = data?.authenticatedWelcomeCallService;

  return loading ? (
    <PageWideLoading />
  ) : (
    <>
      {viewer && welcomeCallService && (
        <HiddenSection visible={!contentLoading}>
          <SessionDetailsContent
            clientId={viewer.id}
            clientName={`${viewer.firstName} ${viewer.lastName}`}
            clientEmail={viewer.email}
            clientPhone={viewer.phoneNumber}
            serviceId={welcomeCallService.id}
            price={welcomeCallService.price}
            durationInMinutes={welcomeCallService.durationInMinutes}
            onLoaded={onContentLoaded}
            onScheduled={onSessionScheduled}
          />
        </HiddenSection>
      )}
      <PaymentConfirmedModal isOpen={isPaymentConfirmedModalVisible} />
    </>
  );
};
