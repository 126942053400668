import { type FC, type PropsWithChildren, useCallback, useState } from 'react';

import { FullButton, Modal } from '@littleotter/legacy-components';

import { ModalContent } from './styled';

type FileUploadConfirmationModalProps = {
  newFile: File | null;
  sendNewFile: (file: File) => Promise<void>;
  resetFileUpload: () => void;
};

export const FileUploadConfirmationModal: FC<PropsWithChildren<FileUploadConfirmationModalProps>> = ({
  newFile,
  sendNewFile,
  resetFileUpload,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);

    try {
      if (!newFile) {
        throw new Error('No file selected for upload');
      }

      await sendNewFile(newFile);
    } catch (e) {
      if (typeof e === 'string') throw new Error(e);
      throw e;
    } finally {
      resetFileUpload();
      setIsLoading(false);
    }
  }, [newFile, resetFileUpload, sendNewFile]);

  return (
    <Modal
      isOpen={!!newFile}
      onBackgroundClick={isLoading ? undefined : resetFileUpload}
      onEscapeKeydown={isLoading ? undefined : resetFileUpload}
    >
      {!!newFile && (
        <ModalContent>
          <p>Upload {newFile?.name}?</p>
          <FullButton onClick={onConfirm} isLoading={isLoading}>
            Send
          </FullButton>
          <FullButton variant="tertiary" onClick={resetFileUpload} disabled={isLoading}>
            Cancel
          </FullButton>
        </ModalContent>
      )}
    </Modal>
  );
};
