import { routeProps } from '$shared/utils/routes';

import { MetadataRoute } from '../../components/MetadataRoute';
import { Page } from '../../components/Page';
import { RouteSwitch } from '../../components/RouteSwitch';
import { routes } from '../../routes';

import { CareHome } from './Home';
import { CareJourney } from './Journey';
import { SessionNote } from './Journey/SessionNote';
import { NextGenSchedule } from './Schedule/NextGenSchedule';

export const Care = () => {
  return (
    <RouteSwitch>
      <MetadataRoute {...routeProps(routes.care.home)}>
        <Page>
          <CareHome />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.care.journey.home)}>
        <Page hasHeader headerTitle="Care Journey" hasBackButton>
          <CareJourney />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.care.journey.sessionNote)}>
        <Page hasHeader headerTitle="Care Journey" hasBackButton>
          <SessionNote />
        </Page>
      </MetadataRoute>
      <MetadataRoute {...routeProps(routes.care.schedule)}>
        <Page hasHeader headerTitle="Schedule" hasBackButton>
          <NextGenSchedule />
        </Page>
      </MetadataRoute>
    </RouteSwitch>
  );
};
