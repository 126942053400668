import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useTimeout } from '@littleotter/legacy-components';
import { Pagination } from '@littleotter/legacy-components/src/components/Pagination';

import { useConversations } from '$shared/contexts/Conversations';
import { type ConversationPreview } from '$shared/contexts/Conversations/conversationPreview';
import { useGraphQLErrorHandling } from '$shared/hooks';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { SearchBar } from '../../../components/SearchBar';
import { type ViewerQuery } from '../../../graphql/__generated__/ViewerQuery';
import { VIEWER_QUERY } from '../../../graphql/user';
import { getLastActiveAt } from '../utils/getMessageLastActiveAt';

import {
  ConversationListEmptyState,
  ConversationListItem,
  CustomerSupportCard,
  NewConversationButton,
  NewConversationModal,
} from './components';

export const ConversationsList = () => {
  const { data: viewerData, error: viewerError } = useQuery<ViewerQuery>(VIEWER_QUERY);
  useGraphQLErrorHandling(viewerError);
  const viewer = viewerData?.viewer;

  const { conversations, totalConversations, conversationInfoBySid, pagination, isLoading, hasErrors, filterReady } =
    useConversations();

  const [newConversationModalIsOpen, setNewConversationModalIsOpen] = useState(false);

  /**
   * The showEmpty state and its UseEffect are used to prevent the
   * ConversationListEmptyState from rendering when selecting a message.
   *
   * This was happening because the conversations were being unmounted
   * during the transition between views and the this FC would render the
   * ConversationListEmptyState instead of the list of conversations.
   */
  const [showEmpty, setShowEmpty] = useState(true);
  useEffect(() => {
    // DO NOT UPDATE THIS to `setShowEmpty(conversations?.length === 0)`
    // because this will reintroduce the bug. See
    // https://github.com/thelittleotter/littleotter-ui/pull/1013.
    if (conversations.length) {
      setShowEmpty(false);
    }
  }, [conversations]);

  /* This hack forces a rerender after a timeout when a new message is added in
   * a conversation.
   *
   * When a message is added, the Twilio SDK still has an outdated value on
   * `conversation.lastMessage`. Because of this, `sortConversationsByActivity`
   * doesn't instantly put the conversation with the latest message at the top
   * and we need to wait for `conversation.lastMessage` to update before
   * rerendering.
   *
   * TODO: figure out a better way to update the list with the correct sorting
   */
  const [, setMessageAddedHack] = useState(false);

  const [timeoutDelay, setTimeoutDelay] = useState<number | null>(null);

  useTimeout(() => {
    setMessageAddedHack((value) => !value);
    setTimeoutDelay(null);
  }, timeoutDelay);

  // If we throw an error, we would trigger the error boundary on certain
  // cases because of a race condition on `useTwilioClient` caused by
  // `ConversationsViewerQuery`
  if (hasErrors) return null;

  const onMessageAdded = () => {
    setTimeoutDelay(100);
  };

  if (!viewer || isLoading) {
    return <PageWideLoading />;
  }

  const renderConversationRow = (convo: ConversationPreview) => {
    const info = conversationInfoBySid.get(convo.sid);
    if (!info) {
      // Do not render the row until we have the convo info / titles available
      return null;
    }
    return (
      <ConversationListItem
        key={convo.sid}
        conversation={convo}
        conversationInfo={info}
        lastActiveAt={getLastActiveAt(convo)}
        onMessageAdded={onMessageAdded}
        viewer={viewer}
      />
    );
  };

  return (
    <>
      {viewer.isStaff && <NewConversationButton onClick={() => setNewConversationModalIsOpen(true)} />}
      {totalConversations > pagination.limit && (
        <>
          <SearchBar disabled={!filterReady} />
          <Pagination {...pagination} />
        </>
      )}
      {!viewer.isStaff && <CustomerSupportCard />}
      {showEmpty ? <ConversationListEmptyState /> : conversations.map(renderConversationRow)}
      <NewConversationModal isOpen={newConversationModalIsOpen} onClose={() => setNewConversationModalIsOpen(false)} />
    </>
  );
};
