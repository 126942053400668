import { useAuth } from '@clerk/clerk-react';
import { useCallback, useMemo } from 'react';

import { useFeatureFlags } from '$services/featureFlags';

import { _eventTracker, logger } from '../../../bootstrap.client';

import { type IdentifyUserTraits, type TrackEventDictionary, type TrackEventName } from './__dictionary__';

/**
 * The primary hook application consumers should use to track events. It's a thin wrapper around our
 * Platform Event Tracking Service and, where necessary, enhances methods with application-specific data
 * (e.g. feature flags).
 *
 * For method documentation:
 * @see @littleotter/platform/src/svc/event-tracking/PlatformEventTrackingService.ts
 *
 * TODO: The current implementation of the useEventTracking hook is literally duplicated across applications. This is very yucky! But we are doing it in order to move quickly at present. There will be a follow-up PR to unify this functionality at a platform level, but it will require some more careful thought and planning to account for flags and types differing between apps. For the time being, we're rolling with the duplication.
 */
export const useEventTracking = () => {
  const flags = useFeatureFlags();
  const { userId, actor } = useAuth();
  const VIA = 'legacy-app';

  /**
   * Make feature flags easier to differentiate from other event properties in our analytics destinations
   * by prefixing them with `__ff-`.
   *
   * At a glance, without the prefix, the marketing team has no way of knowing which property is a flag and which
   * is a legitimate custom property.
   */
  const prefixedFlags = useMemo(() => {
    return Object.keys(flags).reduce((acc, key) => {
      return {
        ...acc,
        [`__ff-${key}`]: flags[key as keyof typeof flags],
      };
    }, {});
  }, [flags]);

  /**
   * Enhance each track call with application-specific data (e.g. feature flags).
   */
  const enhancedTrack = useCallback(
    <T extends TrackEventName>(name: T, properties?: TrackEventDictionary[T], callback?: () => void) => {
      _eventTracker.track(name, { ...properties, ...prefixedFlags, via: VIA }, callback);
    },
    [prefixedFlags]
  );

  /**
   * Enhance each viewedPage call with application-specific data (e.g. feature flags).
   */
  const enhancedViewedPage: typeof _eventTracker.viewedPage = useCallback(
    (properties, callback) => {
      _eventTracker.viewedPage({ ...properties, ...prefixedFlags, via: VIA }, callback);
    },
    [prefixedFlags]
  );

  /**
   * Enhance each identify call with proper typings for user traits
   */
  const enhancedIdentify: typeof _eventTracker.identify<IdentifyUserTraits> = useCallback(
    (email, traits, callback) => {
      if (userId && actor) {
        // Do not identify for impersonated sessions
        logger.debug('[useEventTracking.enhancedIdentify] skipping identify because current user is impersonated');
        return;
      }
      _eventTracker.identify(email, { ...traits }, callback);
    },
    [userId, actor]
  );

  /**
   * IMPORTANT NOTE: Because we are re-exporting some class method as an object property, we have to re-bind the context
   * of the Event Tracker class to the exported method (ie: `method.bind(classInstance)`)). Otherwise, the exported
   * method will not have access to the class's properties/methods and `this` will be undefined because it will be
   * called from the global scope, resulting runtime errors.
   *
   * Note that arrow functions do not have their own `this`
   * context, so they do not need to be re-bound. (eg. enhancedTrack and enhancedViewedPage above)
   */
  return useMemo(
    () => ({
      track: enhancedTrack,
      alias: _eventTracker.alias.bind(_eventTracker),
      clearIdentity: _eventTracker.clearIdentity.bind(_eventTracker),
      identify: enhancedIdentify,
      linkUserWithGroup: _eventTracker.linkUserWithGroup.bind(_eventTracker),
      viewedPage: enhancedViewedPage,
    }),
    [enhancedTrack, enhancedViewedPage, enhancedIdentify]
  );
};
