import { type TableInstance, useSortBy, useTable } from 'react-table';

import { type AppointmentsTableDataItem } from '../types';

import { useTableOptions } from './useTableOptions';

type useAppointmentsTable = (options: {
  tableData: AppointmentsTableDataItem[];
}) => TableInstance<AppointmentsTableDataItem>;

export const useAppointmentsTable: useAppointmentsTable = ({ tableData }) => {
  const tableOptions = useTableOptions(tableData);
  const tableInstance = useTable(tableOptions, useSortBy);

  return tableInstance;
};
