import { type FC, type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { logger } from '$services/logging';
import { type BannerMessage } from '$shared/hooks';
import { useRedirectCallback } from '$shared/utils/rerouter';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { MissingMutationDataError, MissingQueryDataError } from '../../../../graphql/errors';
import { useViewer } from '../../../../shared/contexts/Viewer';
import { useOnboardingWorryDomainsQuery } from '../../hooks';
import {
  type OnboardingWorryDomainsQuery_viewer_family_worryDomains_edges_node,
  type OnboardingWorryDomainsQuery_viewer_worryDomains_edges_node,
} from '../../hooks/__generated__/OnboardingWorryDomainsQuery';

import { useOnboardingAvailableWorryDomainsQuery, useUpdateOnboardingWorryDomainsMutation } from './hooks';
import {
  type OnboardingAvailableWorryDomainsQuery_caregiverWorryDomains_edges_node,
  type OnboardingAvailableWorryDomainsQuery_childWorryDomains_edges_node,
  type OnboardingAvailableWorryDomainsQuery_familyWorryDomains_edges_node,
} from './hooks/__generated__/OnboardingAvailableWorryDomainsQuery';
import { type SelectedChildrenWorryDomainPksMap } from './types';
import { WorryDomainsContent, type WorryDomainsContentProps } from './WorryDomainsContent';

type WorryDomainsProps = {
  setBannerMessage: (message: BannerMessage | null) => void;
};

export const WorryDomains: FC<PropsWithChildren<WorryDomainsProps>> = ({ setBannerMessage }) => {
  const goNextPage = useRedirectCallback({ persistState: true });

  const { data: wdData, loading: wdLoading, error: wdError } = useOnboardingWorryDomainsQuery();
  const { data: awdData, loading: awdLoading, error: awdError } = useOnboardingAvailableWorryDomainsQuery();
  const [updateOnboardingWorryDomains] = useUpdateOnboardingWorryDomainsMutation();

  const [isLoading, setIsLoading] = useState(false);

  // ! HACK(PD-1731): use wdVisited to distinguish between users who have selected no worry domains, from users
  // ! who have not yet visited the worry domains page.
  // !
  // ! We attempted to use Session Storage, but that doesn't work. For some unknown reason, when redirecting to
  // ! other routes (e.g. /checkup), the component does not remount (e.g. CheckupWithPrerequisites) and the session
  // ! storage value does not update and nothing gets rendered and it is all bad.
  const { setWdVisited } = useViewer();

  const onSaveWorryDomains = useCallback<WorryDomainsContentProps['onSaveWorryDomains']>(
    async ({ selectedChildrenWorryDomainPksMap, selectedCaregiverWorryDomainPks, selectedFamilyWorryDomainPks }) => {
      setIsLoading(true);

      try {
        const response = await updateOnboardingWorryDomains({
          variables: {
            childrenWorryDomainPksMap: JSON.stringify(selectedChildrenWorryDomainPksMap),
            familyWorryDomainPks: selectedFamilyWorryDomainPks,
            caregiverWorryDomainPks: selectedCaregiverWorryDomainPks,
          },
        });

        const updateFamilyWorryDomainsResult = response.data?.updateFamilyWorryDomains;

        if (!updateFamilyWorryDomainsResult) {
          throw new MissingMutationDataError('UpdateOnboardingWorryDomainsMutation');
        }

        switch (updateFamilyWorryDomainsResult.__typename) {
          case 'FamilyType':
            // ! HACK(PD-1731): updates wdVisited while submitting worry domains.
            // ! Do not move this to a useEffect on mounting this component, because it might trigger
            // ! a prerequisite to update and skip worry domains entirely.
            setWdVisited(true);
            goNextPage();
            return;
          case 'UpdateFamilyWorryDomainsError':
            setBannerMessage({ type: 'error', message: updateFamilyWorryDomainsResult.message });
            return;
          default:
            throw new Error('Unknown result typename');
        }
      } catch (e) {
        logger.error(new Error('Error updating worry domains', { cause: e }));
        setBannerMessage({ type: 'error', message: 'Something went wrong. Try again later.' });
      } finally {
        setIsLoading(false);
      }
    },
    [goNextPage, setBannerMessage, setWdVisited, updateOnboardingWorryDomains]
  );

  const childWorryDomainNodes = useMemo(
    () =>
      awdData?.childWorryDomains?.edges
        .map((edge) => edge?.node)
        .filter((node): node is OnboardingAvailableWorryDomainsQuery_childWorryDomains_edges_node => !!node),
    [awdData?.childWorryDomains?.edges]
  );

  const caregiverWorryDomainNodes = useMemo(
    () =>
      awdData?.caregiverWorryDomains?.edges
        .map((edge) => edge?.node)
        .filter((node): node is OnboardingAvailableWorryDomainsQuery_caregiverWorryDomains_edges_node => !!node),
    [awdData?.caregiverWorryDomains?.edges]
  );

  const familyWorryDomainNodes = useMemo(
    () =>
      awdData?.familyWorryDomains?.edges
        .map((edge) => edge?.node)
        .filter((node): node is OnboardingAvailableWorryDomainsQuery_familyWorryDomains_edges_node => !!node),
    [awdData?.familyWorryDomains?.edges]
  );

  const defaultChildrenWorryDomainPksMap = useMemo(
    () =>
      wdData?.viewer?.family?.children.reduce<SelectedChildrenWorryDomainPksMap>(
        (acc, child) => ({ ...acc, [child.id]: [] }),
        {}
      ),
    [wdData?.viewer?.family?.children]
  );

  const defaultCaregiverWorryDomainPks = useMemo(
    () =>
      wdData?.viewer?.worryDomains.edges
        .map((edge) => edge?.node)
        .filter((node): node is OnboardingWorryDomainsQuery_viewer_worryDomains_edges_node => !!node)
        .map((node) => node.pk),
    [wdData?.viewer?.worryDomains.edges]
  );

  const defaultFamilyWorryDomainPks = useMemo(
    () =>
      wdData?.viewer?.family?.worryDomains.edges
        .map((edge) => edge?.node)
        .filter((node): node is OnboardingWorryDomainsQuery_viewer_family_worryDomains_edges_node => !!node)
        .map((node) => node.pk),
    [wdData?.viewer?.family?.worryDomains.edges]
  );

  if (wdLoading || awdLoading) return <PageWideLoading />;

  if (wdError) throw wdError;
  if (awdError) throw awdError;

  if (
    !wdData?.viewer?.family?.children ||
    !childWorryDomainNodes ||
    !caregiverWorryDomainNodes ||
    !familyWorryDomainNodes ||
    !defaultChildrenWorryDomainPksMap ||
    !defaultCaregiverWorryDomainPks ||
    !defaultFamilyWorryDomainPks
  )
    throw new MissingQueryDataError('OnboardingWorryDomainsQuery');

  return (
    <WorryDomainsContent
      userChildren={wdData.viewer.family.children}
      childWorryDomains={childWorryDomainNodes}
      caregiverWorryDomains={caregiverWorryDomainNodes}
      familyWorryDomains={familyWorryDomainNodes}
      onSaveWorryDomains={onSaveWorryDomains}
      isLoading={isLoading}
      nextStepsText=""
      defaultChildrenWorryDomainPksMap={defaultChildrenWorryDomainPksMap}
      defaultCaregiverWorryDomainPks={defaultCaregiverWorryDomainPks}
      defaultFamilyWorryDomainPks={defaultFamilyWorryDomainPks}
    />
  );
};
