import { type FC, type PropsWithChildren } from 'react';

import { IconWithBackground, type IconWithBackgroundName } from '../../../../../../components/IconWithBackground';
import { parseFullMarkdown } from '../../../../../FmhcCheckup/utils/markdownParser';
import { CONTEXT_TYPE } from '../../types';

import * as Styled from './styled';

const TITLE_AND_ICON_MAP: { [key in Partial<CONTEXT_TYPE>]: { title: string; icon: IconWithBackgroundName } } = {
  [CONTEXT_TYPE.WHAT_CAN_I_DO]: {
    title: 'What can I do?',
    icon: 'Bulb',
  },
  [CONTEXT_TYPE.WHAT_DOES_THIS_MEAN]: {
    title: 'What does this mean?',
    icon: 'Message',
  },
};

export const DomainContext: FC<PropsWithChildren<DomainContextProps>> = ({ contextType, context }) => {
  if (!(contextType in TITLE_AND_ICON_MAP)) {
    return null;
  }
  const { title, icon } = TITLE_AND_ICON_MAP[contextType];
  return (
    <Styled.Root>
      <Styled.TitleContainer>
        <IconWithBackground name={icon} variant="onboarding" />
        <Styled.Title>{title}</Styled.Title>
      </Styled.TitleContainer>
      <Styled.HtmlContainer content={parseFullMarkdown({ template: context })} />
    </Styled.Root>
  );
};

export interface DomainContextProps {
  contextType: CONTEXT_TYPE;
  context: string;
}
