import { type FC, type PropsWithChildren } from 'react';

import { useFmhcsQuery } from '$shared/scenarios/useFmhcsQuery';

import { PageWideLoading } from '../../../../components/PageWideLoading';
import { SmartFmhcCta } from '../../../../components/SmartFmhcCta';
import { type Fmhc } from '../../../../graphql/lo1/generated';
import { routes } from '../../../../routes';

import { ReportListItem } from './components/ReportListItem';
import { useGetProfilePictures } from './scenarios/useGetProfilePictures';

export const ReportHistory: FC<PropsWithChildren> = () => {
  const [fmhcs, loading] = useFmhcsQuery({ isSubmitted: true, limit: 10 });
  if (loading) {
    return <PageWideLoading />;
  }
  return (
    <>
      <SmartFmhcCta />
      {fmhcs && <ReportList fmhcs={fmhcs} />}
    </>
  );
};

const ReportList: FC<PropsWithChildren<ReportListProps>> = ({ fmhcs }) => {
  const caregiverProfilePictures = useGetProfilePictures();
  return (
    <ul>
      {fmhcs.map((fmhc) => {
        const title = `${new Date(`${fmhc.submissionTime}`).toLocaleDateString()} Checkup report`;
        const content = `Completed by: ${fmhc.submitterName}`;
        return (
          <ReportListItem
            key={fmhc.id}
            href={routes.reports.fmhcs.fmhc.url({ fmhcId: fmhc.id })}
            profilePicture={caregiverProfilePictures[fmhc.submitterId]}
            title={title}
            content={content}
          />
        );
      })}
    </ul>
  );
};

interface ReportListProps {
  fmhcs: Fmhc[];
}
