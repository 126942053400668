import { type FC, type PropsWithChildren, useMemo } from 'react';

import { useGraphQLErrorHandling } from '$shared/hooks';
import { useGetFmhcsWithRetakeWindow } from '$shared/scenarios';
import { useViewerChildren } from '$shared/utils/rerouter/prerequisites/hooks/useViewerChildrenQuery';
import { WithPrerequisites } from '$shared/utils/rerouter/WithPrerequisites';

import { routes } from '../../routes';
import { useViewer } from '../../shared/contexts/Viewer';
import { useWorryDomainsQuery } from '../PaymentDetails/WelcomeCallPaymentDetails/OrderConfirmation/hooks';

import { CheckupStart } from './CheckupStart';
import { type CheckupStartProps } from './props';

export const CheckupWithPrerequisites: FC<PropsWithChildren<CheckupStartProps>> = ({ ...props }) => {
  const { data: viewerData, loading: childrenLoading, error: childrenError } = useViewerChildren();
  const { data: worryDomainsData, error: worryDomainsError } = useWorryDomainsQuery();
  useGraphQLErrorHandling(childrenError, worryDomainsError);

  const {
    latestFreshSubmitted,
    latestFreshUnsubmitted,
    loading: fmhcsLoading,
  } = useGetFmhcsWithRetakeWindow({ noCache: true, scenarios: ['latestFreshSubmitted', 'latestFreshUnsubmitted'] });
  const loading = childrenLoading || fmhcsLoading;

  // prereq: needsChildren - user needs to have at least one child
  const needsChildrenPredicate = useMemo(() => viewerData?.viewer?.family?.children.length === 0, [viewerData]);
  const childRoute = routes.child.home;

  // prereq: needsworryDomains - user needs to fill out worry domains. But also these aren't required, so we make sure that we didn't just ask them to do that.
  // ! HACK(PD-1731): use wdVisited to distinguish between users who have selected no worry domains, from users
  // ! who have not yet visited the worry domains page.
  const { wdVisited } = useViewer();
  const needsWorryDomainsPredicate = useMemo(() => {
    // Early returns if there are any worry domains anywhere
    if (worryDomainsData?.viewer?.worryDomains.edges.length !== 0) {
      return false;
    }
    if (worryDomainsData?.viewer?.family?.worryDomains.edges.length !== 0) {
      return false;
    }
    if (worryDomainsData?.viewer?.family?.children.find((c) => c.worryDomains.edges.length !== 0)) {
      return false;
    }
    // ! HACK(PD-1731)
    if (wdVisited) {
      return false;
    }
    return true;
  }, [wdVisited, worryDomainsData]);
  const worryDomainsRoute = routes.authOnboarding.worryDomains;

  // prereq: hasRecentlySubmittedFmhcs - user has a recently submitted FMHC and does not have a recently unsubmitted FMHC
  const hasRecentlySubmittedFmhcs = !!latestFreshSubmitted && !latestFreshUnsubmitted;

  return (
    <WithPrerequisites
      prerequisites={[
        {
          predicate: needsChildrenPredicate,
          route: childRoute,
        },
        {
          predicate: needsWorryDomainsPredicate,
          route: worryDomainsRoute,
        },
        {
          predicate: hasRecentlySubmittedFmhcs,
          route: routes.home,
        },
      ]}
      loading={loading}
    >
      <CheckupStart {...props} />
    </WithPrerequisites>
  );
};
