import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import { Banner, Header } from '@littleotter/legacy-components';

import { useBannerMessage, useGraphQLErrorHandling } from '$shared/hooks';
import { useGetFmhcs } from '$shared/scenarios/useGetFmhcs';
import { appointmentsProxyUrl } from '$shared/utils/proxy';

import { PageWideLoading } from '../../../components/PageWideLoading';
import { SmartContinueOnboardingCta } from '../../../components/SmartContinueOnboardingCta';
import { SmartFmhcCta } from '../../../components/SmartFmhcCta';
import {
  type CareHomeQuery,
  type CareHomeQuery_viewer_family_children_ageTag,
  type CareHomeQuery_viewer_family_children_worryDomains_edges_node,
} from '../../../graphql/__generated__/CareHomeQuery';
import { CARE_HOME_QUERY } from '../../../graphql/care-home';
import { MissingQueryDataError } from '../../../graphql/errors';
import { useHasPaidFirstFmhcQuery } from '../../../graphql/lo1/generated';
import { routes } from '../../../routes';
import Resources from '../../Library/components/Resources';
import { StateLocationSourceEnum } from '../../PaymentDetails/WelcomeCallPaymentDetails';

import { SmartFamilyOverview } from './components/SmartFamilyOverview';
import { Card, Timeline } from './components/Timeline';
import { ZeroStateCard } from './components/ZeroStateCard';
import { useUpcomingSessions } from './hooks';
import { type ParsedTreatmentNotes, useLatestSessionRecap } from './hooks/useLatestSessionRecap';
import { firstRightVariants, secondRightVariants } from './motionUtils';
import {
  ContainerBackground,
  ContainerMargin,
  FloatRightLink,
  HeaderContainer,
  MotionMarginChildren,
  MovedUp,
  SmallText,
  StyledHeader,
  StyledUpcomingSessions,
  TopIllustration,
  TopIllustrationContainer,
} from './styled';

export const CareHome: FC<PropsWithChildren> = () => {
  const history = useHistory();
  // uses next-gen schedule appointments page
  const scheduleHref = appointmentsProxyUrl;

  const { data, error: careHomeError, loading: careHomeLoading } = useQuery<CareHomeQuery>(CARE_HOME_QUERY);
  const { bannerMessage, bannerIsShowing, setBannerMessage } = useBannerMessage();
  const { upcomingSessions, loading: upcomingSessionsLoading } = useUpcomingSessions();

  const { latestSubmitted, loading: fmhcsLoading } = useGetFmhcs({ scenarios: ['latestSubmitted'] });

  const {
    latestSessionRecap,
    error: latestSessionRecapError,
    loading: latestSessionRecapLoading,
  } = useLatestSessionRecap();

  const { data: hasPaidFirstFmhcData, loading: hasPaidFirstFmhcLoading } = useHasPaidFirstFmhcQuery();

  useGraphQLErrorHandling(careHomeError, latestSessionRecapError);

  const viewer = data?.viewer;

  const loading =
    careHomeLoading || fmhcsLoading || upcomingSessionsLoading || latestSessionRecapLoading || hasPaidFirstFmhcLoading;
  if (loading) return <PageWideLoading />;
  if (!viewer?.family) throw new MissingQueryDataError('CareHomeQuery');

  const {
    family: { children, resourcesFromWorryDomains },
    hasCompletedAppointments,
  } = viewer;

  const worryDomains: CareHomeQuery_viewer_family_children_worryDomains_edges_node[] = [];
  const ageTags: CareHomeQuery_viewer_family_children_ageTag[] = [];

  children.forEach((child) => {
    const { ageTag } = child;

    if (!ageTags.find((tag) => tag.id === ageTag.id)) {
      ageTags.push(ageTag);
    }

    child.worryDomains.edges.forEach((worryDomain) => {
      if (worryDomain?.node) {
        worryDomains.push(worryDomain.node);
      }
    }, []);
  }, []);

  const hasBoughtReport = hasPaidFirstFmhcData?.HasPaidFirstFmhc.hasPaid ?? false;

  const hasValidInsuranceAuthorizations = viewer?.family?.hasValidInsuranceAuthorizations;
  // TODO: Improve button style consistancy
  // StyledUpcomingSession button looks slightly than the button styles
  // for the LeafCardNav FMHC CTA

  const isInAllowedState = viewer?.asCaregiver?.address?.state?.allowed ?? false;
  const showUpcomingSessions = isInAllowedState && (viewer.hasConfirmedAppointments || upcomingSessions.length > 0);

  return (
    <>
      <Banner
        message={bannerMessage.message}
        isShowing={bannerIsShowing}
        variant={bannerMessage.type}
        onTimeout={() => setBannerMessage(null)}
      />
      <TopIllustrationContainer>
        <TopIllustration />
      </TopIllustrationContainer>
      <HeaderContainer>
        <Header as="h2">Hi, {data?.viewer?.firstName}!</Header>
        {showUpcomingSessions && (
          <StyledUpcomingSessions
            hasBoughtReport={hasBoughtReport}
            upcomingSessions={upcomingSessions}
            label="upcoming sessions"
            hasCompletedAppointments={hasCompletedAppointments}
            onClick={() => {
              const route =
                hasCompletedAppointments || upcomingSessions?.length > 0
                  ? scheduleHref
                  : {
                      pathname: routes.paymentDetails.welcomeCall.home.url(),
                      state: { source: StateLocationSourceEnum.CareDen },
                    };
              // if we're routing to next-gen, then eject with window.open as history.push() uses react router
              if (route === scheduleHref) {
                window.open(route, '_self');
              } else {
                history.push(route);
              }
            }}
            hasValidInsuranceAuthorizations={hasValidInsuranceAuthorizations}
          />
        )}
        <SmartContinueOnboardingCta />
        <SmartFmhcCta />
      </HeaderContainer>
      <ContainerBackground>
        <ContainerMargin>
          <MovedUp initial="exit" animate="enter">
            {latestSubmitted ? (
              <>
                <StyledHeader as="h4">
                  <span>Family Overview</span>
                  <FloatRightLink href={routes.reports.fmhcs.overTime.url()}>See all</FloatRightLink>
                </StyledHeader>
                <SmartFamilyOverview fmhcId={latestSubmitted.id} />
              </>
            ) : (
              <>
                <StyledHeader as="h4">Family Overview</StyledHeader>
                <ZeroStateCard
                  highlightedText="Take a child and family check-up"
                  normalText="to unlock your family's well-being overview."
                  linkLabel="Take a check-up"
                  linkHref={routes.checkup.home.url()}
                />
              </>
            )}
            <CareJourneySection
              isInAllowedState={isInAllowedState}
              latestSessionRecap={latestSessionRecap}
              careJourneyCardOnClick={(route) => history.push(route)}
            />
            <MotionMarginChildren variants={secondRightVariants}>
              <StyledHeader as="h4">
                <span>Family Resources </span>
                <FloatRightLink href={routes.library.home.url()}>See all</FloatRightLink>
              </StyledHeader>
              <SmallText>
                Explore resources that have been curated for you based on your child’s age and your families needs.
              </SmallText>
              <Resources horizontalScrolling resources={resourcesFromWorryDomains.edges} />
            </MotionMarginChildren>
          </MovedUp>
        </ContainerMargin>
      </ContainerBackground>
    </>
  );
};

const CareJourneySection = ({
  isInAllowedState,
  latestSessionRecap,
  careJourneyCardOnClick,
}: {
  isInAllowedState: boolean;
  latestSessionRecap?: ParsedTreatmentNotes[];
  careJourneyCardOnClick: (route: string) => void;
}) => {
  if (isInAllowedState && latestSessionRecap?.length) {
    return (
      <MotionMarginChildren variants={firstRightVariants}>
        <Timeline content={latestSessionRecap} sectionTitle="Care journey" seeAllUrl={routes.care.journey.home.url()}>
          {(content) => (
            <Card
              onClick={() =>
                content.original.status === 'locked' &&
                careJourneyCardOnClick(routes.care.journey.sessionNote.url({ id: content.id }))
              }
              avatar={content.avatar || ''}
              title={content.title}
              subtitle={content.subtitle || ''}
              treatmentNote={content.original}
            />
          )}
        </Timeline>
      </MotionMarginChildren>
    );
  }
  if (isInAllowedState) {
    return (
      <>
        <StyledHeader as="h4">Care journey</StyledHeader>
        <ZeroStateCard
          highlightedText="You haven’t had a session yet."
          normalText="Once you do, your session notes will live here."
        />
      </>
    );
  }
  return null;
};
