import { useQuery } from '@apollo/client';
import { type FC, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { Header } from '@littleotter/legacy-components';

import { useGraphQLErrorHandling } from '$shared/hooks';

import { PageWideLoading } from '../../../components/PageWideLoading';
import {
  type ProviderFamilyDetailsQuery,
  type ProviderFamilyDetailsQuery_family_caregivers,
  type ProviderFamilyDetailsQuery_family_providers,
  type ProviderFamilyDetailsQueryVariables,
} from '../../../graphql/__generated__/ProviderFamilyDetailsQuery';
import { MissingQueryDataError } from '../../../graphql/errors';
import { PROVIDER_FAMILY_DETAILS_QUERY } from '../../../graphql/provider-family-details';
import { routes } from '../../../routes';

import { FamilyDetailsCard, NextCall, type Profile, ProfileGroup } from './components';
import { FamilyDetailsTitleSubSection, FamilyDetailsTopSectionContainer } from './styled';

const getProfiles = (
  caregivers: ProviderFamilyDetailsQuery_family_caregivers[] | ProviderFamilyDetailsQuery_family_providers[]
): Profile[] =>
  caregivers.map((caregiver) => ({
    id: caregiver.id,
    fullName: `${caregiver.firstName} ${caregiver.lastName}`,
    profilePictureSrc: caregiver.profilePicture,
  }));

export const FamilyDetailsTopSection: FC<PropsWithChildren> = () => {
  const { id } = useParams<{ id: string }>();

  const { data, loading, error } = useQuery<ProviderFamilyDetailsQuery, ProviderFamilyDetailsQueryVariables>(
    PROVIDER_FAMILY_DETAILS_QUERY,
    {
      variables: { familyId: Number(id) },
    }
  );
  useGraphQLErrorHandling(error);

  if (loading) return <PageWideLoading />;
  if (!data) throw new MissingQueryDataError('ProviderFamilyDetailsQuery');

  const { name, caregivers, providers, nextAppointment, conversationWithProvider, providerNote } = data.family;

  return (
    <FamilyDetailsTopSectionContainer>
      <FamilyDetailsTitleSubSection>
        <Header as="h2">{name}</Header>
        <ProfileGroup label="Family" tooltipId="family-group" profiles={getProfiles(caregivers)} />
        <ProfileGroup label="Care Team" tooltipId="providers-group" profiles={getProfiles(providers)} />
      </FamilyDetailsTitleSubSection>

      <section>
        {nextAppointment ? (
          <NextCall
            startDatetime={new Date(nextAppointment.startDatetime)}
            serviceName={nextAppointment.service.name}
            providerName={`${nextAppointment.provider.firstName} ${nextAppointment.provider.lastName}`}
            conversationLink={
              conversationWithProvider
                ? routes.messages.conversation.url({ id: conversationWithProvider.conversationSid })
                : undefined
            }
            zoomLink={nextAppointment.zoomLink ?? undefined}
          />
        ) : (
          <FamilyDetailsCard title="No appointments scheduled" />
        )}
      </section>

      <section>
        <FamilyDetailsCard title={`${providerNote.length === 0 ? 'No ' : ''}Provider Note about Family`}>
          {providerNote.length > 0 && <p>{providerNote}</p>}
        </FamilyDetailsCard>
      </section>
    </FamilyDetailsTopSectionContainer>
  );
};
