import { gql } from '@apollo/client';

import { CHILD_FRAGMENT } from './child';

export const USER_FRAGMENT = gql`
  fragment UserFragment on UserType {
    id
    email
    firstName
    lastName
    isStaff
    isDemoAccount
    state
    profilePicture
    phoneNumber
    asCaregiver {
      address {
        streetAddress
        state {
          allowed
        }
      }
    }
    hasConfirmedAppointments
    hasCompletedAppointments
    onboardingBookmark
    externalClientUuid
    outOfOfficeDates {
      startDate
      endDate
    }
    family {
      id
      children {
        id
        ...ChildFragment
      }
      careStatus {
        activelyInCare
        previouslyInCare
        everInCare
        isMember
      }
      providers {
        id
        firstName
        lastName
        providerTitle
      }
      hasValidInsuranceAuthorizations
    }
  }

  ${CHILD_FRAGMENT}
`;

export const ADD_USER_CHILD_MUTATION = gql`
  mutation AddUserChild($input: AddUserChildMutationInput!) {
    addUserChild(input: $input) {
      ... on AddUserChildResultType {
        child {
          id
        }
      }
      ... on AddUserChildError {
        message
        fieldErrors {
          firstName
          lastName
          dateOfBirth
          gender
          pronouns
          preferredName
        }
      }
    }
  }
`;

export const VIEWER_QUERY = gql`
  query ViewerQuery {
    viewer {
      id
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;
