import { type ComponentType, type CSSProperties, type FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as SlothFamily } from './assets/SlothFamily.svg';

type ImageContainerSize = 'smallest' | 'smaller' | 'small' | 'regular';

const sizeMultipliers: { [k in ImageContainerSize]: number } = {
  smallest: 2.925,
  smaller: 4,
  small: 5.85,
  regular: 11.3,
};

type StyledImageContainerProps = {
  avatar?: string;
  size: ImageContainerSize;
  customSizeMultiplier?: number;
};

const StyledImageContainer = styled.div<StyledImageContainerProps>`
  height: calc(
    ${({ theme, size, customSizeMultiplier }) =>
      `${customSizeMultiplier ?? sizeMultipliers[size]} * ${theme.deprecated_.sizeBasis}`}
  );
  width: calc(
    ${({ theme, size, customSizeMultiplier }) =>
      `${customSizeMultiplier ?? sizeMultipliers[size]} * ${theme.deprecated_.sizeBasis}`}
  );
  max-width: calc(
    ${({ theme, size, customSizeMultiplier }) =>
      `${customSizeMultiplier ?? sizeMultipliers[size]} * ${theme.deprecated_.sizeBasis}`}
  );
  max-height: calc(
    ${({ theme, size, customSizeMultiplier }) =>
      `${customSizeMultiplier ?? sizeMultipliers[size]} * ${theme.deprecated_.sizeBasis}`}
  );
  margin-right: ${(props) => props.theme.deprecated_.sizeBasis};
  overflow: hidden;
  border-radius: 100%;
  background-color: ${({ avatar, theme }) => (avatar ? 'none' : theme.deprecated_.colors.lightPurple)};
`;

const imageStyle = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  ${imageStyle}
`;

const StyledPlaceholder = styled(SlothFamily)`
  ${imageStyle}
`;

export type ProfilePictureProps = {
  src?: string | File | null;
  size?: ImageContainerSize;
  customSizeMultiplier?: number;
  Placeholder?: ComponentType;
  style?: CSSProperties;
};

export const ProfilePicture: FC<ProfilePictureProps> = ({
  src,
  size = 'regular',
  customSizeMultiplier,
  Placeholder,
  ...props
}) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState('');

  useEffect(() => {
    if (!src) {
      setProfilePictureUrl('');
      return;
    }

    if (typeof src === 'string') {
      setProfilePictureUrl(src);
      return;
    }

    const objectUrl = URL.createObjectURL(src);
    setProfilePictureUrl(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [src]);

  return (
    <StyledImageContainer size={size} customSizeMultiplier={customSizeMultiplier} {...props}>
      {profilePictureUrl ? (
        <StyledImage alt="Profile Picture" src={profilePictureUrl} />
      ) : (
        <StyledPlaceholder as={Placeholder} />
      )}
    </StyledImageContainer>
  );
};
