import { useState } from 'react';

import { FullButton, Header } from '@littleotter/legacy-components';

import { MarginChildren } from '../../../../components';
import { type Child, type RemoveChildResult } from '../../types';
import { Description } from '../ChildForm/styled';

import { AddChildButton } from './AddChildButton';
import { CardContainer, GreenSpan, OrangeSpan, StyledError } from './styled';
import { SummaryCard } from './SummaryCard';

export interface ChildSummaryPageProps {
  childs?: Child[];
  goInputPage: () => void;
  goNextPage: () => void;
  onRemoveChild: (childId: number) => Promise<RemoveChildResult>;
}

export const ChildSummaryPage = ({ childs, goInputPage, onRemoveChild, goNextPage }: ChildSummaryPageProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const childAsCard = (child: Child) => {
    const fullName = `${child.displayName} ${child.lastName}`;
    return (
      <SummaryCard
        onRemoveClick={async () => {
          if (child.id !== undefined) {
            setIsLoading(true);
            await onRemoveChild(child.id);
            setIsLoading(false);
          }
        }}
        key={fullName}
        fullName={fullName}
        gender={child.pronouns || child.gender || 'child'}
        dateOfBirth={child.dateOfBirth}
      />
    );
  };

  const seekingCareCards = (childs ?? []).filter((child) => child.isSeekingCare).map(childAsCard);
  const notSeekingCareCards = (childs ?? []).filter((child) => !child.isSeekingCare).map(childAsCard);

  const isInvalid = !childs?.length;

  return (
    <MarginChildren>
      <Header as="h3">Your children</Header>
      <Description>
        We will focus on the children you're seeking care for, though other siblings may come up when discussing family
        dynamics.
      </Description>
      {seekingCareCards.length > 0 && (
        <>
          <Header as="h5">
            <GreenSpan>Seeking</GreenSpan> Care for
          </Header>
          <CardContainer>
            <MarginChildren>{seekingCareCards}</MarginChildren>
          </CardContainer>
        </>
      )}
      {notSeekingCareCards.length > 0 && (
        <>
          <Header as="h5">
            <OrangeSpan>Not</OrangeSpan> Seeking Care for
          </Header>
          <CardContainer>
            <MarginChildren>{notSeekingCareCards}</MarginChildren>
          </CardContainer>
        </>
      )}
      <hr />
      <AddChildButton onClick={goInputPage} />
      <FullButton disabled={isInvalid} onClick={goNextPage} isLoading={isLoading} variant="textBlue">
        Next: Worries
      </FullButton>
      {isInvalid && <StyledError>Please add a child to the account</StyledError>}
    </MarginChildren>
  );
};
